.child-nav,
.tab-nav {
  padding-left: 0;
  list-style: none;

  li {
    @include rem((
      padding-right: 10px,
    ));
    display: inline-block;
    padding-left: 0;

    &:before {
      content: none;
    }

    &:last-child  {
      padding-right: 0;
    }

    &.is-active {
      .icon {
        fill: $brand-light-grey;
      }
    }

    .icon {
      @include rem((
        height: 20px,
        width: 20px,
        margin-left: 4px,
        // margin-top: -4px,
      ));

      fill: $brand-dark-grey;
      vertical-align: middle;
    }
  }
}

// TAB NAV AREA
.tab-nav__content-area {
  @include rem((
    margin-top: 10px,
    padding-top: 20px,
  ));
  border-top: 1px solid $brand-hr;

  h2 {
    margin-bottom: 0;
  }
}

.tab-nav__content-block {
  display: none;

  &.is-active {
    display: block;
  }

  img {
    @include rem((
      margin-bottom: 16px,
    ));
  }

  .content-carousel__holder {
    img {
      margin-bottom: 0;
    }
  }
}
