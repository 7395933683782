.accordion-holder {
  @include rem(
    (
      margin-bottom: 20px,
    )
  );
}

.accordion {
  @include rem(
    (
      padding: 0 0 20px,
    )
  );

  border-top: 1px solid $brand-border;
  border-bottom: 1px solid $brand-border;

  img {
    width: 100%;
  }

  + .accordion {
    border-top: none;
  }
}

.accordion__heading {
  cursor: pointer;

  h3 {
    @include rem(
      (
        padding: 20px 45px 0 0,
      )
    );
    margin-bottom: 0;
    display: inline-block;
    position: relative;

    @include gridle_state(tablet-min) {
      @include rem(
        (
          padding: 20px 0 0,
        )
      );
    }
  }

  &:hover {
    h3 {
      text-decoration: underline;
      text-decoration-skip: auto;
    }
  }

  &.is-active {
    .svg-icon {
      background-image: url('/SiteFiles/src/ui/img/svg/chev-up.svg');
    }
  }

  .svg-icon {
    @include rem(
      (
        width: 20px,
        height: 20px,
        margin-left: 10px,
        margin-top: -5px,
      )
    );

    background-image: url('/SiteFiles/src/ui/img/svg/chev-down.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20px;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 15px;

    @include gridle_state(tablet-min) {
      position: relative;
      top: auto;
      right: auto;
    }
  }
}

.accordion__section {
  @include rem(
    (
      padding-top: 20px,
    )
  );

  display: none;

  p {
    @include rem(
      (
        margin-bottom: 7px,
      )
    );

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }
}

.faq-list {
  @include rem(
    (
      padding-top: 20px,
    )
  );
}


.financial-accordion {
  h3 {
    font-family: $brand-font;
    font-size: 19px;
    line-height: 27px;
  }

  .grid-column {
    .financial-accordion__section {
      border-bottom: 1px solid #D5D5D5;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      @include gridle_state ( small-desktop ) {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

    .financial-accordion__cell {
      flex: 1 0 50%;
      margin-bottom: 10px;

      &:nth-child(odd) {
        padding-right: 10px;

        @include gridle_state ( small-desktop ) {
          padding-right: 0;
        }
      }

      &:nth-child(even) {
        padding-left: 10px;

        @include gridle_state ( small-desktop ) {
          padding-left: 0;
        }
      }

      @include gridle_state ( small-desktop ) {
        flex: 1 0 100%;
        margin-bottom: 0;
      }
    }

    &:last-child {
      .financial-accordion__section {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
}

.financial-accordion__tooltip {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  line-height: 15px;
  transform: translateY(2px);
  cursor: pointer;
}