.mortgage-calc {
  @include rem((
   margin-top: 30px,
  ));

  .field-container__group {

    label {
      @include rem((
       margin-top: 15px,
      ));
    }
  }

  .btn {
    @include rem((
     line-height: 24px,
     padding-top: 11px,
    ));
  }

  input[type=number] {
    margin-bottom: 0px;
  }
}

.mortgage-calc__base {
  @include rem((
   margin-top: 20px,
  ));
}
