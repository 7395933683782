:root {
    --theme: $brand-primary;
}

.property-card {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    margin-bottom: 25px;

    

    &.is-map-active {
        @media screen and (max-width: 767px) {
            border-top: 2px solid var(--theme);
            box-shadow: none;
            margin-bottom: 15px;
        }
    }
}


.property-card__grid {
    display: flex;

    @media screen and (max-width: 1199px) {
        flex-direction: column;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            flex-direction: row;
        }
    }
}

.property-card__media-area {
    flex: 0 0 257px;
    position: relative;

    @media screen and (max-width: 425px){
        height: 38vw;
    }
    @media screen and (min-width: 426px) and (max-width: 1199px)  {
        height: 18vw;
    }

    @media screen and (max-width: 1199px)  {
        flex: 0 0 1rem;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            flex: 0 0 94px;
        }
    }
}

.property-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.property-card__availability {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--theme);
    color: $brand-white;
    font-family: $base-font-family-med;
    font-size: 11px;
    line-height: 15px;
    width: 95px;
    padding: 8px;
    text-align: center;
    text-transform: uppercase;

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.property-card__help-to-buy {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: $brand-white;
    padding: 5px;
    line-height: 0;

    @media screen and (max-width: 767px) {
        bottom: auto;
        left: auto;
        top: 0;
        right: 0;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

}

.property-card__content-area {
    position: relative;
    flex: 1 1 auto;
    background-color: $development-light-grey;
}

.property-card__content-area-inner {
    border-top: 4px solid var(--theme);
    padding: 20px 25px;

    @media screen and (max-width: 1199px) {
        padding: 10px 15px;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            border: none;
        }
    }

    .property-card__title {
        font-family: $base-font-family-med;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 0;
        color: $brand-dark-grey;

        @media screen and (max-width: 1199px) {
            font-size: 18px;
            line-height: 24px;
        }
    }
}


.property-card__address {
    font-family: $base-font-family;
    font-size: 11px;
    line-height: 15px;
    color: $brand-dark-grey;
}

.property-card__content-grid {
    @media screen and (max-width: 1199px) {
        display: flex;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
}

.property-card__main-content {
    @media screen and (max-width: 1199px) {
        flex: 1 0 50%;
    }
}

.property-card__info-area {
    display: flex;
    flex-wrap: wrap;
}

.property-card__distance {
    flex: 1 0 100%;
    padding-bottom: 15px;
    display: inline-flex;

    @media screen and (min-width: 1200px) {
        flex: 1 0 50%;
    }

    svg {
        color: $brand-primary;
        fill: $brand-primary;
        width: 15px;
        height: 15px;
        flex: 0 0 15px;
    }

    span {
        font-family: $base-font-family;
        font-size: 12px;
        line-height: 16px;
        padding-left: 7px;
        padding-right: 30px;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.property-card__bed-range {
    flex: 1 0 100%;
    padding-bottom: 15px;
    display: inline-flex;

    @media screen and (min-width: 1200px) {
        flex: 1 0 50%;
    }

    svg {
        color: $brand-primary;
        fill: $brand-primary;
        width: 15px;
        height: 15px;
        flex: 0 0 15px;
    }

    span {
        font-family: $base-font-family;
        font-size: 12px;
        line-height: 16px;
        padding-left: 7px;
    }

}

.property-card__ownership {
    flex: 1 0 100%;
    padding-bottom: 15px;
    display: inline-flex;

    @media screen and (min-width: 1200px) {
        flex: 1 0 50%;
    }

    svg {
        color: $brand-primary;
        fill: $brand-primary;
        width: 15px;
        height: 15px;
        flex: 0 0 15px;
    }

    span {
        font-family: $base-font-family;
        font-size: 12px;
        line-height: 16px;
        padding-left: 7px;
        padding-right: 30px;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.property-card__sale-type {
    flex: 1 0 50%;
    padding-bottom: 15px;
    display: inline-flex;    

    svg {
        color: $brand-primary;
        fill: $brand-primary;
        width: 15px;
        height: 15px;
        flex: 0 0 15px;
    }

    span {
        font-family: $base-font-family;
        font-size: 12px;
        line-height: 16px;
        padding-left: 7px;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.property-card__description {
    position: relative;
    font-family: $base-font-family;
    font-size: 12px;
    line-height: 16px;
    color: $brand-dark-grey;
    padding-top: 20px;

    @media screen and (max-width: 1199px) {
        display: none;
    }

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 35px;
        height: 4px;
        background-color: var(--theme);
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.property-card__link-area {
    @media screen and (max-width: 1199px) {
        flex: 1 0 48%;
        padding-left: 2%;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            padding-left: 0;
        }
    }

    .property-card__btn {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 1199px) {
            margin-right: 0;
        }        

        + .property-card__btn {
            .is-map-active & {
                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
        }
    }
}

.property-card__btn {
    display: inline-block;
    font-family: $base-font-family-med;
    font-size: 14px;
    line-height: 19px;
    background-color: transparent;
    padding: 10px 20px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;

    @media screen and (max-width: 1199px) {
        padding: 8px 15px;
        width: 100%;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: table-cell;
            margin-bottom: 0;
        }
    }

    &--bordered {
        border: 1px solid $brand-medium-grey;
        color: $brand-medium-grey;

        &:hover {
            background-color: var(--theme);
            border-color: var(--theme);
            color: $brand-white;
        }
    }

    &--dark {
        border: 1px solid $brand-medium-grey;
        background-color: $brand-medium-grey;
        color: $brand-white;

        &:hover {
            background-color: var(--theme);
            border-color: var(--theme);
            color: $brand-white;
        }
    }
}

.property-card__contact {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--theme);
    color: $brand-white;
    font-family: $base-font-family;
    font-size: 11px;
    line-height: 15px;
    padding: 4px 15px;

    @media screen and (max-width: 767px) {
        padding: 4px;
    }

    a {
        color: $brand-white;

        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
        }
    }

    span {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    svg {
        display: none;

        @media screen and (max-width: 767px) {
            display: inline-block;
        }
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.property-card__base {
    position: relative;

    .is-map-active & {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.property-card__show-btn {
    background-color: $brand-white;
    border: none;
    width: 100%;
    padding: 15px 20px;
    text-align: left;
    font-family: $base-font-family;
    font-size: 11px;
    line-height: 15px;
    display: inline-block;

    svg {
        width: 13px;
        height: 10px;
        color: $brand-primary;
        fill: $brand-primary;

        .is-active & {
            transform: scaleY(-1);
        }
    }

    span {
        padding-left: 5px;
    }
    
}

.property-card__house-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $brand-white;
    z-index: 2;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);

    .is-active & {
        display: block;
        max-height: 230px;
        overflow-y: auto;
    }
}

.property-card__house-list-inner {
    padding: 0 20px;
}

.property-card__house-list-row {
    display: flex;
    border-bottom: 1px solid $brand-black;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &:last-child {
        border-bottom: none;
    }

    .propety-card__property-number {
        margin-bottom: 0;
        color: var(--theme);

        @media screen and (min-width: 1200px) {
            flex: 0 0 28%;
            margin-right: 2%;
        }
    }
    
    .propety-card__type {
        margin-bottom: 0;

        @media screen and (min-width: 1200px) {
            flex: 0 0 28%;
            margin-right: 2%;
        }
    }
    
    .propety-card__beds {
        margin-bottom: 0;

        @media screen and (max-width: 1199px) {
            display: none;
        }

        @media screen and (min-width: 1200px) {
            flex: 0 0 23%;
            margin-right: 2%;
        }
    }

    .propety-card__beds-mobile {
        display: none;

        @media screen and (max-width: 1199px) {
            display: inline-block;
        }
    }
    
    .propety-card__price {
        margin-bottom: 0;

        @media screen and (min-width: 1200px) {
            flex: 0 0 15%;
        }
    }
}

.property-card__house-list-details {
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
    padding-right: 40px;

    @media screen and (max-width: 1199px) {
        flex: 1 0 60%;
        flex-direction: column;
        padding-right: 0;
    }

    .is-map-active & {
        @media screen and (max-width: 767px) {
            padding-right: 70px;
        }
    }
}


.propety-card__link {
    background-color: var(--theme);
    color: $brand-white;
    font-size: 14px;
    line-height: 19px;
    padding: 8px 20px;
    text-align: center;

    @media screen and (max-width: 1199px) {
        flex: 1 0 40%;
    }

    &:hover {
        background-color: $brand-light-grey;
        text-decoration: none;
    }
}