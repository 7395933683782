.slick-arrow {
  z-index: 9;
  background: none;
  border: none;
}

.content-carousel {
  @include rem(
    (
      margin: 0 auto,
    )
  );

  position: relative;
  line-height: 0;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        max-width: 360px,
        margin: 0 auto 30px,
      )
    );
  }

  @include gridle_state(desktop) {
    max-width: 100%;
  }

  .tns-controls {
    button {
      background-color: transparent;
      border: none;

      &:first-child {
        position: absolute;
        top: 50%;
        right: 90%;
        transform: translateY(-50%);
        z-index: 1;
      }

      &:last-child {
        position: absolute;
        top: 50%;
        left: 90%;
        transform: translateY(-50%);
        z-index: 1;
      }

      svg {
        fill: $brand-white;
      }
    }
  }

  .slick-arrow {
    @include netc-transition();
    @include transform-translate(0, -50%);
    position: absolute;
    top: 50%;
    outline: none;

    .svg-icon {
      fill: $brand-white;
    }
  }

  .slick-prev {
    left: 0;

    @include gridle_state(tablet-portrait) {
      left: -10px;
    }

    &:hover {
      @include gridle_state(tablet-min) {
        @include rem(
          (
            left: -5px,
          )
        );
      }

      @include gridle_state(tablet-portrait) {
        left: -15px;
      }
    }
  }

  .slick-next {
    right: 0;

    @include gridle_state(tablet-portrait) {
      right: -10px;
    }

    &:hover {
      @include gridle_state(tablet-min) {
        @include rem(
          (
            right: -5px,
          )
        );
      }

      @include gridle_state(tablet-portrait) {
        right: -15px;
      }
    }
  }
}

.content-carousel__logo {
  @include transform-translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  width: 70%;
  height: 70%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;

  &-background {
    &::before {
      content: ' ';
      background: #7c7b7b;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 5;
    }
  }
}

.content-carousel__item {
  img {
    width: 100%;
    aspect-ratio: 1 / 1;

    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center;';

    @include gridle_state(tablet) {
      width: auto;
      max-width: 100%;
    }
  }
}
