.financials {
  h2 {
    margin-bottom: 50px;
  }
}

.financial-accordion {
  h3 {
    font-family: $brand-font;
    font-size: 19px;
    line-height: 27px;
    display: block;
    position: relative;
  }

  .grid-column {
    @include gridle_state(tablet-min) {
      @include gridle_grid(12);
    }
    @include gridle_state(small-desktop) {
      @include gridle_grid(3);
    }
    .financial-accordion__section {
      border-bottom: 1px solid #d5d5d5;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      @include gridle_state(small-desktop) {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

    .financial-accordion__cell {
      flex: 1 0 50%;
      margin-bottom: 10px;

      &:nth-child(odd) {
        padding-right: 10px;

        @include gridle_state(small-desktop) {
          padding-right: 0;
        }
      }

      &:nth-child(even) {
        padding-left: 10px;

        @include gridle_state(small-desktop) {
          padding-left: 0;
        }
      }

      @include gridle_state(small-desktop) {
        flex: 1 0 100%;
        margin-top: 30px;
      }
    }

    &:last-child {
      .financial-accordion__section {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }

  .accordion__heading {
    .svg-icon {
      position: absolute;
      top: 27px;
      right: 0;
    }
  }
}

.financial-accordion__tooltip {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  line-height: 15px;
  transform: translateY(2px);
  cursor: pointer;
}
