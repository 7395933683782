// configuration
$btn-name:  'btn' !default;
$btn-default-padding:  .5em .8em !default;

// Set sizes
$button-sizes: (
	small: netc-em(12px),
	medium: netc-em(16px),
	large: netc-em(25px),
	xlarge: netc-em(32px)
) !default;

// $button-colours: (
//   primary: (
// 		color: #fff,
// 		background: #4589ca,
// 		outline-color: transparent,
// 		outline-style: none,
// 		outline-width: 0,
// 		box-shadow: none
// 	),
// 	primary-hover: (
// 		color: #fff,
// 		background: #4589ca,
// 		outline-color: #006fbc,
// 		outline-style: solid,
// 		outline-width: 3px,
// 		box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.3)
// 	),
//   secondary: (
// 		color: #4589ca,
// 		background: #fff,
// 		outline-color: #b7b7b7,
// 		outline-style: solid,
// 		outline-width: 1px,
// 		box-shadow: none
// 	),
//   cta: (
// 		color: #fff,
// 		background: #33cc66,
// 		outline-color: transparent,
// 		outline-style: none,
// 		outline-width: 0,
// 		box-shadow: none
// 	),
//   disabled: (
// 		color: rgba(#fff, 0.5),
// 		background: #cccccc,
// 		outline-color: transparent,
// 		outline-style: none,
// 		outline-width: 0,
// 		box-shadow: none
// 	),
// ) !default;

// Generate button state classes
// @each $button-state, $value in $button-colours {
//   .#{$btn-name}--#{$button-state} {
//     color: map-get($value, color);
// 		background-color: map-get($value, background);
// 		outline-color: map-get($value, outline-color);
// 		outline-style: map-get($value, outline-style);
// 		outline-width: map-get($value, outline-width);
// 		outline-offset: -#{map-get($value, outline-width)};
// 		box-shadow: map-get($value, box-shadow);
//   }
// }

// mixins
@mixin netc-full-width-btn {
	display: block;
	width: 100%;
	text-align: center;
}

@mixin netc-hover(
	$bg: $brand-primary, $color: $brand-white) {
	@include netc-transition($transition: all);
	background-color: $bg;
	color: $color;

	&:hover,
  	&:active {
		background-color: $color;
		color: $bg;
	}
}

@mixin netc-core-button {
  font-family: inherit;
	display: inline-block;
  vertical-align: middle;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	outline: 0;
	font-size: 100%;
	padding: $btn-default-padding;
	color: inherit;
	background: none;
}

.#{$btn-name} {
	@include netc-core-button;
}

.#{$btn-name}--full {
	@include netc-full-width-btn;
}

.#{$btn-name}--primary {
	color: #fff;
	background: #4589ca;
	outline: 3px solid transparent;
	transition: box-shadow 0.3s ease-in-out;

	&:hover {
		outline: 3px solid #006fbc;
		outline-offset: -3px;
		box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.3);
	}

	&:active {
		outline: 3px solid #006fbc;
		outline-offset: -3px;
		box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.3) inset;
	}
}

.#{$btn-name}--secondary {
	color: #4589ca;
	background: #fff;
	outline-color: #b7b7b7;
	outline-style: solid;
	outline-width: 1px;
	outline-offset: -1px;
	transition: box-shadow 0.3s ease-in-out;

	&:hover {
		box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.3);
	}

	&:active {
		box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.3) inset;
	}
}

.#{$btn-name}--cta {
	color: #fff;
	background: #33cc66;
	transition: box-shadow 0.3s ease-in-out;

	&:hover {
		box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.3);
	}

	&:active {
		box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.3) inset;
	}
}

.#{$btn-name}--disabled {
	color: rgba(#fff, 0.5);
	background: #cccccc;
}

// Generate button size classes
@each $key, $value in $button-sizes {
  .#{$btn-name}--#{$key} {
    font-size: $value;
  }
}
