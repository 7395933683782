.article-detail {
  @include rem(
    (
      font-size: 13px,
      line-height: 17px,
      margin-bottom: 10px,
    )
  );

  display: block;
}

.article-detail__tag-area {
  display: inline-block;
  color: $brand-secondary-dark;

  a {
    color: $brand-primary;

    &:hover {
      color: $brand-primary;
      text-decoration-skip: auto;
    }

    &:last-child {
      @include rem(
        (
          padding-right: 5px,
        )
      );
      border-right: 1px solid $brand-border;
    }
  }
}

.article-detail__publish {
  display: inline-block;
  // font-style: italic;
}

.article-detail__date {
  @include rem(
    (
      margin-bottom: 6px,
    )
  );

  div.blog & {
    margin-bottom: rem(16px);
  }
  // font-style: italic;
}

//////////////////////////////////////////////////////////////////////////////// NEWS AREA
.news-list {
  @include rem(
    (
      margin: 20px 0,
    )
  );
}

.share-block {
  a {
    width: auto;
    font-size: 48px;
    text-decoration: none;
    display: inline-block;
    margin-right: 10px;
    transform: translateY(0);
    transition: transform 0.3s;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      transform: translateY(-3px);
      text-decoration: none;
    }

    .fa-facebook-square {
      color: #3b5998;
    }

    .fa-twitter-square {
      color: #00acee;
    }

    .fa-whatsapp {
      color: #25d366;
    }

    .fa-envelope-o {
      color: #008484;
    }
  }
}

.rss-icon {
  color: #f26522;
  font-size: 48px;
}
