// configuration
$netc-core-breadcrumbs-namespace: 'breadcrumbs' !default;
$netc-core-breadcrumbs-text-color: #4589ca !default;
$netc-core-breadcrumbs-separator: '\002F' !default;
$netc-core-breadcrumbs-separator-color: #d8d8d8 !default;
$netc-core-breadcrumbs-separator-spacing: 8px !default;
$netc-core-breadcrumbs-font-size: 14px !default;
$netc-core-breadcrumbs-margin: 0 0 16px 0 !default;
$netc-core-breadcrumbs-current-page: #999999 !default;

// default styles
.#{$netc-core-breadcrumbs-namespace} {
	@include rem((
		margin: $netc-core-breadcrumbs-margin,
	));

	ul {
		@include netc-reset-list;
		font-size: 0;
		margin-bottom: 0;
	}

	li {
		@include rem((
			font-size: $netc-core-breadcrumbs-font-size,
		));
		display: inline-block;

		+ li {
				&:before {
					@include rem((
						margin-left: $netc-core-breadcrumbs-separator-spacing,
						margin-right: $netc-core-breadcrumbs-separator-spacing,
					));
					content: $netc-core-breadcrumbs-separator;
					display: inline-block;
					color: $netc-core-breadcrumbs-separator-color;
				}
		}
	}

	a,
	span {
		color: $netc-core-breadcrumbs-text-color;
	}

	.is-current {
		a,
		span {
			color: $netc-core-breadcrumbs-current-page;
		}
	}
}
