.ways-to-buy {
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba($brand-primary-light, 0.1);

  &.development-theme-1 {
    .ways-to-buy__item {
      background-color: $development-cyan;
    }
  }

  &.development-theme-2 {
    .ways-to-buy__item {
      background-color: $development-blue;
    }
  }

  &.development-theme-3 {
    .ways-to-buy__item {
      background-color: $development-orange;
    }
  }

  @media screen and (min-width: $new-laptop-s) {
    padding: 60px;
  }

  &__content-container {
    width: 100%;
    max-width: 720px;
    margin: auto;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 40px;
  }

  &__items {
    max-width: 800px;
    width: 100%;
    @media screen and (min-width: $new-laptop-s) {
      display: flex;
      justify-content: center;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    color: $brand-white;
    padding: 15px 19px;

    margin-right: 30px;
    background-color: $development-cyan;

    &:last-of-type() {
      margin-right: 0;
    }

    @media screen and (min-width: $new-laptop-s) {
      width: 175px;
      flex-direction: column;
      padding: 14px 27px 32px;
    }

    @media screen and (max-width: $new-laptop-s) {
      align-items: center;
      text-align: left;

      + .ways-to-buy__item {
        margin-top: 20px;
      }
    }
  }

  &__icon {
    width: 113px;
    min-width: 113px;
    height: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brand-white;
    border-radius: 100%;

    img {
      max-width: 60px;
      max-height: 60px;
    }

    svg {
      fill: $development-cyan;
    }

    @media screen and (min-width: $new-laptop-s) {
      margin: 0 auto 20px;
    }

    @media screen and (max-width: $new-laptop-s) {
      margin-right: 20px;
    }
  }

  &:hover {
    .ways-to-buy__item {
      color: $brand-white;
    }
  }
}
