.cta-cards-widget {
    margin-bottom: 40px;
}

.cta-cards-widget__grid {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.cta-cards-widget__column {
    flex: 0 1 310px;
    margin-bottom: 10px;

    @media screen and (max-width: 767px) {
        flex: 1 0 auto;
        margin-bottom: 0;
    }
}


////////////////////////////// CTA CARDS CARD COMPONENT
.cta-cards__card {
    display: block;
    height: 100%;
   

    &:hover,
    &:focus {
        text-decoration: none;

        .cta-cards__fake-btn {
            background-color: $brand-medium-grey;
            color: $brand-white;
        }

        .cta-cards__chevron {
            transform: translateX(10px);
        }

        .cta-cards__image {
            transform: scale(110%);
        }

        .cta-cards__title { 
            &:after {
                transform: scaleX(30%);
            }
        }
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 10px;
    }
}

.cta-cards__card-inner {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 991px) {
        padding-left: 5px;
        padding-right: 5px;
    }

    @media screen and (max-width: 767px) {
        padding: 0;
        flex-direction: row;
    }
}

.cta-cards__media-holder {
    line-height: 0;
    height: 152px;
    background-color: $development-cyan;
    overflow: hidden;
    flex: 0 0 152px;

    @media screen and (max-width: 767px) {
        width: 135px;
        height: auto;
        min-height: 90px;
        flex: 0 0 auto;
    }
}

.cta-cards__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
}

.cta-cards__content {
    background-color: $development-cyan;
    text-align: center;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 90px;

    @media screen and (max-width: 767px) {
        flex: 1 0 200px;
        text-align: left;
        flex-direction: row;
        padding: 20px 30px;
        align-items: center;
    }
}

.cta-cards__title {
    font-family: $base-font-family-med;
    color: $brand-white;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $brand-white;
        transform-origin: 50%;
        transform: scaleX(0);
        transition: transform 0.3s ease-out;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 21px;
        flex: 1 0 120px;
        padding-right: 20px;

        &:after {
            content: none;
        }
    }

    .umb-grid-row & {
        @media screen and (max-width: 767px) {
            margin-bottom: 0;
        }
    }
}

.cta-cards__text {
    color: $brand-white;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 25px;
    flex: 1 0 auto;

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.cta-cards__base {
    @media screen and (max-width: 767px) {
        flex: 0 0 20px;
        line-height: 0;
    }
}

.cta-cards__fake-btn {
    font-family: $base-font-family-med;
    background-color: $brand-white;
    color: $brand-primary;
    display: inline-block;
    padding: 10px 25px;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.cta-cards__chevron {
    color: $brand-white;
    height: 21px;
    width: 11px;
    transition: transform 0.3s;

    @media screen and (min-width: 768px) {
        display: none;
    }
}