.cta-bar {
  @include rem((
    padding: 15px,
    margin-bottom: 10px,
  ));

  background-color: rgba($brand-secondary, .3);
  position: relative;
  // color: $brand-white;

  h4,
  p {
    margin-bottom: 0;
    // color: $brand-white;
  }

  .btn {
    display: flex;
    align-self: flex-end;
    justify-content: center;

    &:hover {
      background-color: $brand-primary-dark;
      color: $brand-white;
    }
  }

  &--blog {
    margin-bottom: 0;
  }

  &--no-bg {
    background: none;
    padding: 0;

    h4,
    p {
      color: $brand-text;
    }

    h4 {
      @include rem((
        margin-bottom: 16px,
      ));
    }
  }

  .grid-column:last-child {
    display: flex;
    align-self: center;
  }


  .accordion__section & {
    .grid-column:last-child {
      display: flex;
      align-self: flex-start;
    }
  }

  .subscribe-area {
    p {
      margin-bottom: rem(10px);
    }

    .btn {
      color: $brand-white;
      font-family: $brand-font;
      max-width: rem(350px);
      padding: rem(9px);
      margin-top: rem(3px);

      @include gridle_state ( tablet-min ) {
        padding: rem(9px 24px);
      }

      &:hover {
        background-color: $brand-primary-dark;
      }
    }

    input {      
      display: inline-block;
    }

    input[type=email] {
      border: 2px solid $brand-button-border;
      width: 100%;
      height: rem(38px);

      @include gridle_state ( tablet-min ) {
        width: rem(315px);
        margin-right: rem(10px);
      }
    }    
  }

  .grid-column.blog-detail & {
    margin-bottom: rem(30px);
  }
}
