.banner-carousel__holder {
  position: relative;
}

.banner-carousel {
  position: relative;
  line-height: 0;

  .tns-controls {
    button {
      background-color: transparent;
      border: none;

      &:first-child {
        position: absolute;
        top: 50%;
        right: 90%;
        transform: translateY(-50%);
        z-index: 1;
      }

      &:last-child {
        position: absolute;
        top: 50%;
        left: 90%;
        transform: translateY(-50%);
        z-index: 1;
      }

      svg {
        fill: $brand-white;
      }
    }
  }

  .slick-arrow {
    @include netc-transition();
    position: absolute;
    top: 130px;
    z-index: 9;
    background: none;
    border: none;
    outline: none;
    @include gridle_state(tablet-min) {
      top: 245px;
    }

    .svg-icon {
      fill: $brand-white;
    }
  }

  .slick-prev {
    left: 0;

    @include gridle_state(tablet-min) {
      left: auto;
      right: 90%;
    }

    &:hover {
      @include gridle_state(tablet-min) {
        right: calc(90% + 5px);
      }
    }
  }

  .slick-next {
    right: 0;

    @include gridle_state(tablet-min) {
      right: auto;
      left: 90%;
    }

    &:hover {
      @include gridle_state(tablet-min) {
        left: calc(90% + 5px);
      }
    }
  }
}

.banner-carousel--development {
  .slick-arrow {
    @include transform-translate(0, -50%);
    position: absolute;
    top: 50%;
    z-index: 9;
    background: none;
    border: none;

    .svg-icon {
      fill: $brand-white;
    }
  }
}

.banner-carousel__back-btn {
  @include rem(
    (
      margin-bottom: 10px,
    )
  );
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 31%;
  @include gridle_state(tablet-min) {
    top: 15%;
    bottom: auto;
  }
  @include gridle_state(mobile-lg) {
    top: 42%;
    bottom: auto;
  }
  @include gridle_state(mobile) {
    top: 36%;
    bottom: auto;
  }
  @include gridle_state(small-tablet) {
    top: 16%;
    bottom: auto;
  }
}

.banner-carousel__top {
  position: relative;
}

.banner-carousel__image {
  @include rem(
    (
      height: 320px,
    )
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        height: 550px,
      )
    );
    width: 100%;
  }

  &::before {
    content: ' ';
    background: $brand-text-heading;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    position: absolute;
    z-index: 5;
  }
}

.banner-carousel__logo {
  @include transform-translate(-50%, -44%);
  position: absolute;
  left: 50%;
  top: 44%;
  text-align: center;
  width: 100%;
  max-width: 72%;
  z-index: 10;

  img {
    max-height: 400px;
    max-width: 100%;
    margin: 0 auto;

    @include gridle_state(tablet-min) {
      height: auto;
    }
  }
}

.banner-carousel__btn {
  @include transform-translate(-50%, 0);
  @include rem(
    (
      bottom: -25px,
    )
  );

  width: 100%;
  position: absolute;
  z-index: 5;
  left: 50%;
  text-align: center;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        bottom: 30px,
      )
    );

    width: auto;
  }
  .btn {
    display: inline;
  }
}

.banner-carousel__bottom {
  @include rem(
    (
      padding: 45px 0 15px,
      font-size: 20px,
      line-height: 24px,
    )
  );

  text-align: center;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        padding: 15px 0,
      )
    );
  }

  p {
    @include rem(
      (
        font-size: 18px,
        line-height: 24px,
      )
    );

    margin-bottom: 0;
  }

  .banner-carousel__pm {
    color: $brand-primary;
    font-style: italic;
  }
}

.show-available {
  color: $brand-primary;
  font-size: rem(16px);
}

.banner-carousel__fixed-logo {
  @include transform-translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  text-align: center;
  width: 100%;
  max-width: 72%;

  img {
    max-width: 100%;
    max-height: 200px;

    @include gridle_state(tablet-min) {
      height: auto;
    }
  }
}

.banner-carousel__text-logo {
  @include rem(
    (
      font-size: 40px,
    )
  );

  margin: 0;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: $brand-white;
  display: block;

  @include gridle_state(mobile-lg) {
    margin-top: 45%;
  }
  @include gridle_state(mobile) {
    margin-top: 15%;
  }
  @include gridle_state(tablet-min) {
    @include rem(
      (
        font-size: 90px,
      )
    );
  }

  &.is-active {
    display: block;
  }
}

.banner-carousel__message {
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  width: 100%;
  max-width: 90%;

  @include gridle_state(tablet-min) {
    white-space: nowrap;
    width: auto;
    max-width: 80%;
    text-overflow: ellipsis;
  }

  p {
    @include rem(
      (
        line-height: 48px,
      )
    );
    margin: 0;
  }

  &--fixed {
    @include transform-translate(-50%, 0);
    @include rem(
      (
        bottom: 5px,
      )
    );

    position: absolute;
    left: 50%;
    z-index: 3;
    background-color: $brand-white;

    @include gridle_state(tablet-min) {
      bottom: 15%;
    }

    p {
      @include rem(
        (
          padding: 0 10px,
        )
      );
      margin: 0;
    }
  }
}

.banner-carousel__message_title {
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  width: 100%;
  max-width: 90%;

  p {
    @include rem(
      (
        line-height: 48px,
      )
    );
    margin: 0;
  }

  &--fixed {
    @include transform-translate(-50%, 0);
    @include rem(
      (
        bottom: 5px,
      )
    );

    position: absolute;
    left: 50%;
    z-index: 3;
    background-color: $brand-white;

    @include gridle_state(tablet-min) {
      bottom: 15%;
    }

    p {
      @include rem(
        (
          padding: 0 10px,
        )
      );
      margin: 0;
    }
  }
}

.banner-carousel {
  .banner-carousel__top {
    picture {
      img {
        object-fit: cover;
        object-position: center center;
        font-family: 'object-fit: cover; object-position: center center;';
        @include gridle_state($mobile-lg) {
          max-width: 100%;
          width: 100%;
          height: 550px;
        }
      }
    }
  }
}
