$netc-core-cookie-background-color: $brand-light-grey;
.cookie {
  @include netc-transition();
  @include rem((
    padding: 15px 50px,
  ));
  border: none;
  background-color: $netc-core-cookie-background-color;
  position: fixed;
  bottom: -200px;
  right: 0;
  width: auto;
  z-index: 20;
  text-align: center;

  @include gridle_state ( mobile ) {
    @include rem((
      padding: 15px,
    ));
    border: none;
    width: 100%;
  }

  @media print {
    display: none;
  }

  &.is-active {
    bottom: 0;
    // @include gridle_state ( mobile ) {
    //   top: 0;
    //   bottom: auto;
    // }
  }

  &__info {
    @include transform-translate(0, -50%);
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 40px;

    @include gridle_state ( mobile ) {
      @include transform-translate(0, 0);
      position: relative;
      right: auto;
      top: auto;
    }

    span {
      text-decoration: none;
    }
  }

  p {
    @include rem((
      font-size: 12px,
    ));
    color: $brand-text !important;
  }
}
