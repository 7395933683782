.statistics {
    background-color: $development-cyan;
    margin: 30px 0 40px;
    position: relative;
}

.grid-column {
    > div > .statistics {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            height: 100%;
            width: 20px;
            background-color: $development-cyan;

            @media screen and (min-width:1600px) {
                content: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 20px;
            background-color: $development-cyan;

            @media screen and (min-width:1600px) {
                content: none;
            }
        }

        @media screen and (min-width:1600px) {
            width: 100vw;
            margin-left: calc((1540px - 100vw) / 2);
        }
    }
}

.statistics__bg-icon {
    color: $brand-white;
    opacity: 0.1;
    position: absolute;
    height: 204px;
    left: 0;
    top: 50%;
    z-index: 1;
    transform: translate(-40%, -50%);

    &--right {
        left: auto;
        right: 0;
        transform: translate(40%, -50%);
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.statistics__inner {
    padding: 23px 0;
}

.statistics__carousel {
    max-width: 800px;
    margin: auto;
}

.statistic__card-inner {
    margin: auto;
    text-align: center;
}

.statistic__card-stat {
    color: $brand-white;
    font-family: $brand-font;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 37px;
    }
}

.statistic__card-detail {
    color: $brand-white;
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 21px;
    margin: auto;
    margin-bottom: 0;
    max-width: 150px;

    @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
}