.rich-text-area {
  color: #000000;
  font-family: 'Lexend Deca', 'Arial', sans-serif;
  font-size: 16px;
  line-height: 28px;
  padding: 32px 0;

  ol {
    padding-left: 20px;
  }

  li {
    color: #000000;
    font-family: 'Lexend Deca', 'Arial', sans-serif;
    font-size: 16px;
    line-height: 28px;
  }

  .site-wide {
    max-width: 100ch;
  }
}
