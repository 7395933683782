.featured-developments {

    @media screen and (min-width: 1200px) {
        margin-bottom: 70px;
    }

    .featured-developments__title {
        font-family: $brand-font;
        font-size: 24px;
        line-height: 32px;
        color: $brand-primary;
        margin-bottom: 25px;
        text-align: center;
    
        @media screen and (min-width: 768px) {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 55px;
        }
    }
}

.featured-developments__inner {
    position: relative;

    .tns-controls {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        @media screen and (min-width: 1200px) {
            display: none;
        }

        button {
            background-color: $brand-primary;
            border: none;
            width: 34px;
            height: 34px;

            &:hover {
                background-color: $brand-primary-vlight;
            }

            &:disabled {
                opacity: 0.7;
            }

            .svg-icon {
                width: 8px;
                height: 14px;
            }
        }
    }

    .tns-inner {
        @media screen and (max-width: 767px) {
            margin-right: 20px !important;
        }
    }
}

.featured-developments__carousel {
    margin-bottom: 30px;

    @media screen and (min-width: 1200px) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 546px;
        align-content: space-between;
    }
}

.featured-developments__item {

    @media screen and (min-width: 1200px) {
        width: 49%;
    }

    &:first-child {
        @media screen and (min-width: 1200px) {
            height: 546px;
        }
    }

    &:nth-child(2) {
        @media screen and (min-width: 1200px) {
            height: 258px;
            margin-bottom: 28px;
        }
    }

    &:nth-child(3) {
        @media screen and (min-width: 1200px) {
            height: 258px;
        }
    }

    &:last-child:not(:nth-child(3)) {
        @media screen and (min-width: 1200px) {
            height: 396px;
            margin-bottom: 0;
        }
    }
    
}

.featured-dev-card {
    height: 100%;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
        height: 400px;
    }

    &:hover {
        .featured-dev-card__content {
            transform: translate(0, 0);
        }

        .featured-dev-card__indicator {
            opacity: 0;
        }

        .featured-dev-card__title {
            text-align: left;
        }

        .featured-dev-card__address {
            display: block;
        }

        .featured-dev-card__address-short {
            display: none;
        }
    }
}

.featured-dev-card__inner {
    position: relative;
    height: 100%;
}

.featured-dev-card__media {
    height: 100%;
    line-height: 0;
}

.featured-dev-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featured-dev-card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $brand-form-ele-bg;
    z-index: 1;
    width: 100%;
    transform: translate(55%, calc(100% - 80px));
    transition: transform 0.3s;
    overflow: hidden;

    @media screen and (max-width: 1199px) {
        transform: translate(20%, calc(100% - 80px));
    }

    @media screen and (max-width: 768px) {
        max-height: 350px;
    }
}

.featured-dev-card__indicator {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $brand-primary;    
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    svg {
        color: $brand-white;
        transform: rotate(45deg) translate(-3px, 0);
        width: 8px;
        height: 14px;
    }
}

.featured-dev-card__content-inner {
    padding: 20px;
}

.featured-dev-card__content-top {

    .featured-dev-card__title {
        font-family: $base-font-family-med;
        font-size: 20px;
        line-height: 27px;
        color: $brand-primary;
        margin-bottom: 2px;
        width: 40%;
        text-align: right;

        @media screen and (max-width: 1199px) {
            width: 75%;
        }
    }
}



.featured-dev-card__address {
    font-family: $base-font-family;
    font-size: 14px;
    line-height: 23px;
    color: $brand-button-border;
    opacity: 0.55;
    display: none;
}

.featured-dev-card__address-short {
    font-family: $base-font-family;
    font-size: 14px;
    line-height: 23px;
    color: $brand-button-border;
    opacity: 0.55;
    width: 40%;
    text-align: right;

    @media screen and (max-width: 1199px) {
        width: 75%;
    }
}

.featured-dev-card__content-grid {
    display: flex;

    @media screen and (max-width: 1199px) {
        display: block;
    }
}

.featured-dev-card__content-left {
    flex: 1 0 60%;
}

.featured-dev-card__rooms {
    font-family: $base-font-family;
    font-size: 12px;
    line-height: 16px;
    color: $brand-mob-menu;
    margin-bottom: 15px;
    position: relative;

    span {
        display: inline-block;
        vertical-align: top;
        padding-left: 20px;
    }

    svg {
        width: 15px;
        height: auto;
        vertical-align: top;
        color: $brand-primary;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.featured-dev-card__ownership {
    font-family: $base-font-family;
    font-size: 12px;
    line-height: 16px;
    color: $brand-mob-menu;
}

.featured-dev-card__ownership-item {
    position: relative;
    margin-top: 5px;

    span {
        display: inline-block;
        vertical-align: top;
        padding-left: 20px;
    }

    svg {
        width: 15px;
        height: auto;
        vertical-align: top;
        color: $brand-primary;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.featured-dev-card__content-right {
    flex: 1 0 auto;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-left: 15px;

    @media screen and (max-width: 1199px) {
        text-align: left;
        margin-top: 10px;
    }
}