.centered-form {
  padding: 30px 0;

  &__share {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-text {
      color: $brand-white;
      text-transform: uppercase;
      margin-top: 15px;
    }
  }

  &.centered-form--dev {
    background-color: #00a0a0;

    .centered-form__container {
      .share-btn {
        &:focus,
        &:hover {
          svg {
            fill: $brand-medium-grey;
          }
        }

        svg {
          fill: $brand-white;
        }
      }
    }

    .centered-form__share,
    .centered-form__title,
    .centered-form__intro,
    .centered-form__form-holder .form .fake-label,
    .centered-form__form-holder .form label {
      color: $brand-white;
    }

    .centered-form__form-holder {
      .form {
        .field-container {
          .select2-container,
          .select2-container--default,
          .select2-selection--single,
          input,
          textarea {
            background-color: transparent;
            border-color: $brand-white;
            color: $brand-white;

            &::placeholder {
              color: $brand-white;
              opacity: 0.7;
            }

            &:-ms-input-placeholder {
              color: $brand-white;
            }

            &::-ms-input-placeholder {
              color: $brand-white;
            }
          }
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
          color: $brand-white;
        }
        .select2-container--default .select2-selection--single .select2-selection__arrow b {
          background-image: url('/SiteFiles/src/ui/img/svg/chev-down-white.svg');
        }

        .field-container--currency {
          .icon {
            color: $brand-white;
            border-color: $brand-white;
          }
        }

        .field-container--icon-check {
          .field-container__group {
            input[type='checkbox']:checked + input[type='hidden'] + label,
            input[type='checkbox']:checked + label,
            input[type='radio']:checked + input[type='hidden'] + label,
            input[type='radio']:checked + label {
              background-color: $brand-white;

              p {
                color: #00a0a0;
              }

              .form__check-icon {
                svg {
                  fill: #00a0a0;
                }
              }
            }

            label {
              border-color: $brand-white;
            }

            p {
              color: $brand-white;
            }
          }
        }

        .form__check-icon {
          svg {
            fill: $brand-white;
          }
        }

        .btn--primary {
          font-family: $base-font-family-med;
          background-color: $brand-white;
          color: $brand-black;

          &:hover {
            background-color: $brand-medium-grey;
            color: $brand-white;

            svg {
              stroke: $brand-white;
              fill: $brand-white;
            }
          }

          svg {
            stroke: $brand-black;
            fill: $brand-black;
          }
        }
      }

      a {
        color: #ffffff;
      }
    }
  }

  @include gridle_state(tablet-min) {
    padding: 60px 0;
  }

  .share-btn {
    background: transparent;
    border: none;
    padding: 0;
    line-height: 0;
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }

    &:focus,
    &:hover {
      outline: none;

      svg {
        fill: $brand-primary-light;
      }
    }

    svg {
      fill: $brand-primary;
      width: 28px;
      height: 28px;
    }
  }
}

.centered-form__share {
  @include rem(
    (
      font-size: 12px,
      line-height: 15px,
    )
  );

  margin-bottom: 5px;
  color: $brand-primary;
  text-transform: uppercase;

  span,
  .share-btn {
    font-family: $base-font-family-med;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
  }
}

.centered-form__header {
  text-align: center;
  margin-bottom: 20px;

  @include gridle_state(tablet-min) {
    margin-bottom: 55px;
  }
}

.centered-form__title {
  @include rem(
    (
      font-size: 22px,
      line-height: 28px,
    )
  );

  font-family: $base-font-family-med;
  margin-bottom: 10px;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        font-size: 50px,
        line-height: 62px,
      )
    );
  }
}

.centered-form__intro {
  @include rem(
    (
      font-size: 14px,
      line-height: 26px,
    )
  );

  @include gridle_state(tablet-min) {
    @include rem(
      (
        font-size: 18px,
        line-height: 28px,
      )
    );
  }
}

.centered-form__form-holder {
  .btn {
    display: block;
    width: 100%;
    max-width: 100%;

    span {
      vertical-align: middle;
    }

    svg {
      width: 13px;
      height: 16px;
      stroke: $brand-black;
      fill: $brand-black;
      vertical-align: middle;
    }
  }

  .form {
    .field-container {
      max-width: 95%;

      @include gridle_state(tablet-min) {
        max-width: 100%;
      }
    }

    .field-container--icon-check {
      max-width: 100%;
    }

    .field-container__group {
      input[type='checkbox'] + input[type='hidden'] + label:before {
        content: '';
        background-color: $brand-white !important;
        border: 1px solid $brand-black;
        border-radius: 2px;
      }

      input[type='checkbox']:checked + input[type='hidden'] + label:before {
        background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
        background-repeat: no-repeat;
      }
    }
  }
}
.form {
  input[type='checkbox'] + label:before,
  input[type='checkbox'] + input[type='hidden'] + label:before,
  input[type='checkbox'] + label:after,
  input[type='checkbox'] + input[type='hidden'] + label:after,
  input[type='radio'] + label:before,
  input[type='radio'] + input[type='hidden'] + label:before,
  input[type='radio'] + label:after,
  input[type='radio'] + input[type='hidden'] + label:after {
    .centered-form__form-holder & {
      content: '';
    }
  }

  .field-container--icon-check {
    input[type='checkbox'] + label:before,
    input[type='checkbox'] + input[type='hidden'] + label:before,
    input[type='checkbox'] + label:after,
    input[type='checkbox'] + input[type='hidden'] + label:after,
    input[type='radio'] + label:before,
    input[type='radio'] + input[type='hidden'] + label:before,
    input[type='radio'] + label:after,
    input[type='radio'] + input[type='hidden'] + label:after {
      .centered-form__form-holder & {
        content: none;
      }
    }
  }
}

.register-form__2nd-half {
  display: none;

  &.is-active {
    display: block;
  }
}

.register-form__expand {
  margin-bottom: 10px;

  span {
    color: $brand-white;
  }

  &.is-active {
    display: none;
  }
}

.register-form__expand-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  color: $brand-white;
  text-decoration: underline;
}