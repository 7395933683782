//Vars
$card-header-height: 150px;
$card-media-border-radius: 50%;


.card {
  @include rem((
    margin-bottom: 18px,
  ));
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  
  // IE11 IMAGE HIEGHT FIX
  min-height: 1px;

  @include gridle_state ( mobile ) {
    width: 100%;
  }

  @media print {
    display: block;
  }

//CARD HEADER STYLES
  &__header {
    @include rem((
      padding-bottom: 15px,
    ));
    // display: flex;
    // flex-basis: $card-header-height;
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;

    // IE11 IMAGE HIEGHT FIX
    min-height: 1px;

    &--centered-horz {
      justify-content: center;
    }
    &--centered-vert {
      align-items: center;
    }
    &--centered-both {
      justify-content: center;
      align-items: center;
    }
  }

//CARD CONTENT STYLES
  &__content {
    @include rem((
      padding: 0 0 5px,
    ));

    flex-grow: 1;

    .media-card--small-tablet-split & {
      @include gridle_state ( tablet-portrait ) {
        @include rem((
          padding-left: 20px,
        ));
      }
    }
  }

//CARD FOOTER STYLES
  &__footer {
    padding: 5px 0;
  }

//CARD STANDARD IMAGE STYLES
  // img {
  //   width: 100%;
  //   max-width: 100%;
  // }

//CARD MEDIA WRAPPER STYLES
  .media-wrapper {
    max-height: $card-header-height;
    display: flex;
    align-items: center;

    &--rounded {
      width: 100%;

        img {
            border-radius: $card-media-border-radius;
        }

        padding: 6px 0;
        height: 140px;
        margin: 0 auto;
        width: 140px;
    }
  }

//IF CARDS ARE PLACED IN A CAROUSEL
  .carousel & {
    padding: 0 10px;
    height: auto;
    display: flex;
  }
}

.carousel {
  width: 100%;
  .slick-track {
    display: flex;
  }
}
