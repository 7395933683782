.mort-calc {

  .form {
    label {
      font-family: $base-font-family-med;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .slider-group {
    margin-bottom: 20px;

    label {
      font-family: $brand-font;
      font-size: 12px;
      line-height: 34px;
      padding-bottom: 0;
    }
  }

  .btn--primary {
    background-color: $brand-dark-grey;
    font-family: $brand-font;
  }
}

.mort-calc__title {
  font-family: $brand-font;
  text-decoration: underline;
}

.mort-calc__intro {
  font-size: 20px;
  line-height: 36px;
}

.mort-calc__results {
  margin: 20px 0;

  span {
    opacity: 0.5;
    font-family: $brand-font;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
  }

  .mort-calc__price {    
    font-size: 22px;
    line-height: 36px;
    color: $development-cyan;
    display: block;
    opacity: 1;
  }
}

.mort-calc__small-text {
  font-size: 12px;
  line-height: 16px;
}