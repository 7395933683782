.info-panel {
  width: 100%;

  @include gridle_state ( tablet-min ) {
    @include rem((
      max-width: 262px,
    ));
  }
}

.development-info {

  @include gridle_state ( tablet-min ) {
    display: flex;
    flex: 0 0 auto;
    align-items: flex-end;
    flex-direction: column;
  }

  form {
    width: 100%;

    @include gridle_state ( tablet-min ) {
      @include rem((
        max-width: 262px,
      ));
    }
  }
}

.detail-info-panel {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  	display: inline-block;
  }

  h1,
  h2,
  p {
    margin-bottom: 0;
  }

  .featured-card__detail {
    @include rem((
      margin-bottom: 3px,
    ));
  }
}

.detail-info-panel__top {
  @include rem((
    padding-bottom: 30px,
  ));
  display: block;

  @include gridle_state ( tablet-min ) {
    display: flex;
    flex: 0 0 auto;
  }
}

.detail-info-panel__bottom {
  @include rem((
    padding-top: 30px,
  ));

  border-top: 1px solid $brand-hr;
  display: block;

  @include gridle_state ( tablet-min ) {
    display: flex;
    flex: 0 0 auto;
  }
}

.detail-info-panel__left {
  @include rem((
    padding-left: 50px,
    margin-bottom: 20px,
  ));
  position: relative;

  @include gridle_state ( tablet-min ) {
    flex: 1 1 auto;
    margin-bottom: 0;
  }

  .icon {
    @include transform-translate(0, -30%);
    @include rem((
      height: 36px,
      width: 36px,
    ));

    position: absolute;
    left: 0;
    top: 50px;
    fill: $brand-black;
  }
}

.detail-info-panel__centre {
  @include rem((
    margin-bottom: 20px,
  ));

  @include gridle_state ( tablet-min ) {
    @include rem((
      padding-left: 10px,
      padding-right: 10px,
    ));
    flex: 1 0 auto;
    margin-bottom: 0,
  }
}

.detail-info-panel__right {

  @include rem((
    padding-left: 50px,
    margin-bottom: 20px,
  ));

  @include gridle_state ( tablet-min ) {
    padding-left: 0;
    flex: 1 0 auto;
    text-align: right;
  }

  & > .btn:first-child {
    @include rem((
      margin-top: 3px,
    ));
  }

  .btn,
  .button-input-holder {

    @include gridle_state ( tablet-min ) {
      float: right;
      clear: both;
    }
  }
}
