.development-subnav-wrapper {
  // used to avoid the page layout changing when the nav is fixed...
  height: 50px;

  @include gridle_state(desktop) {
    height: 102px;
  }
}

.development-subnav {
  position: relative;
  background-color: $development-cyan;

  &.active {
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    height: 50px;
    z-index: 50;

    @include gridle_state(desktop) {
      height: 102px;
    }
  }

  &__items {
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow-x: auto;
    padding: 0 50px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include gridle_state(desktop) {
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }

  &__item {
    display: inline-block;
    align-items: flex-end;
    position: relative;
    height: 50px;
    margin: 0 5px;
    color: $brand-white;
    font-family: $base-font-family-med;
    font-size: 14px;
    text-transform: capitalize;

    @include gridle_state(desktop) {
      display: flex;
      height: 102px;
      font-size: 16px;
    }

    @media screen and (min-width: 1366px) {
      margin: 0 10px;
    }

    &-inner {
      padding: 13px 0;
      color: $brand-white;
      font-family: $base-font-family-med;
      text-transform: capitalize;
      font-size: 16px;
      white-space: nowrap;
      cursor: pointer;

      @include gridle_state(desktop) {
        padding: 0 3px 20px;
      }

      @media screen and (min-width: 1366px) {
        padding: 0 10px 20px;
      }
    }

    &:hover {
      color: $brand-white;
      text-decoration: none;

      &:before,
      &:after {
        background-color: rgba($brand-white, 0.5) !important;
      }
    }

    .icon {
      width: 25px;
      height: 25px;
      position: absolute;
      fill: $brand-white;
      stroke: $brand-white;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include gridle_state(mobile tablet) {
        display: none;
      }
    }

    &:before {
      content: '';
      width: 0%;
      transition: width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }

    &::after {
      content: '';
      width: 0%;
      transition: width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }

    &.active,
    &:hover {
      &::after {
        content: '';
        position: absolute;
        width: 50%;
        height: 5px;
        background-color: $brand-white;
        bottom: 0;
        left: 0;
      }
      &::before {
        content: '';
        position: absolute;
        width: 50%;
        height: 5px;
        background-color: $brand-white;
        bottom: 0;
        right: 0px;
      }
    }
  }
}

.development-subnav__select-holder {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  background-color: $brand-white;
  width: 100%;
  padding: 5px 10px;
  z-index: 1;
  border-bottom: 1px solid $brand-primary;

  @media screen and (min-width: 1200px) {
    border-bottom: none;
  }

  @include gridle_state(desktop) {
    height: 100%;
    padding: 0;
    top: auto;
    left: auto;
    right: 30px;
    background-color: transparent;
    width: auto;
  }

  .development-subnav.active & {
    display: flex;
  }
}

.development-subnav__select {
  position: relative;
  color: $development-cyan;
  width: auto;
  height: 44px;
  text-transform: uppercase;
  padding: 6px 30px 6px 6px;
  margin: 0;
  font-family: $base-font-family-med;
  font-size: 15px;
  line-height: 34px;
  border: none;
  background-color: $brand-white;
  

  &:focus {
    outline: none;
  }

  svg {
    display: inline-block;
    stroke: $development-cyan;
    width: 14px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) rotate(-180deg);
  }
}

.development-subnav__title {
  font-family: $base-font-family;
  font-size: 14px;
  line-height: 19px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
}

.development-subnav__scroll-btn {
  display: none;
  border: none;
  background-color: $development-cyan;
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: 1;

  @include gridle_state(desktop) {
    display: none;
  }

  .development-subnav.active & {
    display: block;

    @include gridle_state(desktop) {
      display: none;
    }

    &.disable {
      display: none;
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    width: 15px;
    stroke: $brand-white;
  }

  &--left {
    left: 0;

    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 160, 160, 1) 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 0;
      left: 100%;
    }

    svg {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &--right {
    right: 0;

    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 160, 160, 1) 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 0;
      right: 100%;
    }

    svg {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
}
