.testimonial-carousel {
    margin: 30px 0 70px;
    position: relative;
}

.testimonial-carousel__inner {

    .tns-controls {
        position: absolute;
        bottom: 69px;
        right: 0;
        z-index: 1;

        @media screen and (min-width: 768px) {
            bottom: 0;
            right: 50%;
        }

        button {
            background-color: $brand-primary;
            border: none;
            width: 50px;
            height: 50px;

            &:hover {
                background-color: $brand-primary-vlight;
            }

            &:disabled {
                backdrop-filter: blur(5px);
                background-color: transparent;
            }

            .svg-icon {
                width: 11px;
                height: 20px;
            }
        }
    }
}

.testimonial-carousel__top {

    .testimonial-carousel__title {
        margin-bottom: 15px;

        @media screen and (min-width: 768px) {
            margin-bottom: 55px;
        }
    }
}

.testimonial-carousel__title {
    font-family: $brand-font;
    text-align: center;
    color: $brand-primary;
    font-size: 24px;
    line-height: 32px;
    
    @media screen and (min-width: 768px) {
        font-size: 30px;
        line-height: 40px;
    }
}

.testimonial-card__grid {
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.testimonial-card__media-area {
    flex: 1 0 50%;
    text-align: right;
}

.testimonial-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonial-card__content-area {
    flex: 1 0 50%;
    display: flex;
    align-items: center;
    padding: 10px 30px;

    @media screen and (max-width: 767px) {
        padding-top: 0;
    }
}

.testimonial-card__content-area-inner {
    max-width: 600px;
    margin: auto;

    .btn {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.testimonial-card__quote {
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (min-width: 768px) {
        text-align: left;
    }
}

.testimonial-card__source {
    font-family: $base-font-family-med;
    font-size: 12px;
    line-height: 28px;
    text-align: center;

    @media screen and (min-width: 768px) {
        text-align: left;
    }
}

.testimonial-carousel__base {
    text-align: center;
    margin: 10px 0;
    
    @media screen and (min-width: 768px) {
        display: none;
    }
}