.pagination-controls--center {
    justify-content: center;
}

.pagination-control li {
    display: inline-block;
    margin-right: 5px;
}
.pagination-control li a:hover {
    background: #cbcaca;
    color: #fff;
    border-radius: 16px;
}
.pagination-control li a.btn.aactive,
.pagination-control li .btn.aactive {
    background: #00a0a0;
    color: #fff;
    pointer-events: none;
    border-radius: 16px;
}
.pagination-control ul {
    margin: 0;
    list-style-type: none;
}
.pagination-control li:before {
    content: "";
}