//////////////////////////////////////////////////////////////////////////////// BUTTON STYLES
.btn {
  @include rem(
    (
      padding: 9px 24px,
      margin: 5px 0,
    )
  );
  text-transform: uppercase;
  color: $brand-medium-grey;
  outline: none;
  font-family: $brand-font;
  text-align: center;

  &:hover,
  &:active {
    box-shadow: none;
    outline: none;
    text-decoration: none;

    .icon {
      fill: $brand-white;
    }
  }

  &--small {
    @include rem(
      (
        padding: 3px 15px,
        margin: 5px 0,
        font-size: 14px,
      )
    );
  }

  &--set-width {
    width: 100%;

    @include gridle_state(tablet-min) {
      @include rem(
        (
          max-width: 262px,
        )
      );
    }
  }

  &--iconed {
    @include rem(
      (
        padding: 11px 70px 9px 40px,
      )
    );

    background-repeat: no-repeat;
    background-position: 93% center;
    background-size: 26px;
    position: relative;

    .icon {
      @include transform-translate(0, -50%);
      @include rem(
        (
          right: 15px,
          width: 26px,
          height: 26px,
        )
      );

      position: absolute;
      top: 50%;
    }
  }

  &--iconed-chev {
    @include rem(
      (
        padding: 11px 70px 9px 40px,
      )
    );

    .chev-holder {
      @include rem(
        (
          right: 15px,
          width: 22px,
          height: 22px,
        )
      );

      transform: scaleY(-1) translateY(-50%);
      transform-origin: 50% 0;
      position: absolute;
      top: 50%;

      .icon {
        @include rem(
          (
            width: 22px,
            height: 22px,
            right: 2px,
          )
        );
      }
    }

    &.is-active {
      .chev-holder {
        transform: scaleY(1) translateY(-50%);

        .blog-filters & {
          transform: rotate(1deg);
        }
      }
    }
  }

  &--bordered {
    outline: 2px solid $brand-button-border;
    border: none;

    &:hover,
    &:active {
      outline: 2px solid $brand-primary;
      background-color: $brand-primary;
      color: $brand-white;
    }
  }

  &--bordered-alt {
    outline: 2px solid $brand-secondary;
    border: none;

    &:hover,
    &:active {
      background-color: $brand-primary;
      color: $brand-white;
    }
  }

  //////////////////////////////////////////////////////////////////////////////// BUTTON BORDERS
  &--border-bottom {
    border-bottom: 3px solid $brand-black;
  }
  &--border-top {
    border-top: 3px solid $brand-black;
  }
  &--border-left {
    border-left: 3px solid $brand-black;
  }
  &--border-right {
    border-right: 3px solid $brand-black;
  }

  //////////////////////////////////////////////////////////////////////////////// BUTTON VARIENTS
  &--primary {
    background-color: $brand-primary;
    color: $brand-white;

    &:hover,
    &:active {
      background-color: $brand-primary-dark;
      color: $brand-white;
    }

    .icon {
      fill: $brand-white;
    }
  }

  &--primary-light {
    background-color: $brand-primary-light;
    color: $brand-text;

    &:hover,
    &:active {
      background-color: $brand-primary-dark;
      color: $brand-white;
    }

    .icon {
      fill: $brand-white;
    }
  }

  &--secondary {
    background-color: $brand-secondary;
    color: $brand-white;

    &:hover,
    &:active {
      background-color: $brand-secondary-dark;
      color: $brand-white;
    }
  }

  &--tertiary {
    background-color: $brand-tertiary;
    color: $brand-white;

    &:hover,
    &:active {
      background-color: $brand-secondary-dark;
      color: $brand-white;
    }

    .icon {
      fill: $brand-white;
    }
  }

  &--blog {
    background-color: rgba($brand-text-heading, 0.6);
    color: $brand-white;

    &:hover,
    &:active {
      background-color: rgba($brand-text-heading, 0.6);
      color: $brand-white;
    }
  }

  &--white {
    outline: 2px solid $brand-white;
    background-color: $brand-white;
    color: $brand-primary;

    &:hover,
    &:active {
      outline: 2px solid $brand-light-grey;
      background-color: $brand-light-grey;
    }
  }

  &--dark {
    outline: 2px solid $brand-button-border;
    background-color: $brand-button-border;
    color: $brand-white;

    &:hover,
    &:active {
      outline: 2px solid $brand-primary;
      background-color: $brand-primary;
      color: $brand-white;
    }

    .icon {
      fill: $brand-white;
    }
  }

  &--light {
    background-color: $brand-light-grey;
    outline: 2px solid $brand-light-grey;

    &:hover,
    &:active {
      background-color: $brand-primary;
      outline: 2px solid $brand-primary;
      color: $brand-white;
    }
  }

  &--back {
    @include rem(
      (
        padding: 11px 40px 9px 55px,
      )
    );

    background-color: $brand-dark-grey;
    color: $brand-white;
    position: relative;

    .chev-holder {
      @include rem(
        (
          left: 5px,
          width: 22px,
          height: 22px,
        )
      );

      transform: rotate(270deg);
      transform-origin: 50% 0;
      position: absolute;
      top: 50%;

      .icon {
        @include rem(
          (
            width: 22px,
            height: 22px,
          )
        );
        right: 0;
        fill: $brand-white;
      }
    }

    &:hover,
    &:active {
      background-color: $brand-primary-dark;
      color: $brand-white;
    }

    .icon {
      fill: $brand-white;
    }
  }
}

.child-nav,
.tab-nav {
  .btn {
    background-color: $brand-light-grey;
    border: none;

    &:hover,
    &:active {
      background-color: $brand-primary-dark;
      color: $brand-white;
      border: none;

      .icon {
        fill: $brand-light-grey;
      }
    }
  }

  .is-active {
    .btn {
      background-color: $brand-primary;
      color: $brand-white;
    }
  }
}

.form {
  .btn {
    width: 100%;
    border: none;

    @include gridle_state(tablet-min) {
      @include rem(
        (
          min-width: 200px,
          max-width: 225px,
        )
      );
    }

    &--wide {
      @include gridle_state(tablet-min) {
        @include rem(
          (
            min-width: 200px,
            max-width: 275px,
          )
        );
      }
    }
  }
}

.filter-area,
.download-area {
  @include rem(
    (
      margin: 5px 0,
    )
  );

  .btn {
    width: 100%;
    margin: 0;
  }
}

.cta-bar {
  .btn {
    @include rem(
      (
        margin-top: 15px,
      )
    );

    width: 100%;
    border: none;
    color: $brand-white;

    @include gridle_state(tablet-min) {
      @include rem(
        (
          max-width: 262px,
          margin-top: 5px,
        )
      );
    }

    &:hover,
    &:active {
      background-color: $brand-secondary-dark;
      color: $brand-primary;
      border: none;
    }
  }
}

.netc-calendar-theme .pika-prev,
.netc-calendar-theme .pika-next {
  color: $brand-primary;
}

.netc-calendar-theme .pika-button:hover {
  color: $brand-text-dark;
  background: $brand-secondary;
}

.pika-lendar {
  .is-selected {
    .pika-button {
      color: $brand-white;
      background: $brand-primary-dark;
      box-shadow: none;
      border-radius: 0;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////// INPUT BUTTONS
.button-input-holder {
  width: 100%;
  position: relative;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        max-width: 262px,
      )
    );

    float: right;
  }

  .button-input-holder__btn {
    @include rem(
      (
        height: 44px,
        padding: 10px 70px 10px 40px,
      )
    );

    .icon {
      fill: $brand-black;
    }

    &.btn--bordered-alt {
      .icon {
        fill: $brand-primary;
      }
    }
  }

  input[type='button'] {
    @include rem(
      (
        height: 44px,
        padding-top: 10px,
      )
    );

    position: absolute;
    right: 0;
    top: 0;
    color: $brand-medium-grey;
  }

  &:hover {
    .button-input-holder__btn {
      background-color: $brand-secondary;

      .icon {
        fill: $brand-white;
      }
    }

    input[type='button'] {
      color: $brand-white;
    }
  }
}

.step-btn {
  border: solid 1px $brand-primary;
  border-radius: 2px;
  display: inline-block;
  flex: 0 0 45%;
  height: 196px;
  padding: 10px 0 30px;
  transition: background 0.3s, box-shadow 0.3s;
  -webkit-box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0);
  margin-bottom: 30px;

  @include gridle_state(tablet-min) {
    flex: 0 0 191px;
    margin-right: 30px;
  }

  &:hover {
    text-decoration: none;
    background-color: $brand-primary;
    -webkit-box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.12);

    .step-btn__middle {
      .icon {
        fill: $brand-white;
        stroke: $brand-white;
      }
    }

    .step-btn__bottom {
      color: $brand-white;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.step-btn__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.step-btn__top {
  flex: 0 0 21px;
}

.step-btn__middle {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 70px;

  .icon {
    transition: fill 0.3s, stroke 0.3s;
    fill: $brand-primary;
    stroke: $brand-primary;

    @include rem(
      (
        width: 60px,
        height: 60px,
      )
    );
  }
}

.step-btn__bottom {
  transition: color 0.3s;
  flex: 0 0 auto;
  text-align: center;
  color: $brand-primary;
  font-family: $base-font-family-med;
  font-size: 18px;
  line-height: 22px;
  padding: 0 10px;
}

.step-btn__flag {
  padding: 3px 10px;
  display: inline-block;
  text-transform: uppercase;
  color: $brand-white;
  background-color: $blog-orange;
  font-family: $base-font-family-med;
  font-size: 12px;
  line-height: 15px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 7px;
    height: 100%;
    background-color: $blog-orange;
    position: absolute;
    right: 100%;
    top: 0;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 0;
    border-color: transparent #c64d00 transparent transparent;
    position: absolute;
    right: calc(100% + 1px);
    top: 100%;
  }
}

// new buttons for 2020 development designs
.btn--new.btn--new,
.btn--fake.btn--fake {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  font-family: $brand-font;
  font-size: 14px;
  letter-spacing: 1.5px;
  border: 1px solid $brand-dark-grey;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  padding: 9px 10px;

  @include gridle_state(tablet-min) {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
  }

  @include gridle_state(desktop) {
    padding-left: 30px;
  }

  > a {
    color: $brand-black;
  }

  .tabbed-content-features__item-text & {
    @include gridle_state(desktop) {
      padding-left: 24px;
      padding-right: 34px;
    }
  }

  svg {
    width: 20px;
    margin-left: 10px;
    transition: fill 0.4s ease-in-out, stroke 0.4s ease-in-out;
    stroke: $brand-dark-grey;
    fill: none;
  }

  &:hover {
    color: $brand-white;
    background-color: $brand-dark-grey;

    svg {
      stroke: $brand-white;
    }

    > a {
      color: $brand-white;
    }
  }
}

.btn--fake {
  position: relative;

  .btn--fake__inner {
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
    }
  }

  span,
  .social-share {
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  }

  span {
    svg {
      transform: translateY(-2px);
    }
  }

  .social-share {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    font-size: 12px;
    color: $brand-white;
    text-transform: uppercase;

    a {
      display: flex;

      svg {
        fill: $brand-white;
        margin-left: 0;
        height: 18px;
      }
    }
  }

  @media (hover: none) {
    &.is-active {
      background-color: $brand-dark-grey;

      span {
        opacity: 0;
        visibility: hidden;
      }

      .social-share {
        opacity: 1;
        visibility: visible;

        svg {
          text-decoration: none;
        }
      }
    }
  }

  &:hover {
    span {
      opacity: 0;
      visibility: hidden;
    }

    .social-share {
      opacity: 1;
      visibility: visible;

      a {
        text-decoration: none;
        color: $brand-white;
        line-height: 20px;
        font-size: 20px;
        padding: 0 4px;
      }

      span {
        opacity: 1;
        visibility: visible;
        padding-top: 2px;
      }

      svg {
        text-decoration: none;
      }
    }
  }
}

.btn--block.btn--block {
  background-color: $brand-dark-grey;
  color: $brand-white;

  svg {
    width: 18px;
    fill: $brand-white;
    stroke: transparent;
    transform: translateY(-1px);
  }

  &:hover {
    color: $brand-dark-grey;
    background-color: $brand-white;

    svg {
      fill: $brand-dark-grey;
    }
  }
}

.share-button {
  position: relative;
  overflow: hidden;
}
