$brand-black: #000000;
$brand-white: #ffffff;

$brand-primary: #008484;
$brand-primary-dark: rgba(#7c7b7b, 0.6);
$brand-primary-light: rgba(#008484, 0.3);
$brand-primary-vlight: #8BD3D3;
$brand-secondary: $brand-primary;
$brand-secondary-dark: $brand-primary-dark;
$brand-tertiary: $brand-primary;
$brand-tertiary-dark: $brand-primary-dark;

$brand-grey: #ebecea;
$brand-light-grey: #efefef;
$brand-medium-grey: #5d5d5d;
$brand-dark-grey: #333333;
$brand-dark-orange: #ed674f;
$brand-hr: #cccccc;
$brand-border: #bfbfbf;
$brand-button-border: #7c7b7b;
$brand-select-highlight: #999999;
$brand-form-ele-bg: #fafafa;

$brand-text: #5d5d5d;
$brand-text-dark: #5d5d5d;
$brand-text-heading: #7c7b7b;
$brand-mob-menu: #333333;

$development-cyan: #00a0a0;
$development-blue: #00313e;
$development-orange: #c64d00;
$development-light-grey: #f6f6f6;

// BLOG COLOURS
$blog-orange: #eb8c44;
$blog-blue: #5cb4e6;
$blog-red: #c8393a;
$blog-brown: #a45248;
$blog-purple: #963683;
$blog-green: #74aa50;
