//////////////////////////////////////////////////////////////////////////////// HEADER STYLES
.header {
  @include rem(
    (
      height: 66px,
    )
  );

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  font-family: $base-font-family;
  transition: background 0.3s;
  background: $brand-white;

  @media screen and (min-width: 768px) {
    @include rem(
      (
        padding: 0 15px,
        height: 92px,
      )
    );
  }

  @media screen and (min-width: 992px) {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    
    @include rem(
      (
        height: 102px,
      )
    );
  }

  &.is-scrolled.notification-active {
    .header__main {
      @media screen and (max-width: 991px) {
        background-color: $brand-white;
      }
    }
  }

  

  hr {
    margin: 0;
    border: none;
    border-top: 1px solid $brand-hr;
  }

  &__main {    
    position: relative;
    z-index: 3;

    @media screen and (max-width: 991px) {
      @include netc-transition();
      @include rem(
        (
          margin-left: -15px,
          margin-right: -15px,
        )
      );
      height: 66px;      
    }
  }

  ///////////////////////////////////////// MOBILE MENU HEADER STYLES
  &.is-open {
    .header__main {
      background-color: $brand-white;
      @media screen and (max-width: 991px) {
        background: $brand-mob-menu;
      }
    }

    .header__logo-holder {
      .icon {
        @media screen and (max-width: 991px) {
          color: $brand-white;
        }
      }
    }

    .header__icon-btn {
      @media screen and (max-width: 991px) {
        display: none;
      }

      &:last-child {
        @media screen and (max-width: 991px) {
          display: inline-block;
        }
      }
    }
  }

  &.is-transparent:not(.is-scrolled) {
    background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 90%);

    @media screen and (min-width: 992px) {
      box-shadow: none;
    }

    .header__logo-holder {
      .icon {
        color: $brand-white;
      }
    }

    .header__nav-item {
      &:first-child {
        > a {
          svg {
            display: inline-block;

            @media screen and (max-width: 991px) {
              display: none;
            }
          }
        }
      }
      
      > a {
        color: $brand-white;

        svg {
          width: 16px;
          height: 16px;
          display: none;
          margin-right: 5px;
          vertical-align: top;
        }
      }
    }

    .header__icon-menu-holder { 
      a {
        color: $brand-white;       
      }
    }

    
      &:hover {
        @media screen and (min-width: 992px) {
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
          box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
          background: $brand-white;
        }

        .header__logo-holder {
          .icon {
            @media screen and (min-width: 992px) {
              color: $brand-primary;
            }
          }
        }
  
        .header__nav-item {
          > a {
            @media screen and (min-width: 992px) {
              color: $brand-primary;
      
              &:hover {
                color: $brand-medium-grey;
              }
            }
          }
        }
  
        .header__icon-menu-holder {
          a {
            @media screen and (min-width: 992px) {
              color: $brand-medium-grey;
    
              &:hover {
                color: $brand-primary;
              }
            }
          }
        }
      }    
  }
}

.header__container {
  @include rem(
    (
      padding: 12px 15px,
    )
  );

  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: center;

  @media screen and (min-width: 768px) {
    @include rem(
      (
        padding: 20px 0,
      )
    );
  }

  @media screen and (min-width: 992px) {
    @include rem(
      (
        padding: 25px 0,
      )
    );
  }

  .notification-active & {
    @media screen and (min-width: 992px) {
      @include rem(
        (
          padding: 15px 0 25px,
        )
      );
    }
  }
}

//////////////////////////////////////////////////////////////////////////////// HEADER LOGO
.header__logo-holder {
  @include rem(
    (
      padding: 0,
    )
  );

  flex: 0 0 20%;


  a {
    display: block;
    line-height: 0;
  }

  .icon {
    @include rem(
      (
        height: 35px,
        width: 150px,
      )
    );

    color: $brand-primary;
    transition: color 0.3s;

    @media screen and (min-width: 768px) {
      @include rem(
        (
          height: 43px,
          width: 200px,
        )
      );
    }
  }
}

.development {
  .header__logo-holder {
    @media screen and (min-width: 992px) {
      flex: 0 0 20%;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////// HEADER MENU HOLDER
.header__menu-holder {
  @include rem(
    (
      padding: 10px 0 100px,
      top: 66px,
    )
  );

  position: fixed;
  left: 100%;
  border-top: 1px solid $brand-white;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  z-index: 2;

  @media screen and (max-width: 991px) {
    @include netc-transition();
    background-color: $brand-mob-menu;
  }

  @media screen and (min-width: 768px) {
    overflow: visible;
    @include rem(
      (
        top: 92px,
      )
    );
    flex: 1 0 76%;
  }

  @media screen and (min-width: 992px) {
    @include rem(
      (
        padding: 10px 0 0,
      )
    );

    min-height: 10px;
    top: auto;
    left: auto;
    position: relative;
    width: auto;
    text-align: right;
    padding: 0;
    background-color: transparent;
    border: none;
    flex: 1 0 68%;
  }

  &.is-active {
    left: 0;

    @media screen and (min-width: 992px) {
      left: auto;
    }
  }

  .footer__content {
    text-align: center;
  }
}

.development {
  .header__menu-holder {
    @media screen and (min-width: 768px) {
      flex: 0 0 60%;
    }

    @media screen and (min-width: 992px) {
      top: -100px;
    }

    &.is-active {
      @media screen and (min-width: 992px) {
        top: 0;
      }
    }
  }
}

.header__icon-menu-holder {

  flex: 1 0 auto;
  text-align: right;

  @media screen and (min-width: 992px) {
    @include rem(
      (
        margin-top: -2px,
      )
    );

    flex: 0 0 40px;
  }

  a {
    @include rem(
      (
        font-size: 14px,
      )
    );

    color: $brand-medium-grey;
    transition: color 0.3s;
    // text-transform: uppercase;

    &:hover {
      text-decoration: none;
      color: $brand-primary;

      span {
        text-decoration: underline;
        text-decoration-skip: auto;
      }
    }

    .svg-icon {
      @include rem(
        (
          width: 25px,
          margin-left: 6px,
        )
      );
    }

    .svg-icon--lock {
      @include rem(
        (
          width: 21px,
        )
      );
    }
  }

  .header__login-btn {
    @media screen and (min-width: 1200px) {
      margin-left: rem(15px);
    }

    .svg-icon {
      @include rem(
        (
          height: 30px,
        )
      );
    }

    .logged-in {
      @media screen and (min-width: 768px) {
        display: inline-block;
      }

      @media screen and (min-width: 992px) {
        display: block;
      }
      @media screen and (min-width: 1200px) {
        display: inline-block;
      }
    }

    &:hover {
      .logged-in {
        text-decoration: none;
      }
    }
  }

  .header__menu-btn {
    @include helpers-burger(25px, 2px, 8px);
    @include rem(
      (
        margin-left: 6px,
      )
    );

    .is-transparent:not(.is-scrolled) & {
      @include helpers-burger(25px, 2px, 8px, $brand-white);
    }
  }

  .is-open &,
  .is-open.is-transparent:not(.is-scrolled) & {
    .header__menu-btn {
      @include helpers-burger-to-cross;

      &:before,
      &:after {
        background-color: $brand-white;
        @media screen and (min-width: 992px) {
          background-color: $brand-black;
        }
      }

      
    }
  }

  form {
    display: inline-block;
  }

  input[type='submit'] {
    @include rem(
      (
        padding: 5px,
      )
    );
    background-color: $brand-white;
    border: none;
  }
}

.development {
  .header__icon-menu-holder {
    @media screen and (min-width: 992px) {
      flex: 0 0 20%;
    }
  }
}

.header__icon-btn {
  cursor: pointer;
  line-height: 0;
  position: relative;
  text-decoration: none;

  &:hover {
  > .header__icon-btn__dropdown {
      display: block;
      text-decoration: none;
    }
  }

  span {
    display: none;
    @media screen and (min-width: 992px) {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.1;
      padding: 10px 0;
    }

    @media screen and (min-width: 1200px) {
      line-height: rem(24px);
      padding: 0;
    }

    br {
      display: none;

      @media screen and (min-width: 992px) {
        display: inline-block;
      }

      @media screen and (min-width: 1200px) {
        display: none;
      }
    }
  }

  .svg-icon--search-black {
    width: 26px;
    height: 26px;
  }
}

.header__icon-btn__dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  padding-top: 47px;  
  background-color: $brand-white;
  display: none;

  span {
    font-family: $base-font-family-med;
    display: block;
    padding: 10px 20px;
    border-top: 3px solid $brand-primary;
  }
}

.development {
  .header__icon-btn {
    span {
      display: none;
    }
  }
}

.header__social {
  li {
    display: inline-block;
  }
}