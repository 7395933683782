.development-access-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 40px;
    margin: 45px 0;
    max-width: 600px;

    @include gridle_state(mobile) {
        grid-template-columns: 1fr;
    }
}

.development-access-item {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        width: 24px;
        height: 24px;
    }
}