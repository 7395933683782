.fancybox-slide {
  &:before {
    display: none;
  }

  > div {
    margin-top: rem(77px);
  }
}

.modal-container {
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 10;
  opacity: 0;

  &.is-active {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .fancybox-slide & {
    overflow: visible;

    @include gridle_state(desktop-small) {
      margin: 22.5vh 0 44px;
    }
  }

  @include gridle_state(mobile) {
    margin-top: 0;
  }
}

.modal-container__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($brand-black, 0.8);
  z-index: 0;
}

.modal-container__inner {
  background-color: $brand-white;
  padding: 80px 75px;
  position: relative;
  max-width: rem(872px);
  width: 100%;
  z-index: 1;
}

.wrapper-close-btn {
  position: absolute;
  top: rem(10px);
  right: rem(20px);
  width: rem(32px);

  .btn {
    max-width: rem(32px);
    height: rem(32px);
    width: 100%;
    background: url(~img/svg/cross-black.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}

.site-search__title {
  color: $brand-primary;
  margin-bottom: rem(10px);
}

.site-search__search-field-container {
  position: relative;

  .site-search__search-button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 20px;
  }

  .site-search__search-field {
    margin: 0;
    height: rem(44px);
    border: 2px solid $brand-primary-dark;
    outline: none;
  }

  &--burger {
    padding: rem(12px) rem(20px);

    .site-search__search-button {
      right: 35px;
    }
  }
}
