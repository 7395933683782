.peek-carousel {
    margin: 50px 0 0;

    .slick-list {
      padding: 0 80px 0 0;
    }

    

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        > div {
          height: 100% !important;
        }
    }
}

.peek-carousel__item {
    padding: 15px;
    height: 100%;
}

.peek-carousel__card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    transform: translateY(0);
    transition: transform 0.3s, box-shadow 0.3s;
    -webkit-box-shadow: 0px 13px 15px 0px rgba(0,0,0,0);
    -moz-box-shadow: 0px 13px 15px 0px rgba(0,0,0,0);
    box-shadow: 0px 13px 15px 0px rgba(0,0,0,0);
    height: 100%;


    &:hover {
        text-decoration: none;
        transform: translateY(-10px);
        -webkit-box-shadow: 0px 13px 15px 0px rgba(0,0,0,0.12);
        -moz-box-shadow: 0px 13px 15px 0px rgba(0,0,0,0.12);
        box-shadow: 0px 13px 15px 0px rgba(0,0,0,0.12);
    }
}

.peek-carousel__price {
    position: absolute;
    top: 20px;
    left: 30px;
    background-color: $brand-primary;
    color: $brand-white;
    font-family: $base-font-family-med;
    font-size: 14px;
    line-height: 18px;
    padding: 3px 20px 3px 26px;
    border-radius: 20px;
    z-index: 1;

    .icon {
      position: absolute;
      left: 8px;
      top: 5px;
      fill: $brand-white;
      display: inline-block;
      width: 13px;
      height: 13px;
    }
}

.peek-carousel__media {
    line-height: 0;

    picture {
        img {
            object-fit: cover;
            object-position: center center;
            font-family: 'object-fit: cover; object-position: center center;';
            width: 100%;
            height: 190px;
        }
    }
}

.peek-carousel__content-holder {
    padding: 20px 30px;
    background-color: $brand-white;
    text-align: left;
    flex: 1 0 auto;
}

.peek-carousel__tag {
    color: $brand-primary;
    text-transform: uppercase;
    font-family: $base-font-family-med;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
}

.peek-carousel__title {
    font-size: 22px;
    line-height: 28px;
    font-family: $brand-font;
    margin-bottom: 5px;
}

.peek-carousel__address {
    font-size: 14px;
    line-height: 18px;
    font-family: $base-font-family;
    padding-left: 25px;
    position: relative;

    .icon {
      position: absolute;
      left: 0;
      top: 0;
      fill: $brand-primary;
      display: inline-block;
      width: 18px;
      height: 20px;
    }
}

.peek-carousel__beds {
    font-size: 14px;
    line-height: 18px;
    font-family: $base-font-family;
    padding-left: 25px;
    position: relative;

    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      fill: $brand-primary;
      display: inline-block;
      width: 14px;
      height: 14px;
    }
}
.resale-related-carousel {
  .slick-arrow-resale {
    background-color: $brand-primary;


  @include rem((
    width: 40px,
    height: 40px,
  ));

  @include gridle_state ( tablet-min ) {
    @include rem((
      width: 50px,
      height: 50px,
    ));
  }

  &:hover {
    background-color: rgba($brand-primary, 0.8);
  }

  .svg-icon {
    fill: $brand-white;

    @include rem((
        height: 16px,
        width: 9px,
      ));

    @include gridle_state ( tablet-min ) {
      @include rem((
        height: 20px,
        width: 11px,
      ));
    }
  }
}

.slick-prev {

    @include gridle_state ( tablet-min ) {
      right: 145px;
    }
}


}