/**
 * Add global variables in this file.
 */

// setup image path
$image-path: '~img/';

// setup burger button variables
$burger-gutter: 3px;
$burger-height: 5px;

// setup the global margin-bottom for components
$global-mb: 40px;

// setup the global margin-bottom for elements (<p>, <buttons>, <input> etc)
$global-element-mb: 16px;

// used by rem mixin
$rem-baseline: 16px;

// add rem value to HTML tag
html {
	@include rem-baseline; // Default zoom to 100%
}

// setup global transition time
$global-transition-time: 0.3s;

// setup global transition type
$global-transition-type: ease-in-out;
