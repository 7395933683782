.featured-table {
  color: $brand-text-dark;
  font-family: $base-font-family;

  .featured-card & {
    display: none;
  }

  &.is-active {
    display: block;
  }

  tr {
    &:last-child {
      td {
        border-bottom: 1px solid $brand-black;
      }
    }
  }

  th {
    @include rem(
      (
        padding: 15px,
        font-size: 15px,
        line-height: 17px,
      )
    );

    text-transform: uppercase;
    border-bottom: 1px solid $brand-black;
    font-weight: 400;
    text-align: left;

    @include gridle_state(mobile) {
      @include rem(
        (
          padding: 15px 7px,
          font-size: 14px,
          line-height: 16px,
        )
      );
    }
  }

  td {
    @include rem(
      (
        padding: 7px 15px,
        font-size: 15px,
        line-height: 17px,
      )
    );

    border-top: 1px solid rgba($brand-black, 0.2);
    vertical-align: middle;

    @include gridle_state(mobile) {
      @include rem(
        (
          padding: 7px,
          font-size: 14px,
          line-height: 16px,
        )
      );
    }
  }

  .btn {
    @include rem(
      (
        padding: 7px 20px,
        font-size: 14px,
        line-height: 16px,
      )
    );
  }

  .featured-table__spacer {
    @include rem(
      (
        padding: 6px,
      )
    );
  }
}

.featured-table__table {
  width: 100%;
  background-color: $brand-white;
}

.tablesorter-header {
  cursor: pointer;
}

table.table-sort {
  thead {
    tr {
      .sort-header {
        cursor: pointer;

        &:last-child {
          background: none;
        }

        &:focus {
          outline: none;
        }

        &.is-active {
          .svg-icon {
            background-image: url('/SiteFiles/src/ui/img/svg/chev-up.svg');
          }
        }

        .svg-icon {
          @include rem(
            (
              width: 20px,
              height: 20px,
              margin-left: 10px,
              margin-top: -5px,
            )
          );

          background-image: url('/SiteFiles/src/ui/img/svg/chev-down.svg');
          background-repeat: no-repeat;
          background-position: center right;
          background-size: 20px;
          display: inline-block;
        }
      }
    }
  }
}

.featured-table__icons {
  max-width: 140px;

  @include gridle_state(desktop-small) {
    min-width: 130px;
  }
}
