/**
* Add grid layouts in this partial.
*
*/

.spacer {
  padding: 10px;
  background-color: lightblue;
  margin-bottom: 20px;
}

.site-wide {
  @include rem(max-width, $site-width);
  margin: 0 auto;
  padding-left: $site-wide-padding;
  padding-right: $site-wide-padding;
  overflow: visible;

  @include gridle_state(tablet-min) {
    padding-left: $site-wide-padding;
    padding-right: $site-wide-padding;
  }
}

.grid-container {
  @include gridle(row);

  &.grid-container--flexed-base {
    flex: 1 0 auto;
  }

  .blog & {
    & .grid-column {
      padding: 0px rem(14px);
      flex-basis: 100%;
      max-width: 100%;

      @include gridle_state(small-desktop) {
        &--full {
          flex-basis: 100%;
          max-width: 100%;
        }

        &--half {
          flex-basis: 50%;
          max-width: 50%;
        }

        &--quarter {
          flex-basis: 25%;
          max-width: 25%;
        }
      }
    }
  }
}

.grid-container--bottom {
  @include gridle(row);
  @include gridle_row_align(bottom);
}

.grid-container--no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.grid-container--full,
.grid-container--12 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;
  }
}

.grid-container--half,
.grid-container--6-6 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet desktop) {
      @include gridle_grid(6);
    }
  }
}

.grid-container--6-6-desktop {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(desktop) {
      @include gridle_grid(6);
    }
  }
}

.grid-container--gutter-large {
  @include gridle_state(small-desktop) {
    @include rem('margin-left', -50px);
    @include rem('margin-right', -50px);
  }

  > .grid-column {
    @include gridle_state(small-desktop) {
      @include gridle_gutter(100px);
    }
  }
}

.grid-container--gutter-xlarge {
  @include rem('margin-left', -100px);
  @include rem('margin-right', -100px);
  overflow: hidden;

  > .grid-column {
    @include gridle_gutter(200px);
  }
}

.grid-container--center-border {
  .grid-column:first-child {
    @include gridle_state(tablet desktop) {
      border-right: 1px solid $brand-border;
    }
  }
}

.grid-container--one-third,
.grid-container--4-4-4 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet desktop) {
      @include gridle_grid(4);
      display: flex;
    }

    .advanced-search & {
      @include gridle_state(small-desktop) {
        padding-left: $site-wide-padding + 20;
        padding-right: $site-wide-padding + 20;
      }
    }
  }
}

.grid-container--one-quarter,
.grid-container--3-3-3 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet-min) {
      @include gridle_grid(6);
    }

    @include gridle_state(small-desktop) {
      @include gridle_grid(3);
    }
  }
}

.grid-container--one-sixth,
.grid-container--2-2-2-2-2-2 {
  > .grid-column {
    @include gridle_grid(6);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet desktop) {
      @include gridle_grid(2);
    }
  }
}

.grid-container--two-third-left,
.grid-container--8-4 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet-min) {
      @include gridle_grid(4);
    }

    &:first-child {
      @include gridle_state(tablet-min) {
        @include gridle_grid(8);
      }
    }
  }
}

.grid-container--two-third-right,
.grid-container--4-8 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet desktop) {
      @include gridle_grid(4);
    }

    &:last-child {
      @include gridle_state(tablet desktop) {
        @include gridle_grid(8);
      }
    }
  }
}

.grid-container--three-quarter-right,
.grid-container--3-9 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet) {
      @include gridle_grid(12);
    }

    @include gridle_state(desktop) {
      @include gridle_grid(3);
    }

    &:last-child {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(9);
      }
    }
  }
}

.grid-container--three-quarter-left,
.grid-container--9-3 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet) {
      @include gridle_grid(12);
    }

    @include gridle_state(desktop) {
      @include gridle_grid(3);
    }

    &:first-child {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(9);
      }
    }
  }
}

.grid-container--flexed-base {
  flex: 1 0 auto;

  > .grid-column {
    @include gridle_state(tablet-min) {
      @include rem(
        (
          margin-bottom: 30px,
        )
      );

      display: flex;
    }
  }
}

.grid-column {
  &--no-gutter-left {
    @include gridle_state(tablet desktop) {
      padding-left: 0 !important;
    }
  }

  &--no-gutter-right {
    @include gridle_state(tablet desktop) {
      padding-right: 0 !important;
    }
  }
}

.gm-style-iw {
  .grid-column {
    &--no-gutter-left {
      @include gridle_state(mobile) {
        padding: 2px !important;
      }
    }

    &--no-gutter-right {
      @include gridle_state(mobile) {
        padding: 2px !important;
      }
    }
  }
}

.grid-container--re-sale {
  position: fixed;
  top: 0;
  left: 15px;
  width: calc(100% - 30px);
  height: 100vh;
  margin-left: 0;
  margin-right: 0;
  z-index: 0;

  .grid-column {
    flex: 1 0 100%;
    max-width: 100%;

    @include gridle_state(small-desktop) {
      flex: 1 0 60%;
      max-width: 60%;
    }

    &:last-child {
      @include gridle_state(small-desktop) {
        background-color: $brand-form-ele-bg;
        flex: 1 0 40%;
        max-width: 40%;
      }
    }
  }
}

/*
Breaks location map
.grid-container .grid-column div {
    overflow: hidden; // IE
}
*/

// // Add support for gutter
// @mixin grid-gutters($gutter-spacing) {
// 	//.grid--gutter-#{strip-unit($gutter-spacing)} {
// 		// Apply this to row
// 		@include rem('margin-left', -$gutter-spacing);
//
// 		.grid-item {
// 			@include rem('padding-left', $gutter-spacing);
// 		}
// 	//}
// }
//
// @each $gutter in $grid-gutters-list {
// 	@include grid-gutters($gutter);
// }

.grid-container--reverse {
  flex-direction: row-reverse;
}

.grid-container--7-5 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(tablet) {
      @include gridle_grid(12);
    }

    @include gridle_state(desktop) {
      @include gridle_grid(5);
    }

    &:first-child {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(7);
      }
    }
  }
}

.grid-container--7-3 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    &:first-child,
    &:last-child {
      @include gridle_grid(1);
    }

    &:nth-child(2) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(7);
      }
    }

    &:nth-child(3) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(3);
      }
    }
  }
}

.grid-container--5-6 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    &:first-child {
      @include gridle_grid(1);
    }

    &:nth-child(2) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(5);
      }
    }

    &:nth-child(3) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(6);
      }
    }
  }
}

.grid-container--5-6-alt {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    &:first-child {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(5);
      }
    }

    &:nth-child(2) {
      @include gridle_grid(1);
    }

    &:nth-child(3) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(6);
      }
    }
  }
}

.grid-container--10 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    &:first-child,
    &:last-child {
      @include gridle_grid(1);
    }

    &:nth-child(2) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(10);
      }
    }
  }
}

.grid-container--2-8-2 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    @include gridle_state(desktop) {
      @include gridle_grid(8);
    }

    &:first-child,
    &:last-child {
      @include gridle_state(desktop) {
        @include gridle_grid(2);
      }
    }
  }
}

.grid-container--4-6 {
  > .grid-column {
    @include gridle_grid(12);
    margin-left: 0;
    margin-right: 0;

    &:first-child,
    &:last-child {
      @include gridle_grid(1);
    }

    &:nth-child(2) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(4);
      }
    }

    &:nth-child(3) {
      @include gridle_state(tablet) {
        @include gridle_grid(12);
      }

      @include gridle_state(desktop) {
        @include gridle_grid(6);
      }
    }
  }
}

// Footer grid layout
.grid-container--3-2-2-2-2 {
  > .grid-column {
    @include gridle_grid(6);
    margin-left: 0;
    margin-right: 0;

    &:first-child {
      @include gridle_grid(12);
      order: 6;

      @include gridle_state(small-desktop) {
        order: 0;
        @include gridle_grid(3);
      }
    }

    @include gridle_state(small-desktop) {
      @include gridle_grid(2);
    }
  }
}