.afford-calc {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include gridle_state(mobile tablet) {
    grid-template-columns: 1fr;
  }

  .afford-calc__left {
    grid-column: 1;
    padding: 32px 45px 64px 32px;
    background-color: $development-light-grey;

    @include gridle_state(mobile tablet) {
      padding: 22px 16px;
    }
  }

  .afford-calc__title {
    font-family: $brand-font;
    text-decoration: none;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 16px;

    @include gridle_state(mobile tablet) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }

  .afford-calc__sub-title {
    font-family: $base-font-family-med;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
    color: $brand-black;

    @include gridle_state(mobile tablet) {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 4px;
    }
  }

  .afford-calc__right {
    grid-column: 2;
    background-color: $development-cyan;
    padding: 90px 40px 40px;


    @include gridle_state(mobile tablet) {
      grid-column: 1;
      grid-row: 2;
      padding: 16px 16px 32px;
    }

    .afford-calc__right-title {
      font-family: $base-font-family-med;
      color: $brand-white;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 8px;

      @include gridle_state(mobile tablet) {
        font-size: 16px;
      }
    }

    .afford-calc__right-intro p {
      color: $brand-white;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 32px;

      @include gridle_state(mobile tablet) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .afford-calc__right-base-text p {
      color: $brand-white;
      font-size: 16px;
      line-height: 24px;

      @include gridle_state(mobile tablet) {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }

  .form {
    label {
      font-family: $base-font-family-med;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .slider-group {
    margin-bottom: 12px;

    label {
      font-family: $base-font-family-med;
      font-size: 16px;
      line-height: 26px;
      color: $brand-black;
      text-transform: capitalize;
      padding-bottom: 0;
      opacity: 1;

      @include gridle_state(mobile tablet) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .label-info {
      font-family: $base-font-family;
      font-size: 14px;
      line-height: 24px;
      color: $brand-black;
      margin-bottom: 0;

      @include gridle_state(mobile tablet) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .slider-group__group {
      display: flex;
      align-items: center;
      gap: 24px;

      @include gridle_state(mobile tablet) {
        gap: 16px;
      }
    }

    .slider-group__slider {
      flex: 1 0 auto;

      .irs.irs--flat {

        @include gridle_state(mobile tablet) {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 16px;
        }

        .irs {

          @include gridle_state(mobile tablet) {
            margin-left: 0;
            margin-right: 0;
          }

          .irs-line {
            background-color: $brand-white;
            height: 8px;

            @include gridle_state(mobile tablet) {
              height: 4px;
            }
          }
        }

        .irs-bar {
          background-color: transparent;
        }

        .irs-handle {
          background-color: $development-cyan;     
          width: 18px;   
          height: 18px;
          top: 25px;

          @include gridle_state(mobile tablet) {
            width: 16px;   
            height: 16px;
            top: 24px;
          }
        }
      }
    }

    .slider-group__inputs {
      flex: 0 0 100px;

      @include gridle_state(mobile tablet) {
        flex: 0 0 89px;
      }

      .slider-group__col {
        display: flex;
        flex: 0 0 auto;
        width: 100%;

        @include gridle_state(mobile tablet) {
          padding-top: 8px;
        }

        .slider-input {
          font-family: $base-font-family;
          font-size: 16px;
          line-height: 20px;
          color: $brand-black;
          border: 1px solid $development-cyan;
          height: 40px;
          padding: 10px 8px;
          margin-bottom: 0;

          @include gridle_state(mobile tablet) {
            font-size: 14px;
            line-height: 16px;
            height: 32px;
            padding: 8px;
          }
        }
      }
    }
    
  }

  .btn--primary {
    background-color: $brand-dark-grey;
    font-family: $brand-font;
  }

  .afford-calc__intro {
    font-size: 20px;
    line-height: 36px;
  
    @include gridle_state(mobile tablet) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}





.afford-calc__table {

}

.input-value-reference {
  background-color: $development-cyan;
  font-family: $base-font-family;
  font-size: 16px;
  line-height: 20px;
  color: $brand-white;
  padding: 10px 6px;
  height: 40px;

  @include gridle_state(mobile tablet) {
    font-size: 14px;
    line-height: 16px;
    height: 32px;
    padding: 8px 5px;
  }
}

.afford-calc__table-row {
  display: flex;
  justify-content: space-between;
  background-color: $brand-white;
  padding: 20px 24px;

  @include gridle_state(mobile tablet) {
    padding: 11px;
  }

  &:nth-child(even) {
    background-color: $development-light-grey;
  }

  span {
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 26px;
    color: $brand-black;

    @include gridle_state(mobile tablet) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &--stand {
    span,
    .afford-calc__price {
      font-family: $base-font-family-med;
    }
  }
}

.afford-calc__results {
  margin: 20px 0 30px 0;

  .afford-calc__price {
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 26px;
    color: $brand-black;
    display: block;

    @include gridle_state(mobile tablet) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .afford-calc__table-row--stand {
    .afford-calc__price {
      font-family: $base-font-family-med;
    }
  }
}

.afford-calc__small-text {
  font-size: 12px;
  line-height: 16px;
}