.price-list {
  background-color: $development-light-grey;
  // background-color: rgba($brand-text-heading, 0.1);
}

.price-list__filters {
  display: flex;
  margin-bottom: 40px;
}

.price-list__filters-col {
  flex: 1 0 50%;
  align-self: center;

  &:last-child {
    text-align: right;
  }
}

.price-list__filters-sort {
  span {
    vertical-align: middle;
    font-family: $brand-font;
    font-size: 22px;
    line-height: 36px;

    @include gridle_state(mobile) {
      font-size: 16px;
    }
  }

  select {
    width: auto;
    font-size: 22px;
    line-height: 36px;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    background-color: transparent;
    border: none;
    padding: 5px;

    @include gridle_state(mobile) {
      font-size: 16px;
    }
  }
}

.price-list__filters-btn {
  background-color: transparent;
  border: none;

  svg {
    vertical-align: middle;
  }
}

.price-list__filters-btn-txt {
  font-family: $brand-font;
  text-decoration: underline;
  font-size: 22px;
  line-height: 36px;
  vertical-align: middle;
  padding-right: 10px;

  @include gridle_state(mobile) {
    display: none;
  }
}

.price-list__table {
  width: 100%;
  margin-bottom: 40px;

  .btn {
    width: 100%;
    border: none;
    background-color: $brand-primary;
    color: $brand-white;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    padding: 16px 5px;
    cursor: pointer;

    @include gridle_state(tablet mobile) {
      width: auto;
      padding: 10px 15px;
    }

    &:hover {
      background-color: $brand-primary;
    }
  }

  .price-list__link--coming-soon {
    .btn {
      background-color: $blog-orange;

      &:hover {
        background-color: $blog-orange;
      }
    }
  }

  .price-list__link--sold {
    .btn {
      background-color: $brand-button-border;

      &:hover {
        background-color: $brand-button-border;
      }
    }
  }
}

.price-list__table-head {
  display: flex;
  margin-bottom: 10px;
  text-align: left;

  @include gridle_state(tablet mobile) {
    display: none;
  }
}

.price-list__link {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.price-list__table-row {
  display: flex;
  background-color: $brand-white;
  margin-bottom: 20px;

  @include gridle_state(tablet mobile) {
    flex-wrap: wrap;
    padding: 10px;
  }

  .price-list__link--sold & {
    background-color: $brand-grey;
  }

  .price-list__table-cell {
    &:first-child {
      .price-list__table-cell-inner {
        padding-left: 30px;

        @include gridle_state(tablet mobile) {
          padding-left: 10px;
        }
      }
    }

    &:last-child {
      .price-list__table-cell-inner {
        width: 100%;
        padding-right: 15px;

        @include gridle_state(tablet mobile) {
          padding-right: 10px;
        }
      }
    }
  }

  .price-list__cell-title {
    display: none;

    @include gridle_state(tablet mobile) {
      display: block;
    }
  }
}

.price-list__table-cell {
  flex: 0 0 13.57%;
  display: flex;
  align-items: center;

  @include gridle_state(tablet mobile) {
    flex: 0 0 50%;
    align-items: flex-start;
  }

  &--last {
    text-align: center;
    text-decoration: underline;
    flex: 1 0 100%;
    font-size: 16px;
    font-family: $brand-font;
    display: none;

    @include gridle_state(tablet mobile) {
      display: flex;
      justify-content: center;
      order: 10;
    }
  }

  &.price-list__table-cell-small {
    flex: 0 0 5%;

    @include gridle_state(tablet mobile) {
      flex: 0 0 50%;
    }
  }

  .price-list__cell-title {
    font-size: 14px;
    line-height: 18px;
    color: $brand-text;
    padding-bottom: 5px;
    display: none;

    @include gridle_state(tablet mobile) {
      display: block;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    padding-left: 0;
    font-family: $base-font-family-med;
    font-size: 15px;
    line-height: 19px;

    &::before {
      content: none;
    }
  }
}

.price-list__table-cell-inner {
  padding: 10px;
  font-family: $base-font-family-med;
  font-size: 15px;
  line-height: 19px;

  .price-list__cell-title & {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: $brand-text;
    padding-bottom: 5px;
  }
}

.price-list__features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  svg {
    width: 21px;
    height: 21px;
    color: $brand-white;
  }
}