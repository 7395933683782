// configuration
$netc-core-alert-namespace:  'alert' !default;
$netc-core-alert-padding:  15px 10px !default;
$netc-core-alert-margin:  15px !default;

// default styles
.#{$netc-core-alert-namespace} {
  @include rem(margin-bottom, $netc-core-alert-margin);
	padding: $netc-core-alert-padding;
	width: 100%;

  p {
    @include rem(line-height, 20px);
    margin-bottom: 0;
  }
}

// set alert names
$netc-alerts: (
	success-message: #00a752,
	#{$netc-core-alert-namespace}--info: #007afe,
	error-message: #ff2400
) !default;

// generate alert classes
@each $key, $value in $netc-alerts {
  // Making sure $value is a color
  @if type-of($value) != color {
    @warn "`#{$value}` is not a color.";
    $everything-okay: false;
  }

  @else {
    .#{$key} {
      border: 1px solid $value;
      background-color: lighten($value, 40%);
      color: darken($value, 20%);
    }
  }
}
