.footer {
  @include rem(
    (
      padding: 35px 0 60px,
    )
  );

  background-color: $brand-light-grey;
  font-family: $base-font-family;
  margin-top: auto;
  width: 100%;
  position: relative;

  @include gridle_state(small-desktop) {
    @include rem(
      (
        padding: 50px 0 40px,
      )
    );
  }
}

.footer__content {
  @include rem(
    (
      font-size: 12px,
      line-height: 18px,
    )
  );

  color: $brand-medium-grey;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        font-size: 14px,
      )
    );
  }

  ul {
    @include rem(
      (
        margin: 0 0 20px,
      )
    );
    list-style: none;
    padding: 0;

    @include gridle_state(tablet-min) {
      @include rem(
      (
        margin: 0 0 10px,
      )
    );
    }
  }

  li {
    @include rem(
      (
        padding: 0 0 5px,
        font-size: 12px,
        line-height: 18px,
      )
    );

    @include gridle_state(tablet-min) {
      @include rem(
        (
          font-size: 14px,
        )
      );
    }


    &:before {
      content: none;
    }

    a {
      @include rem(
        (
          font-size: 12px,
          line-height: 18px,
        )
      );

      font-family: $base-font-family;

      @include gridle_state(tablet-min) {
        @include rem(
          (
            font-size: 14px,
          )
        );
      }
    }
  }

  a {
    color: $brand-medium-grey;
  }

  .stacked-list {
    @include rem(
      (
        margin-top: 10px,
      )
    );

    li {
      @include rem(
        (
          margin-bottom: 10px,
        )
      );

      display: block;
    }
  }

  &.footer__content-landing {
    max-width: 1000px;
    margin: 0 auto;

    ul {
      justify-content: center;
      display: block;

      li {
        padding: 5px 15px;
        display: block;
      }
    }
  }
}

.footer__title {
  font-family: $brand-font;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $brand-primary;
  margin-bottom: 5px;

  @include gridle_state(tablet-min) {    
    font-size: 16px;
  }
  
}

.footer__social-area {
  padding-top: 5px;

  li {
    display: inline-block;
    padding-right: 5px;
  }
}

.footer__social-link {
  &:hover,
  &:focus {
    svg {
      fill: $brand-primary;
    }
  }

  svg {
    transition: fill 0.3s;
    fill: $brand-black;
  }
}

/////////////////////////// Footer search area

.footer__search-area {
  position: relative;
  max-width: 295px;
}

input[type="search"].footer__search-input {
  font-size: 16px;
  line-height: 28px;
  height: 46px;
  padding-right: 50px;
}

.footer__search-btn {
  display: inline-flex;
  align-items: center;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  height: 46px;
  padding: 0 15px;
  z-index: 1;

  &:hover,
  &:focus {
    svg {
      fill: $brand-primary;
    }
  }

  svg {
    transition: fill 0.3s;
    fill: $brand-black;
    width: 18px;
    height: 18px;
  }
}

//////////////////////////////////////////////////////////////////////////////// FOOTER LINKS IN MOBILE MENU STYLES
.is-open {
  .footer__content {
    @include rem(
      (
        padding: 5px 20px,
      )
    );

    color: $brand-white;

    &:first-child {
      @include rem(
        (
          margin-top: 40px,
        )
      );
    }

    a {
      color: $brand-white;
    }
  }
}
