/**
 * Add global styles in this file.
 */

html {
  color: $brand-text;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  font-family: $base-font-family;
  scroll-behavior: smooth;

  &.is-sticky {
    height: 100vh;
    overflow: hidden;
  }
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  // DISABLED TO MAKE TOOLTIPS POSITION CORRECTLY
  // height: 100% !important;

  @media screen and (min-width: 768px) {
    @include rem(
      (
        padding: 0 15px,
      )
    );
  }

  &.menu-open {
    @media screen and (max-width: 991px) {
      overflow: hidden;
    }
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.viewport-wide {
  overflow: hidden;

  &.sticky-map {
    overflow: unset;
  }
}

p {
  font-family: $base-font-family;
}

// @note: override margin-top from Titon and add a margin-bottom
ul,
ol,
p {
  @include rem(margin, 0 0 16px 0);
}

ul,
ol {
  @include rem(
    (
      margin-top: 16px,
    )
  );
}

ul {
  padding: 0;

  li {
    @include rem(
      (
        padding-left: 20px,
      )
    );

    list-style: none;
    position: relative;

    &:before {
      content: '-';
      position: absolute;
      left: 0;
    }
  }
}

ol {
  padding-left: rem(15px);
  margin-bottom: rem(20px);
}

hr {
  border: 0;
  border-top: 1px solid $brand-hr;

  @media screen and (min-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.page-content {
  // @include netc-transition();
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // @include gridle_state ( tablet-min ) {
  // 	@include rem((
  //     padding-top: 195px,
  //   ));
  // }
  //
  // @include gridle_state ( small-desktop ) {
  // 	@include rem((
  //     padding-top: 181px,
  //   ));
  // }

  // &.hide-search {
  @include rem(
    (
      padding-top: 66px,
    )
  );

  @media screen and (min-width: 768px) {
    @include rem(
      (
        padding-top: 92px,
      )
    );
  }

  @media screen and (min-width: 992px) {
    @include rem(
      (
        padding-top: 102px,
      )
    );
  }
  // }
}

.development {
  .page-content.page-content {
    @include rem(
      (
        padding-top: 111px,
      )
    );

    @media screen and (min-width: 768px) {
      @include rem(
        (
          padding-top: 180px,
        )
      );
    }

    @media screen and (min-width: 992px) {
      @include rem(
        (
          padding-top: 190px,
        )
      );
    }

    @media screen and (min-width: 1200px) {
      @include rem(
        (
          padding-top: 202px,
        )
      );
    }
  }
}

.page-content__section {
  @include rem(
    (
      margin-top: 20px,
    )
  );

  @media screen and (min-width: 992px) {
    @include rem(
      (
        margin-top: 30px,
      )
    );
  }
}

.property-totals {
  font-size: rem(12px);
  span {
    color: $brand-primary;
    font-family: $base-font-family-med;
  }
}

.umb-grid {
  @include rem(
    (
      margin: 30px 0 0,
    )
  );

  img.full-width {
    width: 100%;
  }
}

.umb-grid-row {
  padding-top: 20px;

  &.bg-grey {
    background-color: #f2f2f2;
  }
}

.sales-message {
  @include rem(
    (
      font-size: 20px,
    )
  );

  color: $brand-text;
  font-weight: 400;

  .featured-card__flex-layout & {
    color: $brand-primary;
    padding-left: rem(50px);
  }
}

.left-aligned-area {
  text-align: left;
}

.right-aligned-area {
  @media screen and (min-width: 768px) {
    // @include rem((
    //   padding-right: 50px,
    // ));

    text-align: right;
  }
}

.centered-area {
  text-align: center;
}

.video-wrapper iframe {
  width: 100% !important;
}

.micro-only {
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.development {
  .micro-only {
    display: inline-block;
  }

  .header__menu-holder {
    .micro-only {
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
  }
}

.mobile-only {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.tablet-up {
  display: none;

  @media screen and (min-width: 768px) {
    display: table-cell;
  }
}

.desktop-up {
  display: none;

  @media screen and (min-width: 992px) {
    display: table-cell;
  }
}

.key-focus {
  &:focus {
    outline: none;
  }
}

.key-focus.is-focused {
  &:focus {
    outline: $brand-primary-dark 2px solid;
  }
}

.upcoming-overlay {
  @include rem(
    (
      padding: 10px 8px,
    )
  );
  display: inline-block;
  text-transform: uppercase;
  background-color: $brand-medium-grey;
  color: $brand-white;
  position: absolute;
  font-family: $brand-font;

  &:before {
    content: none;
  }

  &:last-child {
    padding-right: 0;
  }

  &.is-active {
    .icon {
      fill: $brand-light-grey;
    }
  }

  .icon {
    @include rem(
      (
        height: 16px,
        width: 16px,
        margin-left: 8px,
        margin-top: -3px,
      )
    );

    fill: $brand-white;
    vertical-align: middle;
  }
}

.rel-cont {
  position: relative;
}

.false-width {
  @media screen and (min-width: 992px) {
    width: 450px;
  }
}

.disclaimer-text {
  margin-top: 25px;
  p {
    @include rem(
      (
        font-size: 14px,
        line-height: 16px,
      )
    );
    font-style: italic;
  }
}

.link-less {
  display: block;
  & span {
    display: none;
    color: $brand-black;
  }
}

.title__green {
  color: $brand-primary;
}

.padded-title {
  padding-top: rem(30px);
  padding-bottom: rem(110px);
}

.addthis_inline_share_toolbox {
  margin-bottom: rem(35px);
}

.border-block__orange {
  border-bottom: 10px solid $blog-orange;
}
.border-block__blue {
  border-bottom: 10px solid $blog-blue;
}
.border-block__red {
  border-bottom: 10px solid $blog-red;
}
.border-block__brown {
  border-bottom: 10px solid $blog-brown;
}
.border-block__purple {
  border-bottom: 10px solid $blog-purple;
}
.border-block__green {
  border-bottom: 10px solid $blog-green;
}
.border-block__primary {
  border-bottom: 10px solid $brand-primary;
}

.blog-bg {
  &--grid {
    padding: rem(40px);
    @media screen and (max-width: 767px) {
      padding: rem(2.5rem 0 2.5rem 0);
    }
  }

  div.grid-container {
    margin-left: 0;
    margin-right: 0;
  }
}

.blog-tag__holder {
  display: block;
  margin-bottom: rem(20px);

  @media screen and (min-width: 768px) {
    display: inline-block;
    margin-left: rem(15px);
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.blog-tag__content {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-left: rem(10px);
  font-family: $brand-font;
  font-size: rem(16px);
  cursor: pointer;

  .blog-orange & {
    color: $blog-orange;
  }

  .blog-blue & {
    color: $blog-blue;
  }

  .blog-red & {
    color: $blog-red;
  }

  .blog-brown & {
    color: $blog-brown;
  }

  .blog-purple & {
    color: $blog-purple;
  }

  .blog-green & {
    color: $blog-green;
  }

  .blog-primary & {
    color: $brand-primary;
  }

  &:hover {
    text-decoration: none;
    color: $brand-text;
  }
}

.grid-column.blog-detail {
  background-color: $brand-white;

  div.media__holder {
    margin-bottom: rem(16px);
  }

  h2,
  h3 {
    margin-bottom: rem(30px);
  }

  h1 {
    margin-bottom: rem(40px);

    @media screen and (min-width: 768px) {
      margin-left: rem(15px);
    }
  }

  ul li:before {
    content: '•';
  }
}

.blog-related-articles {
  margin: rem(0px 36px);
  margin-top: rem(96px);

  @media screen and (max-width: 767px) {
    margin: 0;
    margin-bottom: rem(48px);
  }

  h1 {
    color: $brand-dark-grey;
    text-align: center;
    margin-bottom: rem(32px);
  }
}

.blog-detail {
  blockquote {
    border: none;
    padding: 0;
    margin: 0;
    padding-left: rem(40px);
    padding-right: rem(40px);
    position: relative;

    &::before {
      font-family: auto;
      content: '“';
      color: $brand-primary;
      padding-right: rem(16px);
      font-size: rem(52px);
      position: absolute;
      top: rem(10px);
      left: 0;
    }

    &::after {
      font-family: auto;
      content: '”';
      color: $brand-primary;
      padding-left: rem(16px);
      font-size: rem(52px);
      position: absolute;
      bottom: rem(-8px);
      right: 0;
    }
  }
}

.blog-filters {
  padding: 0;
  background-color: $brand-white;

  &.is-active {
    padding: rem(32px 20px);
    background-color: $brand-light-grey;
  }

  transition: background-color 0.5s ease-in-out, padding 0.5s ease-in-out;

  h2 {
    text-align: center;
    color: $brand-dark-grey;
  }

  .filter-area {
    margin: auto;
    display: flex;

    button {
      text-transform: none;
      font-size: rem(21px);
      background: none;
      color: $brand-primary;
      display: flex;
      justify-content: center;
      align-items: center;

      &.btn--iconed-chev {
        font-weight: 500;
        letter-spacing: rem(1px);
        padding-left: rem(35px);
        padding-right: rem(35px);
        margin-left: rem(26px);
      }

      .chev-holder {
        position: relative;
        transform: rotate(180deg);
        transform-origin: unset;
        top: 0;
        margin-left: rem(32px);
        svg.icon {
          transform: none;
          position: relative;
          fill: $brand-primary;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &--dropdown {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &--filter {
    background-color: $brand-white;
    border: 1px solid $brand-dark-grey;
    padding: rem(11px 27px);
    margin-right: rem(16px);
    margin-bottom: rem(16px);
    .blog-filters--selected & {
      margin-bottom: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-right: 0px;
      margin-bottom: rem(10px);
      width: 90%;
    }

    span {
      text-align: center;
      color: $brand-dark-grey;
      font-weight: 600;
    }

    div.blog-filters--close {
      color: $brand-white;
      padding-left: rem(10px);
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;
      background-color: $brand-primary;
      span {
        color: $brand-white;
      }
    }

    &.selected {
      span {
        color: $brand-white;
      }
      background-color: $brand-primary;
      border-color: $brand-primary;

      @media screen and (max-width: 767px) {
        margin-bottom: rem(8px);
      }
    }

    // transition: all 0.1s ease-in-out;
  }

  &--selected {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: rem(15px);
    h2 {
      display: flex;
      padding-right: rem(30px);
      margin-bottom: 0;
      align-self: center;
      font-size: rem(20px);
      @media screen and (max-width: 767px) {
        padding-bottom: rem(16px);
      }
    }
  }
}

.blog-social-media {
  margin-top: 16px;

  @media screen and (max-width: 767px) {
    margin-bottom: rem(80px);
  }

  h4 {
    color: $brand-primary;
    text-transform: uppercase;
    margin-bottom: rem(16px);

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &__icons {
    display: flex;

    a {
      &.svg-icon--blog {
        &-whatsapp {
          display: none;
        }
      }

      @media screen and (max-width: 767px) {
        width: rem(48px);
        height: rem(48px);
        background-size: contain;
        margin: 0px rem(16px);

        &.svg-icon--blog {
          &-whatsapp {
            display: block;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }
}

.landing-intro__content {
  margin: auto;
  text-align: center;
  max-width: rem(720px);
  padding: rem(30px 0);
  padding-bottom: 0;

  p {
    font-size: rem(16px);
    line-height: rem(27px);
  }
}

.landing-intro__search {
  padding: rem(30px 0);
}

.landing-intro__title {
  color: $brand-dark-grey;
  display: block;
  position: relative;

  &.border-block {
    padding-bottom: rem(8px);
  }
}

.is-not-fixed {
  &.header {
    @media screen and (min-width: 768px) {
      position: absolute;
      width: auto;
      left: -15px;
      right: -15px;
    }
  }
}

.is-fixed {
  @media screen and (min-width: 768px) {
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 15px;
    right: 15px;
  }
}

.tooltip-element {
  max-width: rem(280px) !important;
}

.lazy-load {
  opacity: 0;
  transition: opacity 0.25s ease;
  will-change: opacity;

  &.loaded {
    opacity: 1;
  }
}

// TOOLTIPS
.tooltip-cell__item {
  width: 16px;
  height: 16px;
  background-color: $brand-black;
  color: $brand-white;
  display: inline-block;
  margin-left: 5px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
}

.loading-icon {
  display: block;
  width: 50px;
  margin: 20px auto;
  height: 50px;
}

.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($brand-dark-grey, 0.1);
  z-index: 2;
  display: none;  

  &.is-active {
    display: block;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}


form {
  *[data-layout="true"] {
    max-width: 100%;
  }

  .emptyContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 30px !important;
  }

  .columnContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .containerWrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;

    p {
      @include rem(
        (
          font-size: 14px,
          line-height: 22px,
        )
      );
    }

    label.lp-ellipsis {
      @include rem(
        (
          font-size: 18px,
        )
      );

      font-family: $base-font-family-med;
      color: $brand-black;
      font-weight: 400;
      padding-bottom: 12px;
      display: block;
    }

    .lp-form-field {
      span {
        display: block;
      }

      .lp-required {
        color: $brand-black !important;
        display: inline-block;
      }

      .lp-form-fieldInput {
        border-color: $brand-black;
      }
    }

    input.lp-form-fieldInput {
      @include rem(
        (
          margin-bottom: 22px,
          height: 40px,
          padding: 6px 12px,
        )
      );
      font-family: $base-font-family;
      background-color: $brand-white;

      &:focus {
        outline: 2px solid $brand-primary;
      }
    }
  }

  .lp-form-button {
    font-family: $base-font-family-med;
    background-color: $development-cyan;
    font-size: 16px;
    line-height: 21px;
    color: $brand-white;
    border: none;
    padding: 9px 20px;
    text-transform: uppercase;
    transition: background-color 0.3s;
    width: 100%;

    &:hover {
      background-color: $brand-text;
    }
  }
}