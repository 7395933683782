.main-content {
  position: relative;

  .main-content__holder {
    @include gridle_state(small-desktop) {
      width: 60%;
      padding-right: 100px;
    }

    h2 {
      font-family: $base-font-family;
      font-size: 32px;
      line-height: 40px;
    }

    p {
      a {
        font-family: $brand-font;
      }
    }

    .centered-form.centered-form--dev {
      width: 100vw;
      margin-left: -2vw;
      padding-right: 20px;
      max-width: 100rem;
      background-color: $brand-primary;
    }
  }
}

.development__top-section {
  background-color: $brand-form-ele-bg;
  position: relative;
  z-index: 3;
}

.development__top-section-inner {
  display: flex;
  flex-wrap: wrap;

  @include gridle_state(small-desktop) {
    flex-wrap: nowrap;
  }
}

.development__carousel-holder {
  flex: 1 0 100%;
  max-width: 100%;

  @include gridle_state(small-desktop) {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

.development__carousel-holder-inner {
  position: relative;

  .tns-controls {
    button {
      background-color: $brand-primary;
      border: none;
      width: 40px;
      height: 40px;
      transition: background-color 0.3s;

      @include gridle_state(tablet-min) {
        width: 50px;
        height: 50px;
      }

      &:hover {
        background-color: rgba($brand-primary, 0.8);
      }

      &:first-child {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }

      &:last-child {
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 1;

        @include gridle_state(tablet-min) {
          top: 50px;
        }
      }

      svg {
        fill: $brand-white;
        width: 11px;
        height: 20px;
      }
    }
  }
}

.development__info-holder {
  flex: 1 0 100%;

  @include gridle_state(small-desktop) {
    flex: 1 0 40%;
  }

  .split-tone-bg & {
    display: none;

    @include gridle_state(small-desktop) {
      display: block;
      margin-top: 100px;
    }
  }
}

.development__info-holder-inner {
  @include gridle_state(small-desktop) {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
  }

  @include rem(
    (
      max-width: 480px,
      padding: 70px 40px,
    )
  );

  @include gridle_state(mobile) {
    @include rem(
      (
        padding: 30px 40px,
      )
    );
  }

  .info-panel {
    max-width: 100%;
  }

  .reg-int-btn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: auto;
    white-space: nowrap;

    @include rem(
      (
        bottom: 20px,
      )
    );

    @include gridle_state(small-desktop) {
      position: relative;
      left: auto;
      bottom: auto;
      transform: translateX(0);
      margin-top: auto;
    }

    .icon {
      fill: $brand-white;
    }
  }
}

.development__info-top {
  flex: 1 0 auto;
  width: 100%;
}

.development__info-tag {
  color: $brand-primary;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
}

.development__info-title {
  font-size: 32px;
  line-height: 40px;
  color: $brand-text-heading;
  font-family: $base-font-family-med;
}

.development__top-section-base {
  width: 100%;
  background-color: $brand-primary;
  position: relative;
  margin-bottom: 70px;

  @include rem(
    (
      height: 60px,
    )
  );
}

.development__scroller-holder {
  width: 100%;
  background-color: $brand-primary;
  overflow: hidden;

  @include rem(
    (
      height: 60px,
    )
  );

  .reg-int-btn {
    display: none;
    background-color: $brand-white;
    color: $brand-primary;

    .icon {
      fill: $brand-primary;
    }
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;

    @include gridle_state(mobile) {
      top: 67px;
    }

    .reg-int-btn {
      display: none;

      @include gridle_state(small-desktop) {
        display: inline-block;
      }
    }

    .site-wide {
      @media only screen and (min-width: 1640px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

.scroller-nav {
  overflow-x: auto;
  flex: 1 1 auto;
}

.scroller-nav__holder {
  display: flex;
  align-items: center;

  .reg-int-btn {
    flex: 0 0 auto;
  }
}

.scroller-nav__inner {
  display: flex;
  align-items: center;

  @include rem(
    (
      height: 60px,
    )
  );
}

.scroller-nav__link {
  color: $brand-white;
  background: transparent;
  border: none;
  font-family: $base-font-family-med;
  font-size: 16px;
  line-height: 20px;
  padding: 19px 0 14px;
  max-height: 60px;
  border-bottom: 4px solid $brand-primary;
  flex: 0 0 auto;
  transition: border 0.3s;

  @include gridle_state(small-desktop) {
    font-size: 18px;
    line-height: 23px;
  }

  @include rem(
    (
      margin-right: 30px,
    )
  );

  @include gridle_state(small-tablet) {
    @include rem(
      (
        margin-right: 60px,
      )
    );
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.is-active {
    color: $brand-white;
    border-bottom-color: $brand-white;
    text-decoration: none;
  }
}

.floorplan,
.development__epc {
  margin: 50px 0;

  img {
    margin: 30px 0;
    max-width: 100%;
  }
}

.development__financials {
  margin: 50px 0;
}

.development__financials-inner {
  display: flex;
  flex-wrap: wrap;
}

.development__financials-main {
  flex: 1 0 100%;
  display: flex;
  flex-wrap: wrap;

  @include gridle_state(tablet-min) {
    flex: 0 0 33%;
  }
}

.development__financials-sub {
  flex: 1 0 auto;
  display: flex;
}

.development__financials-sub-left {
  flex: 1 0 50%;
}

.development__financials-sub-right {
  flex: 1 0 50%;
}

.development__financials-item {
  .development__financials-main & {
    flex: 1 0 50%;

    @include gridle_state(tablet-min) {
      flex: 1 0 100%;
    }
  }

  h4 {
    font-family: $base-font-family-med;
    font-size: 14px;
    line-height: 22px;
    color: $brand-primary;
    margin-bottom: 0;

    .development__financials-main & {
      font-size: 18px;
      margin-bottom: 8px;
    }
  }

  p {
    font-family: $base-font-family;
    font-size: 14px;
    line-height: 22px;

    .development__financials-main & {
      font-size: 18px;
    }
  }
}

.development__next-steps {
  margin: 50px 0;
}

.development__steps-btns {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0 50px;
  justify-content: space-between;

  @include gridle_state(tablet-min) {
    justify-content: flex-start;
  }
}

.development__subtext {
  font-family: $base-font-family;
  font-size: 14px;
  line-height: 21px;
}

.development__base-carousel {
  position: relative;
  background-color: $brand-form-ele-bg;
  padding: 50px 0;
  z-index: 2;

  &:after {
    content: '';
    display: block;
    background-color: $brand-form-ele-bg;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 25px;
  }

  .site-wide {
    padding-right: 0;

    @media only screen and (min-width: 1600px) {
      max-width: 95%;
      margin-right: 0;
    }
  }
}

.development__base-carousel-inner {
  text-align: center;
}
