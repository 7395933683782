.typeahead__search-complete-holder {
    position: absolute;
    top: 100%;
    background-color: white;
    left: 0;
    width: 100%;

    @media screen and (max-width: 767px) {
        width: calc(100% - 40px);
        left: 20px;
        top: 51px;
    }
}

.typeahead__search-inner {    
    max-height: 200px;
    overflow-y: auto;
}

.typeahead__search-auto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;    

    a {
        padding: 5px 30px;
        font-size: 16px;
        line-height: 20px;
        display: block;
        text-align: left;

        @media screen and (max-width: 767px) {
            padding: 8px 10px;
        }

        &:last-child {
            padding-bottom: 15px;
        }
    }

    strong {
        color: $brand-primary;
    }
}