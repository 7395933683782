.header__search-bar {
  // @include netc-transition();
  position: relative;
  top: 0;

  &.hide-search {
    top: -200px;
  }
  
  @include gridle_state(mobile) {
    &.hide-search-mobile {
      display: none;
    }
  }
}

.header__search-holder {
  // @include netc-transition();
  @include rem(
    (
      padding: 22px 0,
    )
  );
  background-color: $brand-secondary;
  position: relative;
  z-index: 2;
  top: 0;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        padding: 22px 0 40px,
      )
    );
    text-align: center;
  }

  @include gridle_state(small-desktop) {
    @include rem(
      (
        padding: 22px 0 17px,
      )
    );
    text-align: center;
  }

  p {
    @include rem(
      (
        width: 30px,
      )
    );

    display: inline-block;
    margin: 0;
    vertical-align: middle;
    font-style: italic;

    @include gridle_state(tablet-min) {
      width: auto;
    }
  }

  .header__search-area-block {
    width: 290px;
    margin: 0 auto;
    text-align: left;

    @include gridle_state(mobile-lg) {
      width: 430px;
    }
    @include gridle_state(tablet-min) {
      width: auto;
      margin: 0;
    }

    .btn {
      margin: 0;
      min-height: 40px;

      @include gridle_state(mobile) {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: rem(20px);
      }
    }
  }

  select {
    box-sizing: border-box;
    border-radius: 0;
    border: none;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    max-width: none;
    background-color: $brand-white;
    color: $brand-primary;
  }
  option:hover {
    background-color: $brand-primary;
    color: $brand-white;
  }

  .select2-container,
  select {
    @include rem(
      (
        margin: 0 8px,
      )
    );
    width: 200px !important;

    text-align: left;

    @include gridle_state(mobile-lg) {
      width: 330px !important;
    }

    @include gridle_state(tablet-min) {
      @include rem(
        (
          min-width: 225px,
          max-width: 225px,
        )
      );

      width: 100% !important;
    }

    .select2-selection--single {
      @include rem(
        (
          height: 40px,
        )
      );

      border: none;
    }
  }
}

.header__search-area {
  position: relative;
  text-align: center;
  font-family: $base-font-family-med;

  @include gridle_state(tablet-min) {
    display: flex;
    justify-content: center;
  }
}

.header__search-area-section {
  @include rem(
    (
      margin: 10px 0,
    )
  );

  @include gridle_state(mobile) {
    display: block;
  }

  @include gridle_state(tablet-min) {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin: 0;
  }

  label {
    color: $brand-white;
    @include gridle_state(mobile) {
      display: inline-block;

      @include rem(
        (
          width: 28px,
        )
      );
    }
  }
}

.header__search-advanced-btn-holder {
  width: 100%;
  text-align: center;

  .header__search-advanced-btn {
    color: $brand-text;

    &:hover {
      color: $brand-text;
    }
  }
}

.header__search-advanced-btn {
  @include rem(
    (
      font-size: 14px,
    )
  );

  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  color: $brand-white;

  &:hover {
    color: $brand-white;
  }

  @include gridle_state(tablet-min) {
    @include rem(
      (
        bottom: -32px,
      )
    );
    position: absolute;
    left: 0;
    width: 100%;
  }

  @include gridle_state(small-desktop) {
    @include transform-translate(0, -50%);
    position: absolute;
    top: 50%;
    left: auto;
    right: 0;
    bottom: auto;
    width: auto;
  }
}

.header__search-submit-btn {
  @include rem(
    (
      width: 36px,
      height: 36px,
    )
  );
  border: none;
  background: none;
  display: inline-block;
  vertical-align: middle;
}

// BLOG SEARCH
.search-box__holder {
  position: relative;
}

input[type='text'].search-box__input {
  padding-right: rem(50px);
  border: 2px solid $brand-button-border;
  width: 100%;
  height: rem(40px);

  &:focus {
    outline: none;
    border-color: $brand-primary;
  }
}

.search-box__submit-btn {
  background: url('/SiteFiles/src/ui/img/svg/search-grey.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 23px 24px;
  border: none;
  width: rem(40px);
  height: rem(40px);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
}
