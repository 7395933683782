.modal-content {
  @include rem((
    padding: 30px,
  ));

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: $brand-white;
  z-index: 100;
  display: none;
  flex: 0 0 auto;
  align-items: center;
  text-align: center;

  &.is-active {
    display: flex;
  }

  .form {
    input {
      @include rem((
        max-width: 300px,
      ));

      display: inline-block;
    }
  }
}

.modal-content__content-area {
  width: 90%;
  margin: 0 auto;

  .field-container {
      margin: 0 auto;
  }

  @include gridle_state ( small-desktop ) {
    width: 50%;
  }
}

.modal-content__standard-area {
  @include rem((
    padding: 20px 0,
  ));
}

.modal-content__close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  background: none;
  border: none;

  .icon {
    @include rem((
      width: 26px,
    ));
  }
}
