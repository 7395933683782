
.contact-modal__modal {
    width: 329px;
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s;
    z-index: 101;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    .is-active & {
        transform: translateX(0);
    }
}

.contact-modal__top {
    background-color: $development-cyan;
    padding: 10px 25px 10px 30px;
}

.contact-modal__top-inner {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span {
        font-family: $brand-font;
        color: $brand-white;
        font-size: 16px;
        line-height: 21px;
    }
}

.contact-modal__close-btn {
    background-color: transparent;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 14px;
        height: 14px;
        color: $brand-white;
    }
}

.contact-modal__form-area {
    padding: 10px 30px;
    background-color: $brand-white;
    border: 1px solid $brand-primary;

    .contact-modal__input {
        border-color: $brand-black;
        font-size: 16px;
    }

    .alert.success-message {
        border-color: $brand-primary;
        background-color: $brand-primary-light;
    }

    .btn {
        &:disabled {
            background-color: $brand-dark-grey;
            opacity: 0.2;
        }
    }
}

.contact-modal__label {
    font-family: $base-font-family;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    display: block;
}

.contact-modal__textarea {
    border-color: $brand-black;
    font-size: 16px;
}

.contact-modal__btn {
    background-color: $development-cyan;
    border: none;
    color: $brand-white;
    display: inline-flex;
    align-items: center;
    padding: 10px 30px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;

    @media screen and (max-width: 767px) {
        border-radius: 5000000px;
        bottom: 5px;
        padding: 0;
        width: 48px;
        height: 48px;
        justify-content: center;
        margin-right: 5px;
    }

    span {
        display: inline-block;
        padding-left: 15px;

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

