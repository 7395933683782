.filter-area,
.download-area {
  text-align: left;
  position: relative;
  width: 100%;
  display: inline-block;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        max-width: 262px,
      )
    );
  }

  .advanced-search__submit {
    @include rem(
      (
        padding-top: 20px,
      )
    );
  }

  &--full {
    @include gridle_state(tablet-min) {
      max-width: 100%;

      .btn {
        @include rem(
          (
            max-width: 262px,
          )
        );
      }
    }
  }
}

.filter-area__dropdown,
.download-area__dropdown {
  @include rem(
    (
      padding: 5px 15px 15px,
      margin-top: 3px,
    )
  );
  margin-bottom: 0px;
  background-color: rgba($brand-light-grey, 0.7);
  position: relative;
  display: none;
  text-align: left;
  width: 100%;

  &.form {
    label {
      @include rem(
        (
          padding-top: 10px,
        )
      );

      font-family: $base-font-family-med;
    }

    .select2-container .select2-selection--single {
      border: none;
    }

    input[type='checkbox'] + label:before,
    input[type='checkbox'] + label:after {
      background-color: $brand-white !important;
      border: none;
    }

    .field-container__group {
      width: 49%;

      label {
        @include rem(
          (
            padding-top: 5px,
          )
        );
        position: relative;
      }
    }
  }

  .header__search-advanced-btn {
    @include transform-translate(0, 0);
    @include rem(
      (
        padding-top: 10px,
      )
    );
    position: relative;
    top: auto;
    bottom: 0;
    right: auto;
  }
}

.filter-area__dropdown-restrictor {
  width: 100%;
  margin: 0 auto;

  @include gridle_state(desktop) {
    width: 80%;
  }
}

.filter-area__section-holder {
  text-align: center;

  .select2-container {
    margin: 0;
  }
}

.filter-area__section {
  @include rem(
    (
      padding: 0 5px,
    )
  );

  display: block;
  text-align: left;

  @include gridle_state(tablet-min) {
    display: inline-block;
  }
}

//////////////////////////////////////////////////////////////////////////////// DOWNLOAD AREA
.download-area__dropdown {
  @include rem(
    (
      padding: 15px,
    )
  );

  margin: 0;
  width: 100%;

  @include gridle_state(tablet-min) {
    position: absolute;
    top: 105%;
    z-index: 9;
    margin-top: 0;
  }

  a {
    @include rem(
      (
        padding: 4px 0,
      )
    );

    color: $brand-text;
    display: block;
  }
}

//////////////////////////////////////////////////////////////////////////////// LOCATION FILTERS
.location-filter__holder {
  .dev-location & {
    border-top: 1px solid rgba($brand-button-border, 0.5);
    border-bottom: 1px solid rgba($brand-button-border, 0.5);
    margin: 50px 0;
  }
}

.location-filter {
  display: none;

  .filter-group {
    i {
      position: absolute;
      left: 3px;
      top: 3px;

      .dev-location & {
        top: 5px;
        left: 30px;
      }
    }
  }
}

.location-filter__btn {
  @include rem(
    (
      margin-bottom: 10px,
      margin-top: 0px,
    )
  );
  width: 100%;

  @include gridle_state(tablet-min) {
    text-align: right;
  }

  &.location-filter__btn-fullwidth {
    margin-top: -20px;
  }

  .btn {
    @include rem(
      (
        margin-bottom: 0,
      )
    );
  }

  .btn--dev-location {
    position: relative;
    width: 100%;
    text-align: left;
    text-transform: none;
    color: $brand-primary;
    padding-left: 0;
    font-size: 18px;
    font-family: $base-font-family-med;

    .btn__location-sub-text {
      display: block;
      font-size: 12px;
      line-height: 15px;
      color: $brand-text;
    }

    .icon {
      fill: $brand-primary;
    }
  }
}

.location-filter__dropdown {
  @include rem(
    (
      padding: 20px 10px 10px,
    )
  );

  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  background-color: $brand-primary;
  color: $brand-white;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        padding: 20px,
      )
    );
  }

  &.dev-location {
    background-color: $development-cyan;
  }

  ul {
    margin: 0;
  }
}

.location-filter__col {
  @include rem(
    (
      padding: 0 10px,
      margin-bottom: 20px,
    )
  );

  flex: 1 0 47%;

  @include gridle_state(tablet-min) {
    flex: 1 0 33%;
  }

  @include gridle_state(small-desktop) {
    flex: 1 0 auto;
    margin-bottom: 0;
  }

  .dev-location & {
    flex: 1 0 auto;
    @include gridle_state(tablet-min) {
      @include rem(
        (
          margin-bottom: 20px,
          padding-right: 20px,
        )
      );

      padding-left: 0;
    }
  }

  h4 {
    @include rem(
      (
        font-size: 16px,
        line-height: 20px,
        margin-bottom: 10px,
      )
    );

    font-style: italic;
    color: $brand-white;

    .dev-location & {
      color: $brand-primary;
      font-style: normal;

      @include rem(
        (
          font-size: 12px,
          line-height: 23px,
        )
      );
    }
  }

  input[type='checkbox'] + label {
    @include rem(
      (
        font-size: 14px,
        line-height: 18px,
        padding: 2px 5px 2px 30px,
        margin-bottom: 5px,
      )
    );

    display: inline-block;
    font-weight: 400;
    cursor: pointer;
  }

  input[type='checkbox'] + label:before,
  input[type='checkbox'] + label:after {
    content: none;
  }

  input[type='checkbox']:checked + label {
    background-color: darken($brand-primary, 7%);

    .dev-location & {
      background: transparent;
    }
  }
}

.dev-location {
  .location-filter__col {
    input[type='checkbox'] + label:before {
      @include rem(
        (
          width: 24px,
          height: 24px,
          margin-top: -1px,
        )
      );

      content: '';
      border: none;
      background-color: $brand-form-ele-bg !important;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0;
    }

    input[type='checkbox']:checked + label:after {
      @include transform-translate(50%, 0);
      @include rem(
        (
          font-size: 20px,
          top: 4px,
          left: -7px,
          margin-top: -2px,
          width: 20px,
          height: 20px,
        )
      );
      position: absolute;
      content: '';
      color: $brand-dark-grey;
      background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
      background-repeat: no-repeat;
    }
  }

  label {
    display: block;
    color: #fff;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
  }

  i {
    &.map-icon,
    &.fa {
      display: none;
    }
  }
}
