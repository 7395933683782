.advanced-search--rehash {
  background-color: $brand-white;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 51;
  height: 100vh;
  width: calc(100% - 75px);
  transition: transform 0.3s;
  transform: translateX(130%);
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.28);

  &.is-active {
    transform: translateX(0);
  }

  @include gridle_state(tablet-min) {
    width: 600px;
  }

  .advanced-search__form-holder {
    height: 100vh;
    overflow: auto;
  }

  .advanced-search__title {
    font-family: $brand-font;
    span {
      text-decoration: underline;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    svg {
      vertical-align: middle;
    }
  }

  input[type='checkbox']:checked + input[type='hidden'] + label:before,
  input[type='checkbox'] + label:before {
    background-color: $brand-white !important;
    border: 1px solid $brand-black;
    border-radius: 2px;
  }
  .form {
    label {
      @include rem(
        (
          padding-top: 10px,
          padding-bottom: 1px,
        )
      );
      font-family: $brand-font;
      font-size: 12px;
      line-height: 34px;
      opacity: 0.5;
      text-transform: uppercase;

      @include gridle_state(tablet-min) {
        @include rem(
          (
            padding-top: 30px,
            padding-bottom: 10px,
          )
        );
      }
    }

    .field-container__group {
      label {
        font-family: $base-font-family;
        font-size: 16px;
        line-height: 16px;
        opacity: 1;
        text-transform: none;
        padding-top: 5px;
        padding-bottom: 0;
      }
    }
  }

  .advanced-search__close-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: #00a0a0;
    border: none;
    border-top-left-radius: 0;

    @include gridle_state(tablet-min) {
      width: 50px;
      height: 50px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;

      @include gridle_state(tablet-min) {
        width: 50px;
        height: 50px;
      }
    }
  }

  .advanced-search__submit {
    .btn {
      font-family: $brand-font;
      margin-right: 10px;
    }

    a {
      font-family: $brand-font;
      font-size: 18px;
      color: $development-cyan;
      cursor: pointer;

      &:focus,
      &:hover {
        color: $development-blue;
        text-decoration: underline;
      }
    }
  }

  &.development-theme-1,
  .development-theme-1 & {
    .advanced-search__close-btn {
      background-color: $development-cyan;
    }

    .form {
      input.slider-input {
        border-bottom-color: $development-cyan;
      }
    }

    .slider-group .irs--flat .irs-bar {
      background-color: $development-cyan;
    }

    .slider-group .irs--flat .irs-handle {
      border-color: $development-cyan;
    }

    .advanced-search__submit a {
      color: $development-cyan;
    }
  }

  &.development-theme-2,
  .development-theme-2 & {
    .advanced-search__close-btn {
      background-color: $development-blue;
    }

    .form {
      input.slider-input {
        border-bottom-color: $development-blue;
      }
    }

    .slider-group .irs--flat .irs-bar {
      background-color: $development-blue;
    }

    .slider-group .irs--flat .irs-handle {
      border-color: $development-blue;
    }

    .advanced-search__submit a {
      color: $development-blue;
    }
  }

  &.development-theme-3,
  .development-theme-3 & {
    .advanced-search__close-btn {
      background-color: $blog-orange;
    }

    .form {
      input.slider-input {
        border-bottom-color: $blog-orange;
      }
    }

    .slider-group .irs--flat .irs-bar {
      background-color: $blog-orange;
    }

    .slider-group .irs--flat .irs-handle {
      border-color: $blog-orange;
    }

    .advanced-search__submit a {
      color: $blog-orange;
    }
  }
}

// UPDATED SLIDER STYLING
.form {
  input.slider-input {
    border: none;
    border-bottom: 2px solid $development-cyan;
    padding: 0;
    height: auto;
  }
}

.slider-group {
  label {
    font-family: $brand-font;
    font-size: 12px;
    line-height: 34px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .irs--flat .irs-handle {
    background-color: $brand-white;
    border: 2px solid $development-cyan;
    border-radius: 50%;
    top: 24px;
    width: 14px;
    height: 14px;
  }

  .irs--flat .irs-handle i,
  .irs--flat .irs-handle.state_hover > i:first-child,
  .irs--flat .irs-handle:hover > i:first-child {
    background-color: transparent;
  }

  .irs--flat .irs-handle:hover,
  .irs--flat .irs-handle i:hover {
    background-color: $development-cyan;
  }

  .irs--flat .irs-line {
    height: 2px;
    top: 30px;
  }

  .irs--flat .irs-bar {
    height: 2px;
    top: 30px;
  }

  .irs--flat .irs-bar {
    background-color: $development-cyan;
  }

  .slider-group__inputs {
    display: flex;
    justify-content: space-between;

    .slider-group__col {
      flex: 0 0 48%;

      @include gridle_state(tablet-min) {
        flex: 0 0 128px;
      }
    }

    label {
      font-family: $base-font-family;
      padding: 0;
      font-size: 12px;
      line-height: 28px;
      opacity: 1;
      text-transform: none;
    }
  }
}
