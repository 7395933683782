.development-hero {
  &.development-theme-1 {
    .development-hero__media-container {
      background-color: $development-cyan;
    }

    .development-hero__content {
      background-color: rgba($color: $development-cyan, $alpha: 0.05);
    }

    .development-hero__tenure-select {
      background-color: $development-cyan;
    }
  }

  &.development-theme-2 {
    .development-hero__media-container {
      background-color: $development-blue;
    }

    .development-hero__content {
      background-color: rgba($color: $development-blue, $alpha: 0.05);
    }

    .development-hero__tenure-select {
      background-color: $development-blue;
    }
  }

  &.development-theme-3 {
    .development-hero__media-container {
      background-color: $blog-orange;
    }

    .development-hero__content {
      background-color: rgba($color: $blog-orange, $alpha: 0.05);
    }

    .development-hero__tenure-select {
      background-color: $blog-orange;
    }
  }

  &__container {
    padding: 0;
    display: flex;

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }

    @include gridle_state(tablet mobile) {
      padding: 25px 0 0;
    }

    @include gridle_state(mobile) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__media-container {
    padding: 1rem;
    max-width: 320px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $development-cyan;

    @media screen and (max-width: 991px) {
      flex: 1 0 25%;
    }

    @media screen and (max-width: 767px) {
      flex: 1 0 auto;
    }

    @include gridle_state(mobile) {
      max-width: 100%;
      padding: 20px;
    }
  }

  &__media {
    max-width: 232px;
    flex: 1 0 auto;

    @include gridle_state(mobile) {
      max-width: 100%;
      max-height: 180px;
      object-fit: contain;
    }
  }

  &__content {
    max-width: 100%;
    flex: 1 1 auto;
    padding: 40px 50px 20px;
    background-color: rgba($color: $development-cyan, $alpha: 0.05);

    @media screen and (max-width: 991px) {
      flex: 1 1 75%;
    }

    @media screen and (max-width: 767px) {
      flex: 1 1 auto;
    }

    @include gridle_state(mobile) {
      padding: 20px 10px;
    }

    hr {
      margin-right: 0;
      margin-left: 0;
    }

    h1 {
      font-family: $base-font-family-med;
      border: 0;
      margin: 0;
      margin-bottom: 10px;
      > span {
        display: block;
      }
    }

    &-title {
      color: $brand-dark-grey;
      font-family: $base-font-family-med;
      @include rem(
        (
          font-size: 52px,
          line-height: 69px,
        )
      );

      @include gridle_state(mobile) {
        @include rem(
          (
            font-size: 32px,
            line-height: 43px,
          )
        );
      }
    }

    &-subtitle {
      border: 0;
      font-family: $base-font-family;
      color: $brand-dark-grey;
      @include rem(
        (
          font-size: 18px,
          line-height: 30px,
        )
      );
      @include gridle_state(mobile) {
        @include rem(
          (
            font-size: 14px,
            line-height: 21px,
          )
        );
      }
    }

    &-type-title {
      color: rgba($color: $brand-dark-grey, $alpha: 0.5);
      font-family: $base-font-family;
      margin-bottom: 12px;

      @include rem(
        (
          font-size: 14px,
          line-height: 30px,
        )
      );

      @media screen and (max-width: 991px) {
        margin-bottom: 0;
      }
    }

    &-type {
      display: flex;

      @include gridle_state(mobile) {
        flex-wrap: wrap;
      }
      &--item {
        font-family: $base-font-family;
        color: $brand-dark-grey;
        padding-right: 30px;
        @include rem(
          (
            font-size: 18px,
            line-height: 30px,
          )
        );
        @include gridle_state(mobile) {
          flex: 1 0 50%;
          padding: 5px 2px;
          @include rem(
            (
              font-size: 14px,
              line-height: 18px,
            )
          );
        }

        &:hover,
        &.is-active {
          font-family: $base-font-family-med;
          color: $development-cyan;
          text-decoration: underline;
        }
      }
    }
  }

  .btn--block {
    &:hover {
      svg {
        stroke: $brand-black;
        fill: $brand-black;
      }
    }
    svg {
      stroke: $brand-white;
      fill: transparent;
    }
  }
}

.development-hero__tenure-select {
  max-width: 350px;
  flex: 1 0 100%;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 991px) {
    max-width: 100%;
    justify-content: center;
  }
}

.development-hero__tenure-select-inner {
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 991px) {
    padding: 0 15px;
    flex-direction: row;
  }
}

select.tenure-select {
  height: 49px;
  border: none;
  margin-bottom: 0;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }

  .development-subnav__select-holder & {
    @media screen and (max-width: 1199px) {
      width: auto;
    }
  }
}

.development-hero.development-hero--plot {
  background-color: $development-cyan;
  margin-bottom: 50px;

  .development-hero__media-container {
    padding: 1rem;
    max-width: 380px;
    background-color: transparent;
  }

  .development-hero__content {
    padding-left: 60px;
    background-color: transparent;

    @include gridle_state(mobile) {
      padding-left: 20px;
    }

    .grid-container {
      align-items: center;
    }
  }

  .development-hero__content-title {
    font-size: 52px;
    line-height: 65px;
    color: $brand-white;

    @include gridle_state(mobile) {
      font-size: 32px;
      line-height: 43px;
    }
  }

  .development-hero__content-subtitle {
    color: $brand-white;
    font-size: 18px;
    line-height: 30px;
  }
}

.development-hero__tel {
  @include gridle_state(mobile) {
    padding-top: 10px;
  }
  a {
    color: $brand-white;
    font-size: 18px;
    line-height: 30px;
    text-decoration: underline;

    &:hover {
      color: $brand-white;
    }
  }
}

.development-hero-inverted-dark {
  &.development-hero--plot {
    .development-hero__content {
      .grid-container {
        align-items: center;
      }
    }
  }
}
