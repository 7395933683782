.person-profile {
    width: 100%;

    &:first-child {
        margin-top: rem(-94px);

        @include gridle_state ( tablet-min ) {
            margin-top: rem(-68px);
        }

        @include gridle_state ( small-desktop ) {
            margin-top: rem(-94px);
        }
    }

    @include gridle_state ( tablet-min ) {
        margin-top: rem(-68px);
        width: auto;
    }

    @include gridle_state ( small-desktop ) {
        margin-top: rem(-94px);
        width: auto;
    }

    .contributers & {
        @include gridle_state ( tablet-min ) {
            padding: rem(0 20px);
        }

        @include gridle_state ( small-desktop ) {
            padding: rem(0 40px);
        }
    }

    &:hover{
      .person-profile__media{
          transform: translate(-50%,-50%) scale(1.03);
      }

    }
}

.person-profile__author {
  color: $brand-primary;
  font-family: $base-font-family-med;
}

.person-profile--detail {
    margin-top: rem(-57px);
    width: auto;
    text-align: center;
    position: relative;
    z-index: 5;

    @include gridle_state ( tablet-min ) {
        margin-top: rem(-94px);
        margin-left: rem(25px);
        text-align: left;
    }

    &:first-child {
        margin-top: rem(-57px);

        @include gridle_state ( tablet-min ) {
            margin-top: rem(-94px);
        }
    }
}

.person-profile--about {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;

    @include gridle_state ( tablet-min ) {
        flex-wrap: nowrap;
    }
}

.person-profile__image-holder {
    width: rem(188px);
    height: rem(188px);
    border-radius: 5000000px;
    position: relative;
    background-color: $brand-white;
    margin: auto;

    @include gridle_state ( tablet-min ) {
        width: rem(136px);
        height: rem(136px);
    }

    @include gridle_state ( small-desktop ) {
        width: rem(188px);
        height: rem(188px);
    }

    .person-profile--detail & {
        margin: 0;
        display: inline-block;
        vertical-align: bottom;

        width: rem(94px);
        height: rem(94px);
        @include gridle_state ( tablet-min ) {
            width: rem(188px);
            height: rem(188px);
        }
    }

    .person-profile--about & {
        margin: auto;
        flex: 0 0 100%;

        @include gridle_state ( tablet-min ) {
            margin: rem(0 20px 0 0);
            flex: 0 0 auto;
        }
    }
}

.person-profile__media {
    background-repeat: no-repeat;
    background-position: center;
    width: rem(160px);
    height: rem(160px);
    border-radius: 5000000px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s ease;

    @include gridle_state ( tablet-min ) {
        width: rem(120px);
        height: rem(120px);
    }

    @include gridle_state ( small-desktop ) {
        width: rem(160px);
        height: rem(160px);
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      fill: $brand-primary;
    }

    .person-profile--detail & {
        width: rem(80px);
        height: rem(80px);
        @include gridle_state ( tablet-min ) {
            width: rem(160px);
            height: rem(160px);
        }
    }
}

.person-profile__content {
    a {
        font-size: rem(16px);
        line-height: rem(20px);
        text-decoration: none;
        color: $brand-primary;
        font-family: $base-font-family-med;

        &:hover {
            color: $brand-text;
        }

        .person-profile--detail & {
            font-size: rem(14px);
            line-height: rem(18px);

            @include gridle_state ( tablet-min ) {
                font-size: rem(16px);
                line-height: rem(20px);
            }
        }
    }

    .small-text {
        font-size: rem(12px);
    }

    .person-profile--detail & {
        display: block;
        width: auto;
        margin-bottom: rem(20px);
        margin-left: 0;

        @include gridle_state ( tablet-min ) {
            display: inline-block;
            vertical-align: bottom;
            margin-left: rem(20px);
        }

    }

    .person-profile--about & {
        flex: 1 1 100%;
        margin-top: rem(18px);
        text-align: center;

        @include gridle_state ( tablet-min ) {
            flex: 1 1 auto;
            text-align: left;
        }
    }
}

.person-profile__title {
  font-family: $brand-font;
  font-size: rem(22px);
  color: $brand-primary;
}


.contributers {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}
