.logo-display {
    margin-bottom: 50px;
}

.logo-display__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.logo-display__cell {

}

.logo-display__cell-inner {
    padding: 5px 6px;

    @include gridle_state ( tablet-min ) {
        padding: 10px 15px;
    }
}

.logo-display__image {
    height: 54px;

    @include gridle_state ( tablet-min ) {
        height: 87px;
    }
}