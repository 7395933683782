$development-cyan: #00a0a0;
$development-blue: #00313e;
$development-orange: #c64d00;

body {
  padding: 0;
}

.header {
  hr {
    display: none;
  }
}

.development-intro {
  padding: 60px 0;

  @include gridle_state(tablet mobile) {
    padding: 25px;
  }

  .btn {
    margin: 12px 0;
  }
}

.development-5050 {
  &--reversed {
    .grid-container {
      flex-direction: row-reverse;
    }
  }
}

.development-section {
  h2 {
    font-family: $base-font-family-med;
    font-size: 32px;
    line-height: 43px;
    color: $brand-black;
  }

  p {
    color: $brand-black;
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 28px;
  }

  .dev-map {
    margin-top: 0;
  }
}

.text-center {
  text-align: center;
}

.bg-white {
  background-color: $brand-white;
}

.bg-light-grey {
  background-color: $development-light-grey;
}

.bg-light-blue {
  background-color: rgba($development-cyan, 0.1);
}

.vert-padding-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.development-button {
  justify-content: start;
  svg {
    margin-right: 10px;
  }

  &.brochure-button {
    position: relative;

    &.active {
      .brochure-button__dropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
      }
    }
  }

  .brochure-button {
    &__dropdown {
      display: none;
      background-color: $brand-white;
      top: 100%;
      left: -1px;
      width: calc(100% + 2px);
      z-index: 10;
      border: 1px solid $brand-dark-grey;

      &-item {
        padding: 15px 70px 15px 20px;
        border-bottom: 1px solid $brand-dark-grey;
        text-align: left;
        position: relative;
        color: $brand-dark-grey;

        svg {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          stroke: $brand-dark-grey !important;
        }

        &:last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
}

// DEVELOPMENT PAGINATION
.development__pagination {
  display: flex;
}

.development__pagination-col {
  flex: 1 0 33.33333%;

  &:last-child {
    text-align: right;
  }
}

.development__pagination-prev,
.development__pagination-next {
  font-family: $brand-font;
  text-decoration: underline;
  font-size: 18px;
  line-height: 36px;
  background: transparent;
  border: none;
}

.development__pagination-indicator {
  font-family: $brand-font;
  font-size: 18px;
  line-height: 36px;
  display: block;
  text-align: center;
}

section + p,
section + p + p,
section + p + p + p {
  display: block;
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  margin: 30px auto;
}
