.find-home-search {
    > .site-wide {
        > .umb-grid {
            @media screen and (max-width: 767px) {
                margin-top: 0;
            }
        }
    }
}

.find-home-search__list-area {
    @media screen and (max-width: 767px) {
        order: 1;
    }
}

.find-home-search__map-area {
    @media screen and (max-width: 767px) {
        order: 0;
    }
}

