.development-share {
  display: flex;
  align-items: center;
  color: $brand-dark-grey;
  flex-direction: column-reverse;
  padding: 8px;
  text-align: center;

  &__text {
    color: $brand-dark-grey;
    text-transform: uppercase;
    margin-top: 15px;
  }

  &__icons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .share-btn {
    background: transparent;
    border: none;
    padding: 0;
    line-height: 0;
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }

    &:focus,
    &:hover {
      outline: none;

      svg {
        color: $brand-primary-light;
        fill: $brand-primary-light;
      }
    }

    svg {
      color: $brand-primary;
      fill: $brand-primary;
      width: 28px;
      height: 28px;
    }
  }
}
