/**
 * Override variables from Titon: /titon-toolkit/scss/toolkit/_common.scss
 * More details can be found here: http://titon.io/en/toolkit/2.1.6/development/sass/variables
 */

$namespace: 'netc-';
$base-size: 16px;
$base-line-height: 1.5em;

$h1-size: 3rem !default;
$h2-size: 2.5rem !default;
$h3-size: 2.1rem !default;
$h4-size: 1.8rem !default;
$h5-size: 1.5rem !default;
$h6-size: 1.2rem !default;

// @note: Add custom classes to avoid Kentico backend issue where widget dropdown appear at the bottom of the page.
$offCanvas-class: '.netc-off-canvas';
$offCanvas-class-content: '.netc-on-canvas';
$offCanvas-class-wrapper: '.netc-canvas';
