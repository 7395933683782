// configuration
$netc-form-interactions: (
  focus: is-focus,
  valid: is-valid,
  error: is-not-valid,
  empty: is-empty
) !default;

$netc-core-input-padding: 6px 12px !default;
$netc-core-option-padding: 12px !default;
$netc-core-asterix-class-name: 'asterix' !default;
$netc-core-form-element-color: #4589ca !default;

// create mixin to manipulate classes such as .is-focus .is-valid .is-error .is-empty
@mixin netc-form-validation($key) {
  @if map-has-key($netc-form-interactions, $key) {
    .#{map-get($netc-form-interactions, $key)} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$netc-form-interactions}`. "
      + "Please make sure it is defined in `$netc-form-interactions` map.";
  }
}

@mixin netc-default-form() {
  label,
  .fake-label {
    font-weight: bold;
  }

	//.field-container {
		#{$all-text-inputs},
    select:not(.pika-select),
    .#{$namespace}select,
    textarea {
      @include rem(padding, $netc-core-input-padding);
			display: block;
      width: 100%;
      border: 1px solid $brand-light-grey;
		}

    input:not([type="date"]),
    textarea {
      -webkit-appearance:none;
      border-radius: 0;
    }

    input[type="date"] {
      @include gridle_state ( mobile ) {
        min-width: 95%; // @note: work around ios issue - http://output.jsbin.com/civor/1/
        border-radius: 0;
      }
    }

    // remove webkit styles
    input::-webkit-calendar-picker-indicator{
      display: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button,
    input::-webkit-clear-button {
      -webkit-appearance: none;
      margin: 0;
    }

    #{$all-text-inputs},
    select:not(.pika-select),
    .#{$namespace}select,
    textarea,
    [type="file"],
    &--has-group {
      @include rem(margin-bottom, $global-element-mb);
		}

    .field-container__group {
      label,
      .fake-label {
        font-weight: normal;
      }
    }
	//}


  input[type="radio"],
  input[type="checkbox"],
  input[type="checkbox"] + input[type="hidden"] {
  	@include netc-visuallyhidden;

    + label:not(.ot-switch):not(.ot-chkbox *) {
      position: relative;
      display: block;

      &:before {
        @include rem((
          width: 20px,
          height: 20px,
          margin-right: 8px,
        ));
        background-color: $brand-white;
        border: 1px solid $brand-light-grey;
        content: '';
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
      }

      &:after {
        @include rem((
          width: 12px,
          height: 12px,
          margin-top: 1px,
          margin-left: 10px,
        ));
        content: '';
        transition: all 0.3s ease-in-out;
        opacity: 0;
        border-radius: 50%;
        position: absolute;
        left: 0;
        border-radius: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    &:checked {
      + label:not(.ot-switch):not(.ot-chkbox *),
      + input[type="hidden"] + label {
        &:after {
          background-color: $netc-core-form-element-color;
          opacity: 1;
        }
      }
    }
  }

  input[type="checkbox"] {
    + label:not(.ot-switch):not(.ot-chkbox *),
    + input[type="hidden"] + label {
      &:before,
      &:after {
        border-radius: 0;
        background-color: transparent !important;
      }

      &:after {
        // @include fa-icon();
        @include rem((
          margin-top: -1px,
          left: -1px,
          width: 20px,
          height: 20px,
        ));
        content: '';
        background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
        background-repeat: no-repeat;

        vertical-align: middle;
        color: $netc-core-form-element-color;
      }
    }
  }

  .field-container {
    position: relative;

    // styles for the input date field with a calendar icon
    &--has-date-button {
      .#{$btn-name} {
        display: none;

        @include gridle_state ( desktop ) {
          display: block;
        }

        i {
          @include rem((font-size: 20px));
          @include helpers-fa-icon-self($fa-var-calendar);
          color: $netc-core-form-element-color;
        }
      }

      .btn {
        @include rem((
          padding: 0 12px 0 12px,
        ));
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-left: 1px solid $brand-light-grey;
      }
    }
  }
}

@include netc-default-form();

// apply default error styles
@include netc-form-validation(error) {
  input,
  select:not(.pika-select),
  .#{$namespace}select,
  textarea {
    @include netc-property-placeholder {
      color: #{map-get($netc-alerts, #{$netc-core-alert-namespace}--warning)}
    }
    border: 1px solid #{map-get($netc-alerts, #{$netc-core-alert-namespace}--warning)};
    color: #{map-get($netc-alerts, #{$netc-core-alert-namespace}--warning)};
  }

  .#{$namespace}custom-input {
  .#{$namespace}select {
      border: 1px solid #{map-get($netc-alerts, #{$netc-core-alert-namespace}--warning)};
    }
  }

  &.field-container--has-group label {
    color: #{map-get($netc-alerts, #{$netc-core-alert-namespace}--warning)};

    &:before {
      border: 1px solid #{map-get($netc-alerts, #{$netc-core-alert-namespace}--warning)};
    }
  }
}

// apply default validation styles
@include netc-form-validation(valid) {
  input,
  select:not(.pika-select),
  .#{$namespace}select,
  textarea {
    @include netc-property-placeholder {
      color: #{map-get($netc-alerts, #{$netc-core-alert-namespace}--success)}
    }
    border: 1px solid #{map-get($netc-alerts, #{$netc-core-alert-namespace}--success)};
    color: #{map-get($netc-alerts, #{$netc-core-alert-namespace}--success)};
  }

  .#{$namespace}custom-input {
  .#{$namespace}select {
      border: 1px solid #{map-get($netc-alerts, #{$netc-core-alert-namespace}--success)};
    }
  }

  &.field-container--has-group label {
    color: #{map-get($netc-alerts, #{$netc-core-alert-namespace}--success)};

    &:before {
      border: 1px solid #{map-get($netc-alerts, #{$netc-core-alert-namespace}--success)};
    }
  }
}

// validation message
.message {
  display: none;

  p {
    @include rem((
      font-size: 12px,
      line-height: 16px,
    ));
    margin-bottom: 0;
  }
}

// show message on focus
@include netc-form-validation(focus) {
  .message {
    display: block;
  }
}

.#{$netc-core-asterix-class-name} {
  color: $netc-core-form-element-color;
}

// .#{$namespace}select {
//
// }
// netc-select-arrow

.#{$namespace}custom-input {
  width: 100%;

  .#{$namespace}select {
    background-color: #fff;
    min-width: 100%!important;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    background: $brand-white;
    border-radius: 0;
    white-space: nowrap;
    line-height: normal;
    z-index: 3;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border: 1px solid $brand-light-grey;
  }

  .#{$namespace}select-label {
    @include rem((
      padding-top: 7px,
      padding-bottom: 7px,
    ));
    flex: 1 auto;
    order: 1;
    border-right: 1px solid $brand-light-grey;
  }

  .#{$namespace}select-arrow {
    float: none;
    max-width: 40px;
    flex: 1 auto;
    order: 2;
    padding: 0;
    text-align: center;

    i {
      @include helpers-fa-icon($fa-var-chevron-down);
      color: $netc-core-form-element-color;
    }
  }

  .#{$namespace}drop {
    @include rem((
      margin-top: -6px,
    ));
    width: 100%;
    background: $brand-white;
    border: 1px solid $brand-light-grey;
    box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.25);

    a {
      @include rem((
        padding: $netc-core-option-padding,
      ));
      color: $brand-text;
      text-decoration: none;

      &:hover {
        background-color: $netc-core-form-element-color;
        color: $brand-white;
      }
    }
  }
}

// override pikaday styles
.netc-calendar-theme {

  &.pika-single {
    &.is-bound {
      @include rem((
        margin-top: 5px,
      ));
    }
  }

  .pika-title {
    @include rem((
      padding-left: 10px,
      padding-right: 10px,
      margin: 0 -9px 15px -9px,
    ));
    border-bottom: 1px solid $brand-light-grey;
  }

  .pika-prev,
  .pika-next {
    @include rem((
      margin-top: 8px,
      padding-top: 20px,
      height: 20px,
    ));
    background: none;
    color: $netc-core-form-element-color;
    position: relative;
    text-indent: 0;
    overflow: hidden;
    opacity: 1;
  }

  .pika-prev {
    @include helpers-fa-icon($fa-var-chevron-left);

    &:before {
      @include rem((
        height: 20px,
      ));
      display: inline-block;
      top: 0;
      bottom: 0;
      left: 0px;
      position: absolute;
      right: 0;
    }
  }

  .pika-next {
    @include helpers-fa-icon($fa-var-chevron-right);

    &:before {
      @include rem((
        height: 20px,
      ));
      display: inline-block;
      top: 0;
      bottom: 0;
      left: 0px;
      position: absolute;
      right: 0;
    }
  }

  .pika-table {
    abbr[title] {
      border-bottom: 0;
    }

    abbr {
      cursor: auto;
    }
  }

  .pika-button {
    text-align: center;
    background-color: transparent;

    &:hover {
      background-color: $netc-core-form-element-color;
      border-radius: 0;
    }

    .is-today & {
      color: $netc-core-form-element-color;

      &:hover {
        color: $brand-white;
      }
    }

    .is-selected & {
      background-color: $netc-core-form-element-color;
      box-shadow: none;
      border-radius: 0;
    }
  }
}
