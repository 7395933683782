// configuration
$netc-core-cookie-namespace: 'cookie' !default;
$netc-core-cookie-sticky: false !default;
$netc-core-cookie-background-color: #959595 !default;
$netc-core-cookie-padding:  15px 10px !default;

// default styles
.#{$netc-core-cookie-namespace} {
  @include rem(padding, $netc-core-cookie-padding);

  @if ($netc-core-cookie-sticky == true) {
    position: absolute;
    bottom: 0;
    top: auto;
  }
  border: 1px solid $netc-core-cookie-background-color;
  background-color: lighten($netc-core-cookie-background-color, 40%);

  p {
    @include rem(line-height, 20px);
    margin-bottom: 0;
  }
}
