.development-gallery {
  display: flex;
  justify-content: center;
  overflow: hidden;

  &__container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;

    @include gridle_state(mobile) {
      justify-content: flex-start;
      max-width: none;
      overflow-x: auto;
    }
  }

  &__featured-image-container {
    flex: 0 1 48%;
    margin-right: 20px;

    @include gridle_state(mobile) {
      flex: 0 1 100%;
      margin-right: 0;
    }
  }

  &__image-container {
    flex: 0 1 48%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 403px;

    @include gridle_state(mobile) {
      flex: 0 0 100%;
      flex-wrap: nowrap;
      height: 257px;
    }

    + .development-gallery__image-container {
      margin-left: 20px;

      @include gridle_state(mobile) {
        margin-left: 0;
      }
    }
  }

  &__featured-image {
    height: 403px;

    @include gridle_state(mobile) {
      flex: 0 0 305px;
      height: 257px;
      width: 305px;
    }

    a {
      display: block;
      height: 100%;
    }

    img {
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    flex: 0 1 48%;
    height: 50%;

    @include gridle_state(mobile) {
      flex: 0 0 305px;
      height: 257px;
      width: 305px;
      margin-left: 10px;
    }

    &:nth-child(3) {
      padding-top: 2%;

      @include gridle_state(mobile) {
        padding-top: 0;
      }
    }

    &:nth-child(4) {
      padding-top: 2%;

      @include gridle_state(mobile) {
        padding-top: 0;
      }
    }

    a {
      display: block;
      height: 100%;
    }

    img {
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
      width: 100%;
      height: 100%;
    }
  }
}

.development-gallery__carousel {
  position: relative;

  .tns-controls {
    button {
      width: 50px;
      height: 50px;
      background-color: $development-cyan;
      border: none;

      &:hover {
        background-color: $brand-light-grey;
      }

      &:first-child {
        position: absolute;
        top: 0;
        right: 64px;
        z-index: 1;

        @include gridle_state(mobile) {
          right: 50px;
        }
      }

      &:last-child {
        position: absolute;
        top: 0;
        right: 14px;
        z-index: 1;

        @include gridle_state(mobile) {
          right: 0;
        }
      }

      svg {
        fill: $brand-white;
        width: 11px;
      }
    }
  }
}

.glightbox-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .goverlay {
    background-color: rgba($brand-white, 0.92);
  }

  .gcontainer {
    width: auto;
    height: auto;
    overflow: visible;
    padding-bottom: 60px;

    @include gridle_state(mobile tablet) {
      width: 100%;
      height: 100%;
    }
  }

  .gslider {
    overflow: visible;
  }
}

.glightbox-clean {
  .gnext {
    top: auto;
    bottom: 50px;
    left: auto;
    right: 0;
    background-color: $development-cyan;
    width: 50px;
    height: 50px;

    &:hover {
      background-color: rgba($development-cyan, 0.9);
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      opacity: 1;
      background-color: #8bd3d3;
    }
  }

  .gprev {
    top: auto;
    bottom: 100px;
    left: auto;
    right: 0;
    background-color: $development-cyan;
    width: 50px;
    height: 50px;

    &:hover {
      background-color: rgba($development-cyan, 0.9);
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      opacity: 1;
      background-color: #8bd3d3;
    }
  }

  .gcounter {
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    background-color: $development-cyan;
    width: 50px;
    height: 50px;
    color: $brand-white;
    text-align: center;
    line-height: 50px;
    display: none;

    &.show-counter {
      display: block;
    }
  }

  .gclose {
    top: 0;
    left: auto;
    right: 0;
    background-color: $brand-white;
    width: 50px;
    height: 50px;

    &:hover {
      background-color: rgba($brand-white, 0.9);
    }

    &:focus {
      outline: none;
    }

    svg {
      margin: auto;
    }

    path {
      fill: $development-cyan;
    }
  }

  .gslide-description {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .gdesc-inner {
    padding: 10px 5px;
  }

  .gslide-desc {
    font-size: 16px;
    line-height: 28px;
  }
}

.glightbox-mobile {
  .glightbox-container {
    .gslide-description {
      top: auto;
      bottom: 0;
      padding: 10px 50px 10px 10px;
      background: $brand-white;
    }

    .gslide-title {
      color: $brand-black;
      margin: 0;
    }
  }
}
