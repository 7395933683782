/**
* Add form specific styles in this file.
*
* @include netc-form-validation(focus) {
* 	color: transparent;
* }
*
* .#{map-get($netc-form-interactions, focus)} {
*   color: red;
* }
*
*/

.form-styling {
  @include rem(
    (
      padding: 5px,
    )
  );
  background-color: $brand-form-ele-bg;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        padding: 15px,
      )
    );
  }
}

.form {
  .centered-form__form-holder & {
    .field-container {
      input,
      textarea,
      .select2-container {
        @include rem(
          (
            margin-bottom: 22px,
          )
        );
      }

      &--currency {
        .icon {
          border-right: 1px solid $brand-border;
          left: 14px;
          padding-right: 10px;
          padding-top: 8px;
          height: 100%;
          font-weight: 700;
        }

        input {
          padding-left: 40px;
        }
      }
    }

    label,
    .fake-label {
      @include rem(
        (
          font-size: 18px,
        )
      );

      font-family: $base-font-family-med;
      color: $brand-black;
      font-weight: 400;
      padding-bottom: 12px;
    }
  }

  .field-container {
    @include gridle_state(small-desktop) {
      @include rem(
        (
          max-width: 400px,
        )
      );
    }

    &--currency {
      position: relative;

      .icon {
        @include transform-translate(0, -50%);
        position: absolute;
        top: 50%;
        left: 10px;
      }

      input {
        padding-left: 25px;
      }
    }
  }

  .field-container--has-group {
    display: flex;
    flex-wrap: wrap;
  }

  .field-container {
    max-width: 100%;
  }

  .field-container--icon-check {
    justify-content: space-between;

    .field-container__group {
      flex: 0 0 31%;
      margin-bottom: 20px;

      @include gridle_state(tablet-min) {
        flex: 0 0 100%;
      }

      @include gridle_state(small-desktop) {
        flex: 0 0 31%;
      }

      input[type='checkbox']:checked + label,
      input[type='checkbox']:checked + input[type='hidden'] + label,
      input[type='radio']:checked + label,
      input[type='radio']:checked + input[type='hidden'] + label {
        background-color: $brand-primary;

        p {
          color: $brand-white;
        }

        .form__check-icon {
          svg {
            fill: $brand-white;
          }
        }
      }

      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px 10px 10px;
        margin: 0;
        border: 1px solid $brand-primary;
        cursor: pointer;
        transition: background-color 0.3s;
      }

      p {
        @include rem(
          (
            font-size: 14px,
            line-height: 18px,
          )
        );

        font-family: $base-font-family-med;
        margin-bottom: 0;
        color: $brand-primary;
        padding-top: 15px;
        text-align: center;
        justify-self: center;
        transition: color 0.3s;
      }
    }
  }

  .form__check-icon {
    flex: 0 0 50px;
    max-height: 50px;

    svg {
      height: 50px;
      fill: $brand-primary;
      transition: fill 0.3s;
    }
  }

  .field-container--has-info {
    padding-right: 50px;
    position: relative;
  }

  label,
  .fake-label {
    @include rem(
      (
        font-size: 14px,
      )
    );

    font-family: $base-font-family;
    color: $brand-black;
    font-weight: 400;
  }

  .label-tooltip {
    text-decoration: underline;
    text-decoration-skip: auto;
    cursor: pointer;
  }

  label {
    @include rem(
      (
        padding-bottom: 10px,
      )
    );
    display: inline-block;
  }

  input,
  textarea {
    border-color: $brand-border;
    font-family: $base-font-family;

    &:focus {
      outline: 2px solid $brand-primary;
    }
  }

  // Remove the spinner from numeric fields on firefox.
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='date'],
  textarea {
    background-color: $brand-white;
  }

  input[type='text'],
  input[type='password'],
  input[type='email'] {
    @include rem(
      (
        height: 40px,
      )
    );
    background-color: $brand-white;
  }

  .is-not-valid input,
  .is-not-valid select:not(.pika-select),
  .is-not-valid .netc-select,
  .is-not-valid textarea,
  .is-not-valid.field-container--has-group label:before,
  .is-not-valid .select2-container .select2-selection--single,
  .is-not-valid input[type='checkbox'] + input[type='hidden'] + label:before {
    border-color: #ff2400;
  }

  .is-valid input,
  .is-valid select:not(.pika-select),
  .is-valid .netc-select,
  .is-valid textarea,
  .is-valid.field-container--has-group label:before,
  .is-valid .select2-container .select2-selection--single,
  .is-valid input[type='checkbox'] + input[type='hidden'] + label:before {
    border-color: #00a752;
  }

  input {
    & + span.fa {
      display: inline;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      font-size: 20px;
    }

    &.valid {
      border-color: #00a752;

      & + span.fa {
        &::before {
          content: '\F00C';
          color: #00a752;
        }
      }
    }

    &.input-validation-error {
      border-color: #ff2400;

      & + span.fa {
        &::before {
          content: '\F00D';
          color: #ff2400;
        }
      }
    }
  }

  select {
    & ~ span.fa {
      display: inline;
      position: absolute;
      top: 0;
      right: 30px;
      padding: 10px;
      font-size: 20px;
    }

    &.valid {
      border-color: #00a752;

      & ~ span.fa {
        &::before {
          content: '\F00C';
          color: #00a752;
        }
      }
    }

    &.input-validation-error {
      border-color: #ff2400;

      & ~ span.fa {
        &::before {
          content: '\F00D';
          color: #ff2400;
        }
      }
    }
  }

  .field-container--has-date-button {
    .btn {
      @include rem(
        (
          max-width: 50px,
        )
      );

      text-align: right;
      display: block;

      i {
        color: $brand-primary;
      }
    }
  }

  .field-container--disabled {
    opacity: 0.3;
  }

  .field-container__group {
    @include rem(
      (
        margin-bottom: 12px,
        min-height: 25px,
      )
    );

    width: auto;
    display: flex;
    vertical-align: top;
    flex-wrap: wrap;
    flex: 1 0 50%;

    > .field-container__group {
      flex: 1 0 32%;

      @include gridle_state(mobile) {
        flex: 1 0 49%;
      }
    }

    label {
      @include rem(
        (
          font-size: 12px,
          margin-right: 15px,
          padding-left: 30px,
          margin-top: 5px,
        )
      );
      font-style: normal;
      padding-bottom: 0;
    }
  }

  input[type='radio'] + label:before,
  input[type='checkbox'] + label:before,
  input[type='checkbox'] + input[type='hidden'] + label:before {
    @include rem(
      (
        width: 24px,
        height: 24px,
        margin-top: -1px,
      )
    );

    border-color: $brand-border;
    background-color: $brand-grey;

    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }

  input[type='checkbox'] + label:before,
  input[type='checkbox'] + input[type='hidden'] + label:before,
  input[type='checkbox'] + label:after,
  input[type='checkbox'] + input[type='hidden'] + label:after,
  input[type='radio'] + label:before,
  input[type='radio'] + input[type='hidden'] + label:before,
  input[type='radio'] + label:after,
  input[type='radio'] + input[type='hidden'] + label:after {
    .centered-form__form-holder & {
      content: none;
    }
  }

  input[type='radio'] + label:after,
  input[type='checkbox'] + label:after,
  input[type='checkbox'] + input[type='hidden'] + label:after {
    @include transform-translate(0, 0);
    @include rem(
      (
        width: 14px,
        height: 14px,
        top: 4px,
        left: -5px,
      )
    );
  }

  input[type='checkbox'] + label:after,
  input[type='checkbox'] + input[type='hidden'] + label:after {
    @include rem(
      (
        font-size: 20px,
        top: 4px,
        left: -7px,
        margin-top: -2px,
        width: 20px,
        height: 20px,
      )
    );
    content: '';
    color: $brand-dark-grey;
    background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
    background-repeat: no-repeat;
  }

  input[type='radio']:checked + label:after,
  input[type='checkbox']:checked + input[type='hidden'] + label:after {
    @include rem(
      (
        width: 14px,
        height: 14px,
        top: 4px,
        left: -5px,
      )
    );

    background-color: $brand-dark-grey;
  }

  input[type='checkbox']:checked + label:after,
  input[type='checkbox']:checked + input[type='hidden'] + label:after {
    @include transform-translate(0, 0);
    @include rem(
      (
        width: 20px,
        height: 20px,
        top: 4px,
        left: -7px,
      )
    );

    background-color: $brand-dark-grey;
  }

  input[type='checkbox']:checked + label:after,
  input[type='checkbox']:checked + input[type='hidden'] + label:after {
    @include rem(
      (
        top: 3px,
      )
    );
  }

  .link {
    font-size: rem(14px);
    color: $brand-primary;
    display: block;
  }

  p {
    color: $brand-black;
  }
}

.asterix {
  color: $brand-primary-dark;
}

//////////////////////////////////////////////////////////////////////////////// SELECT2 SELECT DROPDOWN STYLES
.select-box {
  @include rem(
    (
      height: 40px,
    )
  );

  border-radius: 0;
  border: none;
  max-width: 60%;
  background-color: $brand-white;
  color: $brand-primary;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        max-width: 100%,
        height: 40px,
      )
    );
  }
}

.select2 {
  width: 100% !important;
}

.select2-container {
  @include rem(
    (
      margin-bottom: 15px,
    )
  );

  font-family: $base-font-family-med;

  .select2-selection--single {
    @include rem(
      (
        height: 40px,
      )
    );
    border-radius: 0;
    border-color: $brand-border;

    &:focus {
      outline: none;
    }

    &.is-focused {
      &:focus {
        outline: $brand-primary-dark 2px solid;
      }
    }
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  @include rem(
    (
      line-height: 40px,
      padding-right: 40px,
    )
  );
  color: $brand-text-heading;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  @include rem(
    (
      height: 40px,
    )
  );
}

.select2-dropdown {
  border-radius: 0;
  border-color: $brand-border;
}

.select2-results__option {
  &:before {
    content: none;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $brand-tertiary;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  @include rem(
    (
      width: 40px,
    )
  );
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  @include transform-translate(-50%, -50%);
  @include rem(
    (
      height: 20px,
      width: 20px,
    )
  );

  background-image: url('/SiteFiles/src/ui/img/svg/chev-down.svg');
  background-repeat: no-repeat;
  border: none;
  margin: 0;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  background-image: url('/SiteFiles/src/ui/img/svg/chev-up.svg');
}

//////////////////////////////////////////////////////////////////////////////// ADVANCED SEARCH FORM ELEMENTS

.advanced-search {
  .select2-container .select2-selection--single {
    border: none;
  }

  input[type='checkbox']:checked + input[type='hidden'] + label:before,
  input[type='checkbox']:checked + input[type='hidden'] + label:after,
  input[type='checkbox'] + label:before,
  input[type='checkbox'] + label:after {
    background-color: $brand-white !important;
    border: none;
  }
}

//////////////////////////////////////////////////////////////////////////////// SLIDER STYLES

.irs {
  @include rem(
    (
      margin: 0 10px 25px,
    )
  );

  @include gridle_state(tablet-min) {
    @include rem(
      (
        margin: 0 0 25px,
      )
    );
  }
}

.irs-grid-pol {
  display: none;
}

.js-irs-1 {
  .irs-line {
    @include rem(
      (
        height: 1px,
        top: 30px,
      )
    );

    @include gridle_state(mobile) {
      @include rem(
        (
          height: 1px,
          top: 30px,
        )
      );
    }
  }
}

.js-irs-0 {
  .irs-line {
    @include rem(
      (
        height: 1px,
        top: 30px,
      )
    );
  }
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
  @include rem(
    (
      height: 1px,
    )
  );

  background: $brand-medium-grey;
}

.irs-slider {
  @include rem(
    (
      width: 15px,
      height: 31px,
      top: 16px,
    )
  );
  background: $brand-primary;
}

.irs-bar,
.irs-bar-edge {
  @include rem(
    (
      height: 8px,
      top: 27px,
    )
  );

  background-color: $brand-black;
}

.grid-column {
  .irs--flat {
    .irs-bar,
    .irs-handle {
      background-color: $brand-black;
    }
  }
}

.grid-column {
  .irs--flat {
    .irs-handle {
      cursor: pointer;
      background-color: $brand-black;

      &:hover {
        background-color: $brand-black;
      }

      i {
        background-color: $brand-black;

        &:hover {
          background-color: $brand-black;
        }
      }
    }
  }
}

.grid-column {
  .irs--flat .irs-handle.state_hover > i:first-child,
  .irs--flat .irs-handle:hover > i:first-child {
    background-color: $brand-black;
  }
}

.irs-grid {
  @include rem(
    (
      bottom: -10px,
    )
  );
}

.irs-grid-text {
  @include rem(
    (
      font-size: 12px,
      line-height: 14px,
    )
  );

  font-family: $base-font-family;
  color: $brand-black;
}

.irs-grid .irs-grid-text:nth-child(2) {
  margin-left: -10px !important;
  text-align: left;
}

.form-centered {
  max-width: 768px;

  .link {
    text-align: right;
  }
}

.form__tooltip {
  @include rem(
    (
      font-size: 14px,
      line-height: 18px,
    )
  );

  background-color: $brand-white;
  border: 1px solid $brand-primary;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  color: $brand-primary;
  text-align: center;
  position: absolute;
  right: -27px;
  top: 0;
  transform: translateY(50%);
  cursor: pointer;
  z-index: 2;

  &[aria-label][data-balloon-pos] {
    position: absolute;
  }

  .register-form & {
    right: 0;
    border: none;
    color: $brand-black;
  }
}

.field-validation-error {
  margin-top: -10px;
  margin-bottom: 15px;
}

.field-container__drop-down .field-validation-valid {
  display: none;
}

.terms-checkbox-holder {
  input[type='checkbox'] + input[type='hidden'] + label {
    padding-left: 20px;
    font-size: 12px;
    margin-bottom: 15px;

    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border: 1px solid $brand-border;

      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0;
    }

    &:after{
      position: absolute;
      font-size: 20px;
      top: 4px;
      left: -7px;
      margin-top: -2px;
      width: 20px;
      height: 20px;
      content: '';
      color: $brand-dark-grey;
      background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
      background-repeat: no-repeat;
      opacity: 0;
    }

    a {
      &:before,
      &:after {
          content: none;
      }
    }
  }

  input[type='checkbox']:checked + input[type='hidden'] + label {
    &:after{
      content: '';            
      font-size: 10px;
      top: 4px;
      left: 2px;
      margin-top: -2px;
      width: 10px;
      height: 10px;            
      color: $brand-dark-grey;
      background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
      background-repeat: no-repeat;
      opacity: 1;
    }
  }
}