.notification-bar {
    background-color: $brand-primary;
    position: relative;
    padding: 5px 50px;
    display: none;

    .notification-active & {
        display: block;
    }
}

.notification-bar__inner {
    text-align: center;
    color: $brand-white;
    font-size: 14px;
    line-height: 18px;

    p,
    a {
        color: $brand-white;
        font-size: 14px;
        line-height: 18px;
    }

    p {
        display: inline;
    }

    a {
        text-decoration: underline;
    }
}

.notification-bar__close-btn {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: transparent;
    padding: 0;
    color: $brand-white;
    border: none;
}