//////////////////////////////////////////////////////////////////////////////// MAIN MENU STYLES
.main-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  @media screen and (min-width: 992px) {
    @include rem(
      (
        padding: 3px 0 0,
        line-height: 17px,
      )
    );
  }

  li {
    vertical-align: middle;
    padding-left: 0;

    @media screen and (min-width: 992px) {
      @include rem(
        (
          padding: 0 10px,
          line-height: 16px,
        )
      );
      display: inline-block;
    }

    &:first-child {
      > a {
        svg {
          display: inline-block;

          @media screen and (max-width: 991px) {
            display: none;
          }
        }
      }
    }

    > a {

      svg {
        width: 16px;
        height: 16px;
        display: none;
        margin-right: 5px;
        vertical-align: top;
      }
    }

    &:before {
      content: none;
    }

    &:hover {
      @media screen and (min-width: 992px) {
        background-color: transparent;
      }
    }

  }

  a {
    @include rem(
      (
        font-size: 18px,
        padding: 12px 20px,
      )
    );

    color: $brand-white;
    transition: color 0.3s;
    display: block;
    font-family: $brand-font;
    text-align: left;

    @media screen and (min-width: 992px) {
      @include rem(
        (
          font-size: 14px,
          line-height: 16px,
        )
      );

      color: $brand-secondary;
      display: inline-block;
      text-align: center;
      padding: 0;
    }

    @media screen and (min-width: 1200px) {
      @include rem(
        (
          font-size: 16px,
          line-height: 18px,
        )
      );
    }

    &:hover {
      text-decoration: none;

      @media screen and (min-width: 992px) {
        text-decoration: underline;
        text-decoration-skip: auto;
      }
    }

    &.is-active {
      text-decoration: underline;
      text-decoration-skip: auto;
    }
  }
}

.header__nav-item {
  &:hover {
    @media screen and (min-width: 992px) {
      .child-nav {
        display: block;
      }
    }
  }

  .child-nav {
    @media screen and (min-width: 992px) {
      padding: 45px 0 0;
    }

    .notification-active & {
      @media screen and (min-width: 992px) {
        padding: 27px 0 0;
      }
    }
  }
  
}

//////////////////////////////////////////////////////////////////////////////// MOBILE MENU

.child-nav {
  display: none;

  @media screen and (min-width: 992px) {
    display: none;
    position: absolute;
    left: 50%;
    z-index: 1;
    text-align: left;
    background-color: transparent;    
    transform: translateX(-50%);
  }

  &__btn {
    position: absolute;
    right: 20px;
    top: 0;
    height: 46px;
    width: 40px;
    border: none;
    border-radius: 0;
    background-color: transparent;

    @media screen and (min-width: 992px) {
      display: none;
    }

    &.is-active {
      svg {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      fill: $brand-white;
      transition: transform 0.3s;
    }
  }

  ul {
    margin-bottom: 20px;
    padding: 10px 20px;      

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
      border-top: 3px solid $brand-primary;
      background-color: $brand-form-ele-bg;  
    }

    li {
      display: block;

      @media screen and (min-width: 992px) {
        padding: 5px 0;
      }
    }
  }

  a {
    font-size: 16px;
    line-height: 21px;
    font-family: $base-font-family;

    @media screen and (min-width: 992px) {
      font-family: $base-font-family-med;
      white-space: nowrap;
      font-size: 14px;
      line-height: 22px;
      color: $brand-medium-grey;
    }

    &:hover {
      @media screen and (min-width: 992px) {
        color: $brand-primary;
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////////// SUB MENU
.header__sub-menu {
  list-style: none;
  margin: 0;
  padding: 5px 0 0;

  @media screen and (max-width: 991px) {
    display: none;
  }

  li {
    @media screen and (min-width: 992px) {
      @include rem(
        (
          padding: 0 10px,
        )
      );
      display: inline-block;
    }

    &:before {
      content: none;
    }

    &:hover {

      @media screen and (min-width: 992px) {
        background-color: transparent;
      }
    }
  }

  a {
    @include rem(
      (
        font-size: 18px,
        padding: 12px 10px,
      )
    );

    color: $brand-medium-grey;
    display: block;

    @media screen and (max-width: 991px) {
      color: $brand-white;
    }

    @media screen and (min-width: 992px) {
      @include rem(
        (
          font-size: 14px,
        )
      );

      text-transform: none;
      display: inline-block;
      padding: 0;
    }

    &:hover {
      text-decoration: none;

      @media screen and (min-width: 992px) {
        text-decoration: underline;
        text-decoration-skip: auto;
      }
    }

    &.is-active {
      text-decoration: underline;
      text-decoration-skip: auto;
    }
  }
}
