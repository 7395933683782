.gallery-carousel {
  position: relative;

  .tns-outer {
    position: relative;
  }

  .tns-controls {
    button {
      background-color: transparent;
      border: none;

      &:first-child {
        position: absolute;
        top: 30%;
        right: 90%;
        z-index: 1;

        @include gridle_state(tablet-min) {
          top: 40%;
        }
      }

      &:last-child {
        position: absolute;
        top: 30%;
        left: 90%;
        z-index: 1;

        @include gridle_state(tablet-min) {
          top: 40%;
        }
      }

      svg {
        fill: $brand-white;
      }
    }
  }
}

.gallery-carousel__page {
  background-color: $brand-white;
  font-size: 14px;
  color: rgba($brand-text, 0.5);
  position: absolute;
  right: 0;

  @include rem(
    (
      top: 80px,
      width: 40px,
      height: 40px,
    )
  );

  @include gridle_state(tablet-min) {
    @include rem(
      (
        top: 100px,
        width: 50px,
        height: 50px,
      )
    );
  }
}

.gallery-carousel__page-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-carousel--small {
  .gallery-carousel__image {
    @include rem(
      (
        height: 200px,
        margin-bottom: 20px,
      )
    );

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;

    @include gridle_state(tablet-min) {
      @include rem(
        (
          height: 300px,
        )
      );
    }

    @include gridle_state(small-desktop) {
      @include rem(
        (
          height: 560px,
        )
      );
    }
  }
}

.gallery-carousel--resale {
  @include gridle_state(small-desktop) {
    height: calc(100vh - 162px);
  }

  .gallery-carousel__image,
  picture {
    img {
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';

      @include gridle_state($mobile-lg) {
        max-width: 100%;
        width: 100%;
      }
      @include rem(
        (
          height: 267px,
        )
      );

      @include gridle_state(small-desktop) {
        height: calc(100vh - 150px);
      }
    }
  }
}

.gallery-carousel__image {
  @include rem(
    (
      height: 200px,
      margin-bottom: 20px,
    )
  );

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        height: 400px,
      )
    );
  }

  @include gridle_state(small-desktop) {
    @include rem(
      (
        height: 550px,
      )
    );
  }
}

.gallery-carousel__description {
  text-align: center;
  margin: 0 auto 25px;

  p {
    margin: 0;
  }

  @include gridle_state(tablet-min) {
    width: 80%;
  }

  // @include gridle_state ( small-desktop ) {
  //   width: 50%;
  // }

  @include gridle_state(desktop) {
    width: 50%;
  }
}

//////////////////////////////////////////////////////////////////////////////// GALLERY CAROUSEL NAVIGATION

.gallery-carsousel-nav {
  @include rem(
    (
      padding: 0 30px,
      margin-bottom: 30px,
    )
  );

  display: flex;
  overflow-x: auto;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        padding: 0 40px,
      )
    );
  }

  @include gridle_state(small-desktop) {
    @include rem(
      (
        padding: 0 100px,
      )
    );
  }

  &.gallery-carousel-nav--small {
    @include gridle_state(small-desktop) {
      @include rem(
        (
          padding: 0 50px,
        )
      );
    }

    @include gridle_state(desktop) {
      @include rem(
        (
          padding: 0 80px,
        )
      );
    }
  }
}

.gallery-carousel-nav__item {
  @include rem(
    (
      padding: 0 5px,
    )
  );

  @include gridle_state(tablet-min) {
    @include rem(
      (
        padding: 0 20px,
      )
    );
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.gallery-carousel-nav__image {
  @include netc-transition(0.5s, ease, filter);
  @include rem(
    (
      height: 55px,
      width: 100px,
    )
  );

  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  cursor: pointer;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        height: 90px,
        width: 165px,
      )
    );
  }

  .tns-nav-active &,
  &:hover {
    filter: grayscale(0);
  }
}
