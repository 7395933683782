.advanced-search {
  @include rem(
    (
      margin-top: 0px,
    )
  );

  background-color: rgba($brand-light-grey, 0.7);

  // @include gridle_state ( tablet-min ) {
  //   @include rem((
  //     margin-top: 0,
  //   ));
  // }

  // @include gridle_state ( small-desktop ) {
  //   @include rem((
  //     margin-top: 0,
  //   ));
  // }

  .form {
    label,
    .fake-label {
      @include rem(
        (
          font-size: 14px,
        )
      );

      font-weight: 400;
    }

    label {
      @include rem(
        (
          font-size: 16px,
          padding-top: 30px,
          padding-bottom: 10px,
        )
      );
      display: inline-block;

      @include gridle_state(tablet-min) {
        @include rem(
          (
            padding-top: 50px,
          )
        );
      }
    }
  }

  .field-container__group {
    label {
      @include rem(
        (
          font-size: 14px,
          margin-right: 15px,
          padding-left: 30px,
          padding-top: 5px,
        )
      );
      font-style: normal;
      padding-bottom: 0;
    }

    input {
      & + label {
        &::before {
          content: '';
          top: rem(4.25px);
          height: rem(20px);
          width: rem(20px);
        }
      }
      &[type='checkbox']:checked {
        & + label {
          &::before {
            background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}

.advanced-search__grid {
  @include rem(
    (
      padding-bottom: 20px,
    )
  );

  @include gridle_state(tablet-min) {
    margin: 0 auto;
    width: 100%;
    min-height: 70vh;
  }

  @include gridle_state(desktop) {
    width: 80%;
  }

  .field-container__group {
    width: 49%;
  }

  .advanced-search--rehash & {
    padding: 40px;

    @include gridle_state(tablet-min) {
      margin: 0 auto;
      width: 100%;
      min-height: 70vh;
      padding: 50px 100px;
    }

    @include gridle_state(desktop) {
      width: 100%;
    }

    .field-container__group {
      width: 100%;
      flex: 1 0 100%;

      @include gridle_state(tablet-min) {
        width: 49%;
        flex: 1 0 49%;
      }
    }

    .MuiSlider-root {
      color: $development-cyan;
    }
  }
}

.advanced-search__submit {
  @include rem(
    (
      padding-top: 50px,
    )
  );

  text-align: center;

  input[type='submit'] {
    background-size: 26px 26px;
    font-family: $brand-font;
  }
}
