/**
 * This example feature a hierarchy of titles
 *
 *
 * @section Headings
 * @example
 * <h1>h1 Lorem ipsum</h1>
 * <h2>h2 Lorem ipsum</h2>
 * <h3>h3 Lorem ipsum</h3>
 * <h4>h4 Lorem ipsum</h4>
 * <h5>h5 Lorem ipsum</h5>
 * <h6>h6 Lorem ipsum</h6>
 */


/**
 * Lists
 *
 *
 * @section Lists
 * @example
 *
 * <ul class="list">
 *  <li>Consectetur adipiscing elit</li>
 *  <li>Integer molestie lorem at massa</li>
 *  <li>Facilisis in pretium nisl aliquet</li>
 *  <li>Nulla volutpat aliquam velit
 *    <ul>
 *      <li>Phasellus iaculis neque</li>
 *      <li>Purus sodales ultricies</li>
 *    	 <li>Vestibulum laoreet porttitor</li>
 *    	 <li>Ac tristique libero volutpat</li>
 *    </ul>
 *  </li>
 *  <li>Faucibus porta lacus fringilla vel</li>
 *  <li>Aenean sit amet erat nunc</li>
 *  <li>Eget porttitor lorem</li>
 * </ul>
 */


/**
 * Lead text
 *
 *
 * @section Lead text
 * @example
 *
 * <p class="lead-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, libero.</p>
 */

$netc-core-lead-text-namespace: 'lead-text' !default;
$netc-core-lead-text-font-size: 18px !default;
$netc-core-lead-text-color: #333333 !default;

.#{$netc-core-lead-text-namespace} {
  @include rem((
		font-size: $netc-core-lead-text-font-size,
	));
	color: $netc-core-lead-text-color;
}


/**
 * Body text
 *
 *
 * @section body text
 * @example
 *
 * <p>Lorem ipsum dolor <strong>sit amet</strong>, consectetur <a href="#" title="adipisicing">adipisicing</a> elit. Iure, libero.</p>
 */

$netc-core-link-color: #0066ff !default;

a {
	color: $netc-core-link-color;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}


/**
 * Annotations
 *
 *
 * @section Annotations
 * @example
 *
 * <p class="small-text">Lorem ipsum dolor <strong>sit amet</strong>, consectetur <a href="#" title="adipisicing">adipisicing</a> elit. Iure, libero.* </p>
 */

$netc-core-annotation-spacename: 'small-text' !default;
$netc-core-annotation-font-size: 12px !default;

.#{$netc-core-annotation-spacename} {
	@include rem((
		font-size: $netc-core-annotation-font-size,
	));
}


/**
 * Blockquotes
 *
 *
 * @section Blockquotes
 * @example
 *
 *
 * <blockquote><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem cupiditate natus et aperiam aspernatur voluptas nisi impedit, hic quo dolorum tempore similique dicta at, quibusdam doloremque placeat obcaecati laudantium temporibus.</p>
 * <p class="cite">— quibusdam doloremque placeat obcaecati laudantium temporibus.</p>
 * </blockquote>
 *
 *
 */

$netc-core-blockquote-spacename: 'blockquote' !default;
$netc-core-blockquote-padding: 10px 20px !default;
$netc-core-blockquote-font-size: 16px !default;
$netc-core-blockquote-cite-font-size: 12px !default;
$netc-core-blockquote-border-color: #eeeeee !default;
$netc-core-blockquote-sub-text-color: #999999 !default;


.#{$netc-core-blockquote-spacename},
blockquote {
	@include rem((
		padding: $netc-core-blockquote-padding,
	));
	border-left: 4px solid $netc-core-blockquote-border-color;
	font-style: italic;
	font-weight: bold;

	p {
		@include rem((
			font-size: $netc-core-blockquote-font-size,
			line-height: 20px,
		));
	}

	.cite {
		@include rem((
			font-size: $netc-core-blockquote-cite-font-size,
		));
		color: $netc-core-blockquote-sub-text-color;
		font-style: normal;
		margin-bottom: 0;
	}
}
