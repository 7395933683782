.property-search {
    background-color: $brand-form-ele-bg;
    padding: 40px 0;
    position: relative;
    margin: 30px 0 60px;

    @media screen and (max-width: 767px) {
        padding: 35px 0;
    }
}

.grid-column {
    > div > .property-search {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            height: 100%;
            width: 20px;
            background-color: $brand-form-ele-bg;

            @media screen and (min-width:1600px) {
                content: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 20px;
            background-color: $brand-form-ele-bg;

            @media screen and (min-width:1600px) {
                content: none;
            }
        }

        @media screen and (min-width:1600px) {
            width: 100vw;
            margin-left: calc((1540px - 100vw) / 2);
        }
    }
}

.property-search__bg-icon {
    color: $brand-primary;
    opacity: 0.1;
    position: absolute;
    height: 300px;
    left: 0;
    top: 0;
    z-index: 1;
    transform: translateX(-55%);

    @media screen and (max-width: 767px) {
        top: auto;
        bottom: 0;
        height: 280px;
        transform: translateX(-65%);
    }

    &--right {
        left: auto;
        right: 0;
        transform: translateX(55%);

        @media screen and (max-width: 767px) {
            transform: translateX(65%);
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.property-search__content-holder {
    text-align: center;
    max-width: 700px;
    margin: auto;
    position: relative;
    z-index: 2;

    .property-search__title {
        font-family: $brand-font;
        color: $brand-primary;
        font-size: 28px;
        line-height: 37px;
        margin-bottom: 40px;
    
        @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 20px;
        }
    }
}

.property-search__text {
    font-family: $base-font-family;    
    font-size: 16px;
    line-height: 28px;
    color: $brand-dark-grey;
    margin-bottom: 25px;
}