.developments-carousel {
  &__container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 40px;
  }

  &__cards {
    position: relative;
    padding-top: 20px;
    max-width: 1200px;
    width: 100%;

    .tns-controls {
      display: flex;
      justify-content: space-between;

      button {
        background-color: transparent;
        border: none;

        svg {
          fill: $brand-black;
        }
      }
    }
  }

  &__card {
    flex-direction: column;
    margin: 30px auto;
    max-width: 370px;
    min-width: 0;

    &.development-theme-2 {
      .developments-carousel__card {
        background-color: $development-orange;

        .btn.btn--new {
          color: $development-orange;
          &:hover {
            color: $brand-black;
          }
        }
      }
    }

    &.development-theme-3 {
      .developments-carousel__card {
        background-color: $development-blue;

        .btn.btn--new {
          color: $development-blue;

          &:hover {
            color: $brand-black;
          }
        }
      }
    }

    &-content {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 20px 40px;
      background-color: $development-cyan;
    }

    &-image {
      max-width: 380px;
      max-height: 250px;
      overflow: hidden;
      img {
        max-width: 380px;
      }
    }

    &-logo {
      padding-bottom: 10px;
      flex: 1 0 auto;
      align-items: center;
      display: flex;
      img {
        max-width: 192px;
      }
    }

    &-address {
      color: $brand-white;
      padding-bottom: 20px;
    }

    .btn {
      display: inline-flex;
      background-color: $brand-white;
      border: 0;

      &.btn--new {
        color: $development-cyan;

        &:hover {
          color: $brand-black;
        }
      }
    }
  }
}

.developments-carousel__progress-bar {
  position: relative;
  margin: 0 auto 30px;
  height: 4px;
  max-width: 792px;
}

.developments-carousel__progress-bar-bottom {
  display: block;
  width: 100%;
  height: 2px;
  padding: 1px 0;
  background-color: $brand-border;
  transform: translateY(1px);
}

.developments-carousel__progress-bar-top {
  display: block;
  width: 100%;
  height: 4px;
  background-color: $brand-primary;
  transform: scaleX(0);
  transition: transform 0.3s;
  transform-origin: 0;
  position: absolute;
  top: 0;
  left: 0;
}
