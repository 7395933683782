.development-cta {
  background-color: rgba($brand-text-heading, 0.1);
  color: $brand-black;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 30px;
    text-align: center;
  }

  &__subtitle {
    font-family: $brand-font;
    font-size: 12px;
    line-height: 34px;
  }

  &__title {
    font-family: $base-font-family-med;
    font-size: 40px;
    line-height: 53px;
    margin-bottom: 15px;

    @include gridle_state(mobile) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }

  &__content {
    max-width: 586px;
    width: 100%;
    font-family: $brand-font;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }

  &__link-area {
    margin-top: 20px;

    .btn,
    .btn.btn--new {
      display: inline-block;
      text-align: center;
      margin: auto;
    }
  }
}

.cta-image {
  &.development-theme-1,
  .development-theme-1 & {
    .cta-image__text {
      background-color: $development-cyan;

      .btn {
        color: $development-cyan;

        &.btn--block.btn--block {
          &:hover {
            color: $brand-dark-grey;

            svg {
              stroke: $brand-dark-grey;
              fill: $brand-dark-grey;
            }
          }
        }

        svg {
          stroke: $development-cyan;
        }
      }
    }
  }

  &.development-theme-2,
  .development-theme-2 & {
    .cta-image__text {
      background-color: $development-blue;

      .btn {
        color: $development-blue;

        &.btn--block.btn--block {
          &:hover {
            color: $brand-dark-grey;

            svg {
              stroke: $brand-dark-grey;
              fill: $brand-dark-grey;
            }
          }
        }

        svg {
          stroke: $development-blue;
        }
      }
    }
  }

  &.development-theme-3,
  .development-theme-3 & {
    .cta-image__text {
      background-color: $blog-orange;

      .btn {
        color: $blog-orange;

        &.btn--block.btn--block {
          &:hover {
            color: $brand-dark-grey;

            svg {
              stroke: $brand-dark-grey;
              fill: $brand-dark-grey;
            }
          }
        }

        svg {
          stroke: $blog-orange;
        }
      }
    }
  }

  width: 100%;
  height: auto;
  padding: 1px 0;
  position: relative;

  @include gridle_state(tablet-min) {
    height: 617px;
    padding: 0;
  }

  &.cta-page-banner {
    @include gridle_state(mobile) {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    position: absolute;
    z-index: 1;

    @include gridle_state(mobile) {
      position: relative;
      aspect-ratio: 375 / 282;
    }
  }

  .site-wide {
    display: flex;
    justify-content: flex-end;
    z-index: 2;
    position: relative;

    @include gridle_state(mobile) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__text {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 60px;
    background-color: $development-cyan;
    padding: 26px;
    padding-top: 53px;

    @include gridle_state(mobile) {
      margin-top: 0;
      padding: 16px;
      margin-bottom: 0;
    }

    @include gridle_state(tablet-min) {
      margin-top: 117px;
      width: 586px;
      padding: 36px;
    }

    .tag,
    .title,
    p {
      color: $brand-white;
      margin-bottom: 12px;
    }

    p {
      &.restrict {
        @include gridle_state(mobile) {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.show-more {
        @include gridle_state(mobile) {
          display: block;
        }
      }
    }

    .tag {
      font-size: 12px;
      font-family: $brand-font;
    }

    .title {
      font-family: $base-font-family-med;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 17px;

      @include gridle_state(mobile) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
      }
    }

    .btn {
      display: inline-flex;
      background-color: $brand-white;
      color: $development-cyan;
      border: 0;

      svg {
        stroke: $development-cyan;
      }
    }
  }
  &__read-more-btn {
    display: none;
    font-family: $base-font-family;
    color: $brand-white;
    font-size: 16px;
    line-height: 24px;
    background-color: transparent;
    border: none;
    padding: 0;
    text-decoration: underline;

    &.hidden {
      display: none;
    }

    @include gridle_state(mobile) {
      display: block;
      margin: 0 0 10px auto;
      text-align: right;
    }
  }
}
