.development-section {
  &__tabbed-content {
    padding-bottom: 40px;

    @include gridle_state(mobile) {
      padding-bottom: 20px;
    }
  }

  &__tab-container {
    padding-top: 50px;
    border-bottom: 1px solid rgba($brand-dark-grey, 0.25);
  }

  &__tabs {
    display: block;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    @include gridle_state(tablet-min) {
      display: flex;
    }
  }

  &__tab {
    font-size: 18px;
    color: $brand-dark-grey;
    padding-bottom: 12px;

    margin: 0 25px 0px;
    cursor: pointer;
    position: relative;
    display: inline-block;

    @include gridle_state(tablet-min) {
      display: block;
    }

    &:before {
      content: '';
      width: 0%;
      transition: width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }

    &::after {
      content: '';
      width: 0%;
      transition: width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }

    &.active {
      &::after {
        content: '';
        position: absolute;
        width: 50%;
        height: 2px;
        background-color: $development-cyan;
        bottom: 0;
        left: 0;
      }
      &::before {
        content: '';
        position: absolute;
        width: 50%;
        height: 2px;
        background-color: $development-cyan;
        bottom: 0;
        right: 0;
      }
    }
  }
  &__item.js-tab-content {
    opacity: 0;
    height: 0;
    visibility: hidden;
    max-width: 1200px;
    margin: auto;

    &.active {
      padding: 30px 0;
      opacity: 1;
      height: 100%;
      visibility: visible;
      &.no-title {
        padding-top: 70px;
      }
    }

    &-container {
      display: flex;
    }
  }
}
