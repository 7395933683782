.cta-signpost {
    background-color: $brand-primary;
    
    text-align: center;
    padding: 45px 30px;

    @include gridle_state ( tablet-min ) {
        padding: 80px 30px;
    }

    &.cta-signpost--alt {
        background-color: $brand-light-grey;

        .btn {
            background-color: $brand-primary;
            color: $brand-white;
            outline-color: $brand-primary;

            &:hover {
                background-color: $brand-white;
                color: $brand-primary;
            }
        }
    }
}

.cta-signpost__inner {
    max-width: 800px;
    margin: auto;
}

.cta-signpost__title {
    font-family: $base-font-family-med;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    color: $brand-white;

    @include gridle_state ( tablet-min ) {
        font-size: 32px;
        line-height: 43px;
    }

    .cta-signpost--alt & {
        color: $brand-primary;
    }
}

.cta-signpost__content {
    font-family: $base-font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: $brand-white;

    @include gridle_state ( tablet-min ) {
        font-size: 16px;
        line-height: 28x;
    }

    .cta-signpost--alt & {
        color: $brand-text;

        p {
            color: $brand-text;
        }
    }

    p {
        font-family: $base-font-family;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        color: $brand-white;
    
        @include gridle_state ( tablet-min ) {
            font-size: 16px;
            line-height: 28x;
        }
    }
}

.cta-signpost__base {
    margin-top: 50px;

    @include gridle_state ( tablet-min ) {
        margin-top: 20px;
    }
}