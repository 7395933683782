.banner__holder {
  position: relative;
  border-bottom: 15px solid $brand-white;

  &.blog {
    background-color: $brand-dark-grey;
  }

  .banner__bg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgb(24, 24, 24);
    background: linear-gradient(0deg, #141414ba 0%, #1d1d1d9c 39%, #2f2f2f5e 48%, rgba(255, 255, 255, 0%) 64%)
  }

  @include gridle_state(mobile) {
    .banner__bg {
      display: flex;
    }
  }

  picture {
    img {
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
      @include gridle_state($mobile-lg) {
        max-width: 100%;
        max-height: 550px;
        width: 100%;
      }
    }
  }
}

.banner__image {
  height: rem(320px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  @include gridle_state(mobile) {
    .blog & {
      height: rem(480px);
    }
  }

  @include gridle_state(tablet-min) {
    height: rem(550px);
    width: 100%;

    .blog & {
      height: rem(320px);
    }
  }

  &::before {
    content: '';
    background: $brand-text-heading;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    position: absolute;
    z-index: 5;

    .blog & {
      background-color: #00000099;
      opacity: 1;
    }
  }
}

.banner__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;

  .blog & {
    text-align: center;
    width: 90%;
    p,
    h3 {
      color: $brand-white;
      margin-bottom: 0;
    }

    @include gridle_state(mobile) {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: rem(32px);

      p {
        font-size: rem(16px);
      }
    }
  }

  .banner__category {
    display: flex;
    justify-content: center;
    align-items: center;
    div.svg-icon {
      margin-right: rem(5px);
    }
  }
}

.banner__title {
  font-size: rem(30px);
  line-height: rem(36px);
  text-transform: uppercase;
  color: $brand-white;
  text-align: center;

  @include gridle_state(tablet-min) {
    font-size: rem(80px);
    line-height: rem(90px);
  }

  .blog & {
    font-size: rem(40px);
    text-transform: none;
    line-height: rem(60px);

    @include gridle_state(mobile) {
      font-size: rem(24px);
      line-height: rem(32px);
      margin: rem(16px 0px);
      padding: rem(0px 16px);
    }
  }
}

.banner__border {
  position: absolute;
  bottom: 0;
  z-index: 6;
  display: flex;
  width: 100%;
}

.banner__border-block {
  flex: 1 0 auto;
  height: rem(10px);
}
