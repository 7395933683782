.header__micro-header {
  @include rem(
    (
      padding: 10px 0,
    )
  );

  font-family: $base-font-family;
  background-color: $brand-secondary;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        padding: 15px 0,
      )
    );
  }

  // @include gridle_state ( desktop ) {
  //   @include rem((
  //     padding: 30px 0,
  //   ));
  // }
}

.header__micro-header-area {
  @include rem(
    (
      margin-left: -20px,
      margin-right: -20px,
    )
  );

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        margin-left: 15px,
        margin-right: 15px,
      )
    );
  }

  @include gridle_state(small-desktop) {
    margin: 0;
  }

  // @include gridle_state ( desktop ) {
  //   flex-direction: row;
  //   justify-content: center;
  //   position: relative;
  // }
}

.header__micro-header-title {
  @include rem(
    (
      font-size: 18px,
      padding: 0 70px 0 20px,
    )
  );

  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        margin-bottom: 10px,
      )
    );

    padding: 0;
    cursor: default;
    background: none;
  }

  .chev-holder {
    transform: scaleY(1) translateY(0);
    @include rem((
      width: 25px,
      height: 25px,
    ));
    display:inline-block;
    position: absolute;
    right: 30px;
    top: 6px;

    @include gridle_state(tablet-min) {
      display: none;
    }
  }

  p {
    @include rem(
      (
        font-size: 18px,
      )
    );

    display: inline-block;
    margin: 0;
  }

  a {
    color: $brand-white;
    line-height: 2;

    &:hover {
      color: $brand-grey;
    }
  }

  &.is-active {
    .chev-holder {
      transform: scaleY(-1) translateY(0);
    }
  }
}

.header__microsite-menu {
  @include rem(
    (
      padding: 20px 0 0,
    )
  );

  list-style: none;
  margin: 0;
  display: none;

  @include gridle_state(tablet-min) {
    padding: 0;
    text-align: left;
    display: block;
    // flex: 0 0 75%;
  }

  // @include gridle_state ( desktop ) {
  //   text-align: center;
  // }

  &.is-active {
    display: block;
  }

  li {
    @include rem(
      (
        padding: 1px 0,
      )
    );
    display: block;

    @include gridle_state(tablet-min) {
      @include rem(
        (
          padding: 0 12px 0 0,
        )
      );

      display: inline-block;
    }

    // @include gridle_state ( desktop ) {
    //   @include rem((
    //     padding: 0 6px,
    //   ));
    // }

    &:before {
      content: none;
    }

    &:hover {
      background-color: $brand-white;

      @include gridle_state(tablet-min) {
        background-color: transparent;
      }
    }
  }

  a {
    @include rem(
      (
        font-size: 16px,
        padding: 5px 20px,
      )
    );

    display: block;
    color: $brand-white;

    @include gridle_state(tablet-min) {
      display: inline-block;
      padding: 0;
    }

    &:hover {
      text-decoration: none;
      color: $brand-grey;

      @include gridle_state(tablet-min) {
        text-decoration: underline;
        text-decoration-skip: auto;
      }
    }
  }

  .sub-nav {
    position: relative;

    &.is-active {
      background-color: $brand-white;

      @include gridle_state(tablet-min) {
        background-color: $brand-secondary;
      }

      .downloads {
        display: block;
        background-color: $brand-white;

        @include gridle_state(tablet-min) {
          background-color: $brand-secondary;
        }
      }
    }
  }

  .downloads {
    display: none;
    padding: 0;
    background-color: $brand-white;

    @include gridle_state(tablet-min) {
      @include transform-translate(-50%, 0);
      @include rem(
        (
          padding: 25px 10px 10px,
        )
      );

      position: absolute;
      left: 50%;
      // display: block;
      white-space: nowrap;
      top: 100%;
      background-color: $brand-secondary;
    }

    @include gridle_state(small-desktop) {
      @include rem(
        (
          padding: 35px 10px 10px,
        )
      );
    }

    li {
      padding: 0;
      display: block;
    }

    a {
      @include rem(
        (
          padding-left: 30px,
        )
      );

      @include gridle_state(tablet-min) {
        @include rem(
          (
            padding: 10px 20px,
          )
        );
      }
    }
  }
}
