.tabbed-content-features {
  .grid-container-special {
    @media screen and (min-width: $new-laptop-s) {
      display: flex;
      flex-wrap: wrap;

      @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 0;
      }
    }
  }

  &__item-title {
    display: flex;
    align-items: center;
    font-family: $base-font-family;
    font-size: 22px;
    color: $brand-black;
    padding-top: 20px;
    padding-right: 42px;
    padding-bottom: 20px;
    padding-left: 27px;
    margin-top: 16px;

    svg {
      min-width: 24px;
      stroke: $brand-black;
      margin-right: 20px;

      &.icon-chevron-down {
        width: 15px;
        min-width: 15px;
        margin-right: 0;
        margin-left: auto;
        transition: transform 0.3s ease-in-out;
        transform: rotate(90deg);

        @media screen and (min-width: $new-laptop-s) {
          display: none;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.is-active {
      color: $brand-black;

      svg {
        stroke: $brand-black;
      }
    }

    @media screen and (max-width: $new-laptop-s) {
      &.is-active {
        .icon-chevron-down {
          transform: rotate(-90deg);
        }

        + .tabbed-content-features__item-contents {
          max-height: 1000px;
        }
      }
    }

    @media screen and (min-width: $new-laptop-s) {
      margin: 0 50px;
      flex: 0 0 20%;

      &.is-active {
        font-family: $brand-font;

        + .tabbed-content-features__item-contents {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          max-height: 1000px;

          @supports (display: grid) {
            max-height: none;
          }
        }
      }

      &:hover {
        font-family: $brand-font;
      }
    }
  }

  &__item-contents {
    display: flex;
    grid-area: 1 / 2 / 9 / 4;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    @media screen and (max-width: $new-tablet) {
      flex-direction: column;
    }

    @media screen and (max-width: $new-laptop-s) {
      overflow: hidden;
      max-height: 0;
      transform-origin: center top;
      transition: max-height 0.3s ease-in-out;
    }

    @media screen and (min-width: $new-laptop-s) {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      flex: 1 0 55%;
      max-height: 0;

      @supports (display: grid) {
        max-height: none;
      }
    }
  }

  &__item-text {
    padding-top: 24px;
    padding-right: 25px;
    padding-bottom: 24px;
    padding-left: 25px;

    > p,
    > ol,
    > ul {
      margin-top: 0;
    }

    @media screen and (min-width: $new-laptop-m) {
      padding-top: 20px;
      padding-right: 25px;
      padding-bottom: 45px;
      padding-left: 25px;
    }

    h2 {
      font-family: $base-font-family-med;
      font-size: 26px;
      color: $brand-black;
      margin-bottom: 27px;
    }

    p,
    ul,
    ol,
    li {
      color: $brand-black;
      font-family: $base-font-family;
      font-size: 16px;
      line-height: 24px;
    }

    p,
    ul,
    ol {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      display: inline-flex;
      background-color: $brand-white;
    }
  }

  &__item-image {
    position: relative;
    padding-top: 20px;

    @media screen and (min-width: $new-tablet) {
      flex: 1 0 310px;
      max-width: 310px;
    }
  }

  &__image {
    width: 100%;

    @media screen and (max-width: $new-tablet) {
      margin-bottom: 15px;
    }
  }
}
