.featured-card__flex-layout {
  @include rem(
    (
      padding-top: 20px,
    )
  );
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    @include rem(
      (
        margin-bottom: 5px,
        line-height: 22px,
        font-size: 16px,
      )
    );
  }
}

.featured-card__top-left {
  @include rem(
    (
      margin-bottom: 10px,
    )
  );

  @include gridle_state(tablet-min) {
    margin-bottom: rem(40px);
  }
}

.featured-card__top-right {
  @include rem(
    (
      font-size: 16px,
      line-height: 18px,
      margin-bottom: 15px,
    )
  );

  @include gridle_state(tablet-min) {
    @include rem(
      (
        margin-bottom: 60px,
      )
    );

    text-align: right;
  }
}
.logoList {
  @include rem(
    (
      height: 150px,
      margin-right: 18%,
    )
  );
}
.doubleLogoList {
  @include rem(
    (
      height: 150px,
      margin-right: 1%,
    )
  );
}
.featured-card__bottom-right {
  @include gridle_state(tablet-min) {
    // @include rem((
    //   margin-bottom: 60px,
    // ));

    text-align: right;
  }

  .btn {
    &:last-child {
      @include gridle_state(tablet-min) {
        margin-bottom: 0;
      }
    }
  }
}

.featured-card__contact,
p.featured-card__contact {
  @include rem(
    (
      font-size: 24px,
      margin-bottom: 10px,
    )
  );

  @include gridle_state(tablet-min) {
    margin-bottom: 5px;
  }
}

.featured-card__detail-holder {
  flex: 1 0 auto;
}

.featured-card__detail {
  @include rem(
    (
      margin-bottom: 10px,
      font-size: 16px,
      line-height: 20px,
      padding: 10px 0 10px 50px,
    )
  );

  position: relative;

  @include gridle_state(tablet-min) {
    @include rem(
      (
        margin-bottom: 15px,
      )
    );
  }

  .icon {
    @include transform-translate(0, -50%);
    @include rem(
      (
        width: 36px,
        height: 36px,
      )
    );

    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    fill: $brand-primary;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.featured-card__title,
h2.featured-card__title,
.tab-nav__content-area h2.featured-card__title {
  @include rem(
    (
      margin-bottom: 5px,
    )
  );
  color: $brand-primary;

  &:hover {
    text-decoration-skip: auto;
  }
}

.featured-card {
  .upcoming-overlay {
    float: left;
    background-color: $brand-medium-grey;
    color: $brand-white;
    position: absolute;
    padding: 15px;
    z-index: 6;

    .icon {
      @include rem(
        (
          height: 20px,
          width: 20px,
          margin-left: 8px,
          margin-top: -3px,
        )
      );

      fill: $brand-white;
      vertical-align: middle;
    }
  }
}

.grid-column.featured-devs {
  padding-top: rem(40px);
}
