// configuration
$netc-core-table-namespace: 'table' !default;
$netc-core-table-strip-modifier: 'striped' !default;
$netc-core-table-header-font-size: 14px !default;
$netc-core-table-body-size: 14px !default;
$netc-core-table-header-padding: 8px !default;
$netc-core-table-body-padding: 5px 10px !default;
$netc-core-table-header-row-border: 2px solid #dfdfdf !default;
$netc-core-table-header-color: $brand-dark-grey !default;
$netc-core-table-body-color: $brand-dark-grey !default;
$netc-core-table-row-border: 1px solid #dfdfdf !default;
$netc-core-table-row-background: #f9f9f9 !default;
$netc-core-table-margin: 16px !default;

// default styles
.#{$netc-core-table-namespace} {
  @include rem((
    margin: $netc-core-table-margin,
  ));
  width: 100%;
  color: $netc-core-table-body-color;
  border-spacing: 20px 0;

  th {
    color: $netc-core-table-header-color;
  }

  // th,
  // td {
  //   @include rem((
  //     padding-right: 12px,
  //   ));
  // }

  thead {
    th {
      @include rem((
        font-size: $netc-core-table-header-font-size,
        padding: $netc-core-table-header-padding,
      ));
      text-align: left;
      white-space: nowrap;
      line-height: normal;
    }

    tr {
      border-bottom: $netc-core-table-header-row-border;
    }
  }

  tbody {
    td {
      @include rem((
        font-size: $netc-core-table-body-size,
        line-height: 20px,
        padding: $netc-core-table-body-padding,
      ));
      vertical-align: top;
    }

    tr {
      border-bottom: $netc-core-table-row-border;
    }
  }

  // striped style
  &--#{$netc-core-table-strip-modifier} {
    tbody {
      tr:nth-child(odd) {
        background-color: $netc-core-table-row-background;
      }
    }
  }
}
