.development-section {
  &__title-container {
    padding-top: 70px;

    @include gridle_state(mobile) {
      padding: 50px 25px 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__title {
    font-family: $base-font-family-med;
    font-size: 40px;
    line-height: 53px;
    color: $brand-dark-grey;

    @include gridle_state(mobile) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__content {
    padding-top: 10px;
    font-size: 18px;
    line-height: 28px;
    max-width: 650px;
  }
}
