// configuration
$netc-core-pagination-namespace: 'pagination' !default;
$netc-core-pagination-current: 'is-current' !default;
$netc-core-pagination-text-color: #4589ca !default;
$netc-core-pagination-border-color: #b7b7b7 !default;
$netc-core-pagination-border-separator-color: #dddddd !default;
$netc-core-pagination-current-background-color: #4589ca !default;
$netc-core-pagination-current-text-color: #fff !default;
$netc-core-pagination-hover-background-color: #eeeeee !default;
$netc-core-pagination-hover-text-color: #2d6297 !default;
$netc-core-pagination-item-width: 34px !default;
$netc-core-pagination-item-height: 32px !default;
$netc-core-pagination-font-size: 14px !default;
$netc-core-pagination-line-height: 30px !default;
$netc-core-pagination-margin: 0 0 16px 0 !default;
$netc-core-pagination-padding: 0 8px !default;
$netc-core-pagination-transition: true !default;
$netc-core-pagination-transition-effect: 'all 0.3s ease-in-out' !default;


// default styles
.#{$netc-core-pagination-namespace} {
	@include rem((
		margin: $netc-core-pagination-margin,
	));
  @include netc-reset-list;
	display: inline-block;
  font-size: 0;
  border: 1px solid $netc-core-pagination-border-color;

	li {
		@include rem((
			font-size: $netc-core-pagination-font-size,
		));
		display: inline-block;
		vertical-align: middle;
		text-align: center;
	}

	a {
		@include rem((
			min-width: $netc-core-pagination-item-width,
			min-height: $netc-core-pagination-item-height,
			font-size: $netc-core-pagination-font-size,
			line-height: $netc-core-pagination-line-height,
			padding: $netc-core-pagination-padding,
		));
	  display: inline-block;
    vertical-align: middle;

		@if ($netc-core-pagination-transition == true) {
			transition: unquote($netc-core-pagination-transition-effect);
		}

		&:hover {
			background-color: $netc-core-pagination-hover-background-color;
			color: $netc-core-pagination-hover-text-color;
			text-decoration: none;
		}
	}

	.#{$netc-core-pagination-current} {
		a {
			background-color: $netc-core-pagination-current-background-color;
			color: $netc-core-pagination-current-text-color;
		}
	}

	&__arrow {
		i {
			@include fa-icon();
			vertical-align: middle;
		}

		&--prev {
			i:before {
				content: $fa-var-chevron-left;
			}
		}

		&--next {
			i:before {
				content: $fa-var-chevron-right;
			}
		}
	}
}

.#{$netc-core-pagination-namespace}:not(.#{$netc-core-pagination-namespace}--simple) {
	li + li {
		border-left: 1px solid $netc-core-pagination-border-separator-color;
	}
}

.#{$netc-core-pagination-namespace}--simple {
	border: 0;
	width: 100%;
	display: inline-block;

	li {
		border: 1px solid $netc-core-pagination-border-color;
	}

	.#{$netc-core-pagination-namespace}__arrow--next {
		float: right;
	}
}
