.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 20;

  &.is-active {
    display: flex;
  }

  .btn {
    width: 100%;
  }

  .btn--primary {
    background-color: #4f1f5e;
  }
}

.popup__inner {
  @include rem(
    (
      height: 500px,
      width: 500px,
      padding: 20px,
    )
  );
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: $brand-white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0 0 120px 0;
  -webkit-box-shadow: 6px 6px 16px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 6px 6px 16px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 6px 6px 16px 1px rgba(0, 0, 0, 0.3);

  @include gridle_state(mobile) {
    @include rem(
      (
        height: 300px,
        width: 300px,
        padding: 20px,
      )
    );

    border-radius: 0 0 80px 0;
  }
}

.popup__top {
  .fm-logo {
    @include rem(
      (
        width: 65px,
      )
    );

    fill: #4f1f5e;
    display: inline-block;
    vertical-align: middle;
    background: transparent;
  }

  img {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

.popup__middle {
  @include rem(
    (
      max-width: 380px,
    )
  );

  flex: 1 0 auto;
  display: flex;

  @include gridle_state(mobile) {
    @include rem(
      (
        max-width: 220px,
      )
    );
  }
}

.popup__content {
  @include rem(
    (
      font-size: 30px,
      line-height: 36px,
    )
  );
  margin: auto 0;
  color: #4f1f5e;

  @include gridle_state(mobile) {
    @include rem(
      (
        font-size: 20px,
        line-height: 26px,
      )
    );
  }

  a {
    color: #4f1f5e;
    text-decoration-skip: auto;
  }
}

.popup__bottom {
  @include rem(
    (
      max-width: 380px,
    )
  );

  @include gridle_state(mobile) {
    @include rem(
      (
        max-width: 220px,
      )
    );
  }
}
