.property-filter {
  @media screen and (max-width: 767px) {
    > .umb-grid {
      margin-top: 0;

      .umb-grid-row {
        padding-top: 0;
      }
    }
  }
}

.property-filter__grid {
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.property-filter__search-criteria {
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    flex: 1 0 100%;
    text-align: center;
  }

  .property-filter__search-title {
    font-family: $base-font-family-med;
    font-size: 22px;
    line-height: 30px;
    color: $brand-dark-grey;
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 5px;
    }
  }

  .property-filter__search-result-amount {
    font-family: $base-font-family;
    font-size: 16px;
    line-height: 21px;
    color: $brand-dark-grey;
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }

    span {
      color: $brand-dark-grey;
      text-decoration: underline;
    }
  }

  .property-filter__search-results-new {
    cursor: pointer;
    font-weight: bold;
  }
}

.property-filter__btn-area {
  display: flex;
  flex: 0 0 50%;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 767px) {
    flex: 1 0 100%;
    justify-content: space-between;
    width: 100%;
  }

  .home-banner__search-area {
    flex: 1 0 auto;
    display: inline-block;
    border: 1px solid $brand-light-grey;
    margin-right: 20px;
    margin-left: 20px;
    max-width: none;

    @media screen and (max-width: 1023px) {
      flex: 1 0 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  .home-banner__search-area-inner {
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }

  .home-banner__search-area-inner {
    input {
      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
  }

  .typeahead__search-complete-holder {
    z-index: 1;
    border: 1px solid $brand-light-grey;
    border-top: none;

    .suggested-link {
      padding: 5px 30px;
      font-size: 16px;
      line-height: 20px;
      display: block;
      text-align: left;
      background-color: transparent;
      border: none;

      @media screen and (max-width: 767px) {
        padding: 8px 10px;
      }

      &:hover {
        .suggested-link__title {
          text-decoration: underline;
        }
      }

      &:last-child {
        padding-bottom: 15px;
      }
    }

    .suggested-link__title {
      strong {
        color: $brand-primary;
      }
    }
  }
}

.property-filter__map-tab {
  border: none;
  color: $brand-primary;
  background-color: transparent;

  span {
    font-family: $base-font-family-med;
    text-decoration: underline;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
  }

  svg {
    color: $brand-primary;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.property-filter__btn {
  font-family: $base-font-family-med;
  font-size: 16px;
  line-height: 21px;
  color: $brand-white;
  border: none;
  background-color: $brand-primary;
  padding: 12px 25px;
  text-transform: uppercase;

  &:hover {
    background-color: $brand-button-border;
  }

  span {
    padding-right: 5px;
  }

  svg {
    width: 13px;
    height: 10px;
    color: $brand-white;

    .is-active & {
      transform: scaleY(-1);
    }
  }
}

.property-filter__filter {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: $brand-light-grey;
  display: none;

  .is-active & {
    display: block;
  }
}

.property-filter__filter-inner {
  padding: 15px 20px 30px;
}

.property-filter__filter-grid {
  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.property-filter__filter-column {
  @media screen and (min-width: 768px) {
    flex: 1 0 50%;
  }
}

.property-filter__filter-column-inner {
  padding-right: 10px;
}

.property-filter__filter-option {
  label {
    display: inline-block;
    font-family: $base-font-family-med;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: $brand-dark-grey;
    margin-top: 15px;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  input[type='checkbox'] + label {
    @include rem(
      (
        font-size: 14px,
        line-height: 19px,
        padding: 2px 5px 2px 35px,
        margin-bottom: 5px,
      )
    );
    font-family: $base-font-family;
    color: $brand-text-heading;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    position: relative;
  }

  input[type='checkbox'] + label:before {
    @include rem(
      (
        width: 24px,
        height: 24px,
        margin-top: -1px,
      )
    );

    content: '';
    border: none;
    background-color: $brand-form-ele-bg !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }

  input[type='checkbox'] + label:after {
    @include transform-translate(0, 0);
    @include rem(
      (
        font-size: 20px,
        top: 4px,
        left: -7px,
        margin-top: -2px,
        width: 20px,
        height: 20px,
      )
    );

    content: '';
    position: absolute;
    top: 3px;
    left: 1px;
    color: $brand-dark-grey;
    background-image: url('/SiteFiles/src/ui/img/svg/checked.svg');
    background-repeat: no-repeat;
    opacity: 0;
  }

  input[type='checkbox']:checked + label:after {
    content: '';

    opacity: 1;
  }

  select {
    max-width: 215px;
  }
}

.property-filter__filter-checkbox-grid {
  display: flex;
  flex-wrap: wrap;
}

.property-filter__filter-checkbox {
  flex: 1 0 33%;
  margin-top: 15px;

  label {
    margin-top: 0;
  }

  .property-filter__filter-checkbox-grid--half & {
    flex: 1 0 50%;
  }
}

.property-filter__filter-base {
  text-align: left;
  padding-top: 30px;

  @media screen and (min-width: 768px) {
    text-align: right;
    padding-top: 60px;
  }
}

.property-filter__filter-apply-btn {
  border: none;
  background-color: $brand-primary;
  color: $brand-white;
  font-family: $base-font-family-med;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  padding: 10px 20px;

  &:hover,
  &:focus {
    background-color: $brand-button-border;
  }
}

.property-filter__filter-clear-btn {
  border: none;
  background-color: transparent;
  color: $brand-text-heading;
  text-decoration: underline;
  font-family: $base-font-family;
  font-size: 14px;
  line-height: 19px;
  margin-left: 20px;

  &:hover,
  &:focus {
    color: $brand-primary;
  }
}
