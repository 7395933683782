// HEADING STYLES
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: $brand-font;
  color: $brand-text-heading;
}

h1,
.h1 {
  @include rem(
    (
      font-size: 30px,
      line-height: 34px,
    )
  );

  // font-weight: 700;

  @include gridle_state(mobile) {
    @include rem(
      (
        font-size: 24px,
        line-height: 28px,
      )
    );
  }
}

h2 {
  @include rem(
    (
      font-size: 22px,
      line-height: 28px,
    )
  );

  font-weight: 400;

  @include gridle_state(mobile) {
    @include rem(
      (
        font-size: 22px,
        line-height: 28px,
      )
    );
  }

  .umb-grid-row & {
    margin-bottom: rem(30px);
  }
}

h3 {
  @include rem(
    (
      font-size: 18px,
      line-height: 23px,
    )
  );

  font-weight: 400;

  @include gridle_state(mobile) {
    @include rem(
      (
        font-size: 18px,
        line-height: 23px,
      )
    );
  }

  .umb-grid-row & {
    margin-bottom: rem(25px);
  }
}

h4 {
  @include rem(
    (
      font-size: 18px,
      line-height: 23px,
    )
  );

  font-weight: 400;

  @include gridle_state(mobile) {
    @include rem(
      (
        font-size: 18px,
        line-height: 23px,
      )
    );
  }
}

// TEXT STYLES
p {
  @include rem(
    (
      line-height: 22px,
      font-size: 14px,
    )
  );
  color: $brand-text;

  a {
    color: $brand-primary;
    text-decoration: underline;
    text-decoration-skip: auto;
  }
}

li {
  @include rem(
    (
      line-height: 22px,
      font-size: 14px,
    )
  );
  a {
    color: $brand-primary;
    // text-decoration: underline;
  }
}

a,
.link {
  color: $brand-secondary-dark;

  &:hover {
    color: $brand-primary;
  }

  &.is-active {
    text-decoration: underline;
    text-decoration-skip: auto;
    // font-weight: 700;
  }
}

.lead-text {
  @include rem(
    (
      font-size: 20px,
      line-height: 32px,
    )
  );

  color: $brand-text;
}

.annotation-text,
.caption {
  @include rem(
    (
      font-size: 12px,
      line-height: 16px,
    )
  );
  font-style: italic;
}

.annotation-text {
  min-height: 40px;
}

.xl-text {
  @include rem(
    (
      font-size: 30px,
      line-height: 34px,
    )
  );
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.theme-title-green {
  color: $brand-primary;
}
