.site-search {
  &__results-container {
    max-width: 100%;

    @include gridle_state ( small-desktop ) {
      padding-left: rem(30px);
      max-width: 75%
    }

    @include gridle_state ( desktop ) {
      max-width: 45%;
    }
  }

  &__result {
    margin-bottom: rem(35px);
  }

  &__result-snippet {
    strong {
      color: $brand-primary;
    }
  }

  &__summary {
    color: $brand-primary;
  }

  &__title {
    font-family: $base-font-family;
    font-size: rem(24px);
    margin-bottom: 0;

    a {
        color: $brand-text-heading;
    }
  }

  &__url{
    color: $brand-primary;
    font-size: rem(14px);
  }

  &__search-button {
    max-height: 16px;
  }
}
