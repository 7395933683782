$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
$fa-font-path: '~font-awesome/fonts';

.main-content {
  opacity: 1;
}

// libs
@import '~gridle/sass/gridle/gridle-flex';
@import '~sass-rem/rem';
@import '~font-awesome/scss/font-awesome.scss';
@import '~tablesorter/dist/css/scss/theme.scss';
@import 'helpers/functions';
@import '~glightbox/dist/css/glightbox';
@import '~beerslider/dist/BeerSlider.unmin';
@import '~tiny-slider/src/tiny-slider';
@import '~balloon-css/src/balloon';

@import 'core/tools-functions';
@import 'core/tools-mixins';

// override Titon variables
@import 'settings/settings-framework';
@import 'framework-imports';

// settings
@import 'settings/settings-brand';
@import 'settings/settings-breakpoint';
@import 'settings/settings-grid';
@import 'settings/settings-layers';
@import 'settings/settings-type';
@import 'settings/settings-global';

// helpers
@import 'helpers/helpers';
@import 'helpers/mixins';
@import 'helpers/list-inputs';

// tools
@import 'core/core-components/core-component-list-inputs';
@import 'core/core-components/core-component-buttons';
@import 'core/core-components/core-component-alerts';
@import 'core/core-components/core-component-forms';
@import 'core/core-components/core-component-cookie';
@import 'core/core-components/core-component-breadcrumbs';
@import 'core/core-components/core-component-pagination';
@import 'core/core-components/core-component-table';
@import 'core/core-components/core-component-typography';
@import 'core/core-components/core-component-tooltip';

// base
@import 'base/base-card';
@import 'base/base-grid';

// components
@import 'components/ion.rangeSlider.css';
@import 'components/component-global';

@import 'components/component-footer';
@import 'components/component-accordian';
@import 'components/component-typography';
@import 'components/component-buttons';
@import 'components/component-forms';
@import 'components/component-main-menu';
@import 'components/component-search';
@import 'components/component-site-search';
@import 'components/component-site-search-modal';
@import 'components/component-icons';
@import 'components/component-banner-carousel';
@import 'components/component-content-carousel';
@import 'components/component-gallery-carousel';
@import 'components/component-card';
@import 'components/component-featured-card';
@import 'components/component-featured-table';
@import 'components/component-child-nav';
@import 'components/component-advanced-search';
@import 'components/component-filter-area';
@import 'components/component-cta-bar';
@import 'components/component-cta-signpost';
@import 'components/component-decision-tree';
@import 'components/component-article';
@import 'components/component-mortgage-calc';
@import 'components/component-info-area';
@import 'components/component-detail-list';
@import 'components/component-cookie';
@import 'components/component-maps';
@import 'components/component-modal';
@import 'components/component-notification-bar';
@import 'components/component-popup';
@import 'components/component-banner';
@import 'components/component-people';
@import 'components/component-pagination';
@import 'components/component-re-sale';
@import 'components/component-peek-carousel';
@import 'components/component-header';
@import 'components/component-header-microsite';
@import 'components/component-centered-form';
@import 'components/component-image-slider';
@import 'components/component-cta-cards-widget';
@import 'components/component-blog-carousel';
@import 'components/component-featured-developments';
@import 'components/component-contact-modal';
@import 'components/component-home-banner';
@import 'components/component-typeahead';
@import 'components/component-property-search';
@import 'components/component-statistics';
@import 'components/component-testimonial-carousel';
@import 'components/component-property-card';
@import 'components/component-property-filter';
@import 'components/component-find-home-search';

// Updated homepage and search
@import 'components/component-logo-display';

// Development 2020
@import 'components/developments/index';

// templates
@import 'template/template-home';
@import 'template/template-styleguide';

// overrides
@import 'fixes/overrides-common';
@import 'fixes/overrides-tooltips';
