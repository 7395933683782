.home-banner {
  height: 653px;

  @media screen and (max-width: 767px) {
    height: 463px;
  }
}

.home-banner__inner {
  position: relative;
}

.home-banner__animated-svg {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
  svg {
    max-width: 850px;
  }
}

.home-banner__media-holder {
  height: 653px;

  @media screen and (max-width: 767px) {
    height: 463px;
  }
}

.home-banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-banner__content-area {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  padding: 120px 30px;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 70px 30px;
  }
}

.home-banner__content-area-inner {
  text-align: center;
}

.home-banner__title {
  font-family: $brand-font;
  font-size: 48px;
  line-height: 64px;
  color: $brand-white;
  text-shadow: 1px 2px 2px rgba(#000000, 0.3);
  margin-bottom: 5px;

  @media screen and (max-width: 767px) {
    font-size: 32px;
    line-height: 43px;
  }
}

.home-banner__strapline {
  font-family: $base-font-family-med;
  color: $brand-white;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 35px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.home-banner__search-area {
  background-color: $brand-white;
  max-width: 500px;
  margin: auto;
  padding: 10px 20px;
  position: relative;

  @media screen and (max-width: 767px) {
    background-color: transparent;
  }
}

.home-banner__search-area-inner {
  display: flex;

  @media screen and (max-width: 767px) {
    display: block;
  }

  input {
    border: none;
    margin: 0;

    @media screen and (max-width: 767px) {
      background-color: $brand-white;
      margin-bottom: 15px;
      height: 41px;
    }
  }
}

.home-banner__search-btn {
  font-family: $base-font-family-med;
  background-color: $development-cyan;
  font-size: 16px;
  line-height: 21px;
  color: $brand-white;
  border: none;
  padding: 9px 20px;
  text-transform: uppercase;
  transition: background-color 0.3s;

  &:hover {
    background-color: $brand-medium-grey;
  }
}
