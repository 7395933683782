.dev-detail-intro {
  padding: 80px 0;

  .grid-column:first-child {
    @include gridle_state(small-desktop) {
      padding-left: 103px;
    }
  }
  .grid-column:last-child {
    @include gridle_state(small-desktop) {
      padding-left: 103px;
      padding-right: 103px;
    }
  }

  p {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .dev-detail-intro__sub {
    font-size: 16px;
    margin-bottom: 0;
  }

  .btn {
    font-family: $brand-font;
  }
}

.dev-detail-intro__media {
  padding-top: 30px;

  @include gridle_state(small-desktop) {
    padding-top: 0;
  }

  img {
    width: 100%;
    aspect-ratio: 117 / 80;
  }
}

.dev-detail-intro__model-btn {
  span {
    vertical-align: middle;
  }

  svg {
    width: 13px;
    height: 16px;
    fill: $brand-black;
    stroke: $brand-black;
    transform: rotate(-25deg);
    vertical-align: middle;
  }

  &:hover {
    svg {
      fill: $brand-white;
      stroke: $brand-white;
    }
  }
}


.development-register-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;  

  .btn.btn--new {
    padding-right: 38px;

    @include gridle_state(mobile, tablet) {
      padding: 11px 12px 11px 6px;

      svg {
        height: 14px;
        margin: 0 4px;
      }
    }
  }
}