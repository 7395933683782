.development-5050 {
  &.development-5050--single {
    .grid-container--half > .grid-column,
    .grid-container--6-6 > .grid-column {
      flex: 1 0 100%;
      max-width: 100%;
      text-align: center;
    }
  }

  &__grid {
    .grid-column {
      &:first-child {
        @include gridle_state(mobile) {
          margin-bottom: 24px;
        }
      }
    }
  }

  &--reversed {
    .grid-container {
      flex-direction: row-reverse;

      .development-5050__media {
        transform: translateX(-50%);

        .active & {
          transform: translateX(0);
        }
      }

      .development-5050__content {
        transform: translateX(50%);

        .active & {
          transform: translateX(0);
        }
      }
    }
  }

  &__media {
    iframe,
    a.development-5050__external-link {
      width: 100%;
      height: 318px;
      max-width: 1155px;
      margin: auto;

      @include gridle_state(mobile tablet) {
        height: 180px;
      }
    }

    a.development-5050__external-link {
      height: 33rem;
      display: flex;
      img {
        object-fit: contain;
      }

      @include gridle_state(mobile) {
        height: 21rem;
      }
    }

    .tns-slider {
      display: flex;
    }

    .tns-slider > .tns-item {
      height: auto;
      object-fit: cover;
    }
  }

  &__content {
    opacity: 0;
    transform: translateX(-50%);
    transition: transform 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045),
      opacity 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);

    .active &,
    &.active {
      opacity: 1;
      transform: translateX(0);
    }

    .btn.btn--new {
      display: inline-block;
      svg {
        vertical-align: middle;
      }
    }

    iframe {
      width: 100%;
      height: 528px;
      max-width: 1155px;
      margin: auto;

      @include gridle_state(tablet) {
        height: 350px;
      }

      @include gridle_state(mobile) {
        height: 180px;
      }
    }
  }

  &__media {
    position: relative;
    opacity: 0;
    transform: translateX(50%);
    transition: transform 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045),
      opacity 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045);

    img {
      width: 100%;

      @include gridle_state(tablet desktop) {
        object-fit: contain;
        object-position: top center;
        font-family: 'object-fit: cover; object-position: center center;';
      }
    }

    video {
      width: 100%;
    }

    .active & {
      opacity: 1;
      transform: translateX(0);
    }

    .tns-controls {
      button {
        background-color: transparent;
        border: none;

        &:first-child {
          position: absolute;
          top: 50%;
          right: 90%;
          transform: translateY(-50%);
          z-index: 1;
        }

        &:last-child {
          position: absolute;
          top: 50%;
          left: 90%;
          transform: translateY(-50%);
          z-index: 1;
        }

        svg {
          fill: $brand-white;
        }
      }
    }

    .slick-prev,
    .slick-next {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;

      svg {
        fill: $brand-white;
        width: 11px;
      }
    }

    .slick-prev {
      background-color: #8bd3d3;
      right: 50px;
    }

    .slick-next {
      background-color: $development-cyan;
      right: 0;
    }
  }

  &.location-grid {
    padding: 30px 0;

    .grid-column {
      position: relative;
    }

    .development-5050__content {
      position: absolute;
      z-index: 1;

      @include gridle_state(mobile) {
        display: none;
        position: static;
      }

      &.active {
        z-index: 2;

        @include gridle_state(mobile) {
          display: block;
        }
      }
    }
  }
}

.location-grid__base {
  text-align: center;

  .btn {
    display: inline-block;
    width: auto;
  }
}
