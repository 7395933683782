.modal-panel {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  margin-left: 50px;
  transform: translateX(110%);
  transition: transform 0.3s;
  width: calc(100% - 50px);
  max-width: 766px;

  &.is-active {
    transform: translateX(0);
  }
}

.modal-panel__screen {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: rgba($brand-white, 0.5);
  height: 100vh;
  width: 100vw;
  display: none;

  .no-scroll & {
    display: block;
  }
}

.modal-panel__inner {
  background-color: $brand-white;
  max-width: 766px;
  padding: 40px;  
  height: 100vh;
  overflow-y: auto;

  @include gridle_state (tablet-min) {
    padding: 50px 80px 50px 100px;  
  }
}

.modal-panel__close-btn {
  background-color: $development-cyan;
  border: none;
  color: $brand-white;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 100%;
  top: 0;
  cursor: pointer;
  z-index: 10;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    stroke: $brand-white;
    fill: $brand-white;
  }
}