.card {
  @include rem(
    (
      margin-bottom: 60px,
    )
  );

  p:last-child {
    @include rem(
      (
        margin-bottom: 12px,
      )
    );
  }

  &--s-margin {
    @include rem(
      (
        margin-bottom: 20px,
      )
    );
  }

  &:hover {
    & div.card__bg {
      opacity: 1;
    }

    .grid-column--first & {
      & div.card__bg {
        background-color: #00000066;
      }
    }
  }
}

.card__header {
  overflow: visible;
  position: relative;

  img {
    width: 100%;
  }

  & div.card__bg {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000066;
    transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out;
    z-index: 1;
  }

  .grid-column--first & {
    height: rem(380px);
    & div.card__bg {
      opacity: 1;
      background: linear-gradient(
        0deg,
        rgba(24, 24, 24, 0.8) 0%,
        rgba(29, 29, 29, 0.5) 34%,
        rgba(47, 47, 47, 0.3) 75%,
        rgba(84, 84, 84, 0) 100%
      );
    }
  }
}

.card__content {
  .grid-column--first & {
    color: white;
    p {
      color: $brand-white !important;
    }

    .card__title {
      color: $brand-white !important;
    }
  }

  p {
    @include rem(
      (
        line-height: 22px,
      )
    );
  }

  .grid-container--half &,
  .grid-container--6-6 & {
    @include gridle_state(tablet-min) {
      max-width: 75%;
      margin: auto;
      align-self: center;
    }
  }

  a {
    &:hover {
      text-decoration: none;

      .card__title {
        color: $brand-button-border;
      }
    }
  }

  .featured-devs--blog & {
    text-align: center;

    p {
      text-align: left;
    }
  }

  .blog & {
    .card__text {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;

      -webkit-line-clamp: 3;
      @include gridle_state(small-desktop) {
        -webkit-line-clamp: 5;
      }
    }
  }
}

.card--centered {
  text-align: center;
}

.card--rectangle {
  img {
    aspect-ratio: 70 / 39;
  }
}

.card--square {
  img {
    aspect-ratio: 1 / 1;
  }
}

.card__media-holder {
  img {
    @include netc-transition(0.5s, ease, all);
    @include transform-scale(1, 1);
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center;';

    a:hover & {
      @include transform-scale(1.03, 1.03);
    }
  }

  // .featured-devs--blog & {
  //   height: rem(190px);
  // }
}

.card__title {
  @include rem(
    (
      margin-bottom: 15px,
    )
  );

  display: block;
  color: $brand-secondary;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  div.blog & {
    color: $brand-dark-grey;
  }
}

// BLOG CARDS
.card--blog {
  overflow: hidden;
  position: relative;

  &:hover {
    .card__text > span {
      color: $brand-secondary-dark !important;
      .grid-column--first & {
        color: white !important;
      }
    }

    .card__content-holder {
      height: rem(215px);
    }

    .btn,
    .card__un-restricted-text {
      opacity: 1;
    }

    .card__restricted-text-ellipsis {
      display: none;
    }

    .card__tag {
      background-color: $brand-text;
      color: $brand-white;
    }

    &.card--related {
      .card__tag {
        background-color: $brand-primary;
      }
    }
  }

  &.card--blog-story {
    &:hover {
      .card__content-holder {
        height: rem(250px);

        .blog & {
          height: rem(260px);
        }

        .grid-column--first & {
          height: rem(232px);

          .card__text {
            -webkit-line-clamp: 5;
          }
        }
      }
    }

    .blog & {
      margin-bottom: rem(32px);
    }
  }

  .card__header {
    margin-bottom: rem(155px);
    padding-bottom: 0;

    .grid-column--first & {
      margin-bottom: 0;
    }
  }

  .card__media-bg {
    height: rem(225px);
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .grid-column--first & {
      height: rem(380px);
    }
  }

  .card__content-holder {
    padding: rem(16px 16px 0);
    transition: height 0.5s;
    background-color: $development-light-grey;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: rem(170px);
    z-index: 2;

    @include gridle_state(tablet) {
      height: rem(250px);
    }
    @include gridle_state(mobile) {
      height: rem(200px);
    }

    .blog & {
      @include gridle_state(tablet) {
        height: rem(185px);
      }
      @include gridle_state(small-desktop) {
        height: rem(190px);
      }
    }

    .grid-column--first & {
      background-color: transparent;
    }
  }

  .card__title {
    margin-bottom: rem(10px);

    .blog-detail & {
      margin-bottom: rem(10px);
    }
  }

  .btn {
    transition: opacity 0.5s;
    opacity: 0;
    &:hover {
      color: $brand-white;
      background-color: $brand-primary;
    }
    @include gridle_state(tablet mobile) {
      opacity: 1;
    }
  }

  .grid-column--first & {
    @include gridle_state(desktop) {
      .card__text {
        max-width: 65%;
      }
    }
  }

  .card__restricted-text {
    font-size: rem(14px);
    line-height: rem(18px);
  }

  .card__un-restricted-text {
    opacity: 0;
    transition: opacity 0.5s;
    font-size: rem(14px);
    line-height: rem(18px);
  }

  .article-detail__date {
    font-size: rem(12px);
    line-height: rem(16px);
    font-family: $base-font-family;
    color: $brand-text-heading;

    div.blog & {
      font-size: rem(14px);
      color: $brand-dark-grey;
    }
  }

  .card__tag {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $brand-white;
    color: $brand-text;
    text-transform: uppercase;
    font-family: $brand-font;
    font-size: rem(14px);
    line-height: rem(34px);
    z-index: 3;
    padding: rem(0 8px);
    letter-spacing: 2px;

    .blog & {
      display: flex;
      letter-spacing: 0px;
      font-size: rem(15px);
      text-transform: none;
      padding: rem(4px 20px);
      background-color: $brand-primary;
      color: $brand-white;
      max-width: 100%;
      
      &:hover {
        background-color: $brand-primary;
        color: $brand-white;
      }

      span {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: rem(5px);
      }
    }
  }
}

.card--info {
  background-color: $brand-white;
  padding: rem(20px 50px);
  -webkit-box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 0px 21px -6px rgba(0, 0, 0, 0.32);

  .card__header,
  .card__content {
    flex: 1 0 auto;
  }

  hr {
    margin: rem(20px 0);
  }

  .btn {
    font-family: $base-font-family-med;
    width: 100%;
    max-width: 100%;
  }
}

.card--related {
  .card__tag {
    color: $brand-primary;
  }
  .card__content-holder {
    background-color: $brand-primary;
    color: $brand-white;
  }

  .article-detail__date,
  .card__title {
    color: $brand-white;
  }

  a {
    &:hover {
      .card__title {
        color: $brand-white;
      }
    }
  }
}

.blog-detail {
  .grid-container {
    .grid-column {
      div {
        min-height: 1px;
      }
    }
  }

  .blog & {
    margin: auto;
  }
}
