.image-slider__top {
  text-align: center;
  margin-bottom: 30px;

  p {
    font-size: 18px;
    line-height: 28px;
  }
}

.image-slider__top-inner {
  max-width: 700px;
  margin: auto;
}

.js-beer-slider {
  width: 100%;

  > img {
    max-width: none;
    width: 100%;
  }
}

.beer-reveal {
  border-right: 3px solid #00A0A0;
}

.beer-handle {
  background-image: url('/SiteFiles/src/ui/img/svg/handle.svg');
  
  &:after,
  &:before {
    content: none;
  }
}

.beer-range:focus ~ .beer-handle {
  background-image: url('/SiteFiles/src/ui/img/svg/handle.svg');
}