.latest-blog__title-container {
    text-align: center;

    .latest-blog__title {
        font-family: $brand-font;
        font-size: 24px;
        line-height: 32px;
        color: $brand-primary;
        margin-bottom: 25px;
    
        @media screen and (min-width: 768px) {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 55px;
        }
    }
}

.blog-cards__carousel-container {
    position: relative;
    
    .tns-controls {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        @media screen and (min-width: 1200px) {
            display: none;
        }

        button {
            background-color: $brand-primary;
            border: none;
            width: 34px;
            height: 34px;

            &:hover {
                background-color: $brand-primary-vlight;
            }

            &:disabled {
                opacity: 0.7;
            }

            .svg-icon {
                width: 8px;
                height: 14px;
            }
        }
    }

    .tns-inner {
        @media screen and (max-width: 767px) {
            margin-right: 20px !important;
        }
    }
}

.blog-cards__progress-bar {
    position: relative;
    margin: 0 auto 50px;
    height: 4px;
    max-width: 792px;

    @media screen and (min-width: 1200px) {
        display: none;
    }
}

.blog-cards__progress-bar-bottom {
    display: block;
    width: 100%;
    height: 2px;
    padding: 1px 0;
    background-color: $brand-border;
    transform: translateY(1px);
}

.blog-cards__progress-bar-top {
    display: block;
    width: 100%;
    height: 4px;
    background-color: $brand-primary;
    transform: scaleX(0);
    transition: transform 0.3s;
    transform-origin: 0;
    position: absolute;
    top: 0;
    left: 0;
}