.svg-icon {
  @include rem(
    (
      width: 36px,
      height: 36px,
    )
  );
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;

  &--small {
    @include rem(
      (
        width: 26px,
        height: 26px,
      )
    );
  }

  &--lock {
    background-image: url('/SiteFiles/src/ui/img/svg/lock.svg');
  }

  &--search {
    background-image: url('/SiteFiles/src/ui/img/svg/search.svg');
  }

  &--search-black {
    background-image: url('/SiteFiles/src/ui/img/svg/search-black.svg');
  }

  &--tag {
    background-image: url('/SiteFiles/src/ui/img/svg/tag.svg');
  }

  &--menu {
    background-image: url('/SiteFiles/src/ui/img/svg/menu.svg');
  }

  &--house {
    background-image: url('/SiteFiles/src/ui/img/svg/private-sale.svg');
  }

  &--bed {
    background-image: url('/SiteFiles/src/ui/img/svg/bed.svg');
  }

  &--bath {
    background-image: url('/SiteFiles/src/ui/img/svg/bath.svg');
  }

  &--share {
    background-image: url('/SiteFiles/src/ui/img/svg/share.svg');
  }

  &--garden {
    background-image: url('/SiteFiles/src/ui/img/svg/garden.svg');
  }

  &--location {
    background-image: url('/SiteFiles/src/ui/img/svg/location.svg');
  }

  &--list {
    background-image: url('/SiteFiles/src/ui/img/svg/list-white.svg');
  }

  &--twitter {
    background-image: url('/SiteFiles/src/ui/img/svg/twitter.svg');
  }

  &--facebook {
    background-image: url('/SiteFiles/src/ui/img/svg/facebook.svg');
  }

  &--email {
    background-image: url('/SiteFiles/src/ui/img/svg/email.svg');
  }

  &--whatsapp {
    background-image: url('/SiteFiles/src/ui/img/svg/whatsapp.svg');
  }

  &--blog {
    &-facebook {
      background-image: url('/SiteFiles/src/ui/img/svg/blog-facebook.svg');
    }
    &-email {
      background-image: url('/SiteFiles/src/ui/img/svg/blog-email.svg');
    }
    &-whatsapp {
      background-image: url('/SiteFiles/src/ui/img/svg/blog-whatsapp.svg');
    }
  }

  &--instagram {
    @include rem(
      (
        width: 32px,
        height: 25px,
      )
    );
    background-image: url('/SiteFiles/src/ui/img/svg/instagram.svg');
  }

  &--youtube {
    @include rem(
      (
        width: 32px,
        height: 27px,
      )
    );
    background-image: url('/SiteFiles/src/ui/img/svg/youtube.svg');
  }

  &--twitter-white {
    background-image: url('/SiteFiles/src/ui/img/svg/twitter-white.svg');
  }

  &--facebook-white {
    background-image: url('/SiteFiles/src/ui/img/svg/facebook-white.svg');
  }

  &--instagram-white {
    @include rem(
      (
        width: 32px,
        height: 25px,
      )
    );
    background-image: url('/SiteFiles/src/ui/img/svg/instagram-white.svg');
  }

  &--youtube-white {
    @include rem(
      (
        width: 32px,
        height: 27px,
      )
    );
    background-image: url('/SiteFiles/src/ui/img/svg/youtube-white.svg');
  }
}

.icon--tag {
  width: rem(23px);
  height: rem(23px);

  .blog-orange & {
    fill: $blog-orange;
  }

  .blog-blue & {
    fill: $blog-blue;
  }

  .blog-red & {
    fill: $blog-red;
  }

  .blog-brown & {
    fill: $blog-brown;
  }

  .blog-purple & {
    fill: $blog-purple;
  }

  .blog-green & {
    fill: $blog-green;
  }
}

.is-active {
  .svg-icon--menu {
    background-image: url('/SiteFiles/src/ui/img/svg/cross.svg');

    @include gridle_state(small-desktop) {
      background-image: url('/SiteFiles/src/ui/img/svg/cross-black.svg');
    }
  }
}

.pb-logo {
  background-image: url('/SiteFiles/src/ui/img/svg/peabodyIcon.svg');
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center 6px;
}

.pb-logo-white {
  background-image: url('/SiteFiles/src/ui/img/svg/peabodyIcon-white.svg');
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 10px 3px;
}

.plotlist-feature-icon {
  display: inline-block;
  max-width: 40px;

  .featured-table & {
    @include gridle_state(tablet-min) {
      width: 45%;
    }
    @include gridle_state(desktop) {
      width: 33%;
    }
  }

  .icon {
    width: 35px;
    height: 35px;
  }
}

.plotlist-feature-img {
  display: inline-block;
  max-width: 40px;

  .featured-table & {
    @include gridle_state(tablet-min) {
      width: 45%;
    }
    @include gridle_state(desktop) {
      width: 33%;
    }
  }

  img {
    width: 35px;
    margin-bottom: 0;
  }
}
