.detail-list {
  @include rem((
    padding: 10px 0,
  ));

  border-bottom: 1px solid $brand-hr;
  display: block;

  @include gridle_state ( tablet-min ) {
    display: flex;
    flex: 0 0 auto;
  }

  &:last-child {
    @include rem((
      margin-bottom: 20px,
    ));
    border: none;

    @include gridle_state ( tablet-min ) {
      margin-bottom: 0;
    }
  }

  p {
    @include gridle_state ( tablet-min ) {
      margin: 0;
    }
  }
}

.detail-list__left {
  @include rem((
    padding-right: 20px,
  ));

  @include gridle_state ( small-desktop ) {
    flex: 1 0 auto;
    margin-bottom: 0;
  }

  // strong {
  //   &:first-child {
  //     color: $brand-primary-dark;
  //   }
  // }
}

.detail-list__right {
  @include gridle_state ( small-desktop ) {
    flex: 0 0 auto;
    margin-bottom: 0;
  }
}
