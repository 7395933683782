.decision-tree {

}

.decision-tree__block {
  @include rem((
    padding: 15px 15px,
    margin-bottom: 1px,
    border: 3px solid $brand-secondary,
    background-color:white,
  ));

  display: flex;
  flex-wrap: wrap;
  // border-bottom: 1px solid $brand-secondary;
}

.decision-tree__text {
  flex: 0 0 100%;
  // color: $brand-white;
  display: flex;
  align-self: center;

  @include gridle_state ( small-desktop ) {
    @include rem((
      padding-right: 20px,
    ));

    flex: 0 0 45%;
  }

  p {
    // color: $brand-white;
    color: teal;
    @include gridle_state ( small-desktop ) {
      margin-bottom: 0;
    }
  }
}

.decision-tree__selection {
  flex: 0 0 auto;
  display: flex;
  align-self: center;

  input[type="radio"] + label:before,
  input[type="checkbox"] + label:before {
    content: none;
  }

  input[type="radio"] + label:after,
  input[type="checkbox"] + label:after {
    content: none;
  }

  .field-container__group {
    @include rem((
      margin-bottom: 12px,
    ));

    width: 100%;
    display: block;

    @include gridle_state ( tablet-min ) {
      width: auto;
      display: inline-block;
      margin-bottom: 0;
    }

    label {
      margin-top: 0;
    }
  }

  .field-container__group input[type="radio"] + label {
    @include rem((
      padding: 12px 15px,
      width: 195px,
      font-size: 16px,
    ));

    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid $brand-secondary;
    background-color: $brand-secondary;
    color: $brand-white;
  }

  .field-container__group input[type="radio"]:checked + label {
    border: 1px solid $brand-secondary;
    background-color: $brand-secondary;
    color: $brand-white;
  }
}

.decision-tree__results {
  @include rem((
    padding-top: 35px,
  ));
  max-width: 650px;
  margin: 0 auto;
}
