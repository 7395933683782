.development-banner {
  position: relative;
  margin: 0 auto;
  padding: 0;
  max-height: 655px;
  overflow: hidden;

  &__media {
    video {
      width: 100%;
    }

    img {
      width: 100%;
      height: 655px;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center center;';

      @include gridle_state(mobile) {
        height: 342px;
      }
    }
  }

  &__tag {
    position: absolute;
    width: 260px;
    background-color: rgba($color: $brand-white, $alpha: 0.8);
    padding: 20px 30px 15px;
    text-align: center;
    bottom: 0;
    left: 0;
    z-index: 1000;

    &-title {
      font-size: 12px;
      line-height: 36px;
      color: rgba($brand-dark-grey, 0.5);
    }

    &-logo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-logo {
      img {
        max-width: 104px;
      }
    }
  }
}
